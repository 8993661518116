import { BaseView } from '@app/views/shared/BaseView';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-path-list',
  templateUrl: './career-path-list.component.html',
  styleUrls: ['./career-path-list.component.sass']
})
export class CareerPathListComponent extends BaseView implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;

  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private route: Router,

  ) {
    super()
  }

  ngOnInit() {
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
    });
  }

}
