<div>

  <form class="login-form form" #form='ngForm' [formGroup]="loginForm" (submit)="login()">

    <div class="column is-12" style="padding-bottom: 60px;">
      <ats-language-switch login="show"></ats-language-switch>
    </div>
    <div class="logo-login field text-center mt-50" *ngIf="(customerPreference$ | async) as customerPreference">
      <img  src="{{getLogo(customerPreference?.logo)}}">
    </div>
    <h1 class="title-login">{{ 'login.login' | translate }}</h1>
    <p class="sub-title-login">{{ 'login.data' | translate }}</p>
    <div class="notification is-danger" *ngIf="error" [innerHTML]=""></div>

    <div class="margin-login">
      <div class="field p-0 mb-20">
        <p class="itens-login">{{ 'login.login' | translate }}:</p>
        <input type="text" formControlName="login" class="input" placeholder="{{ 'placeholder.login' | translate }}">
        <app-alert-field alertType=2 alertTextTranslate="login.invalid-format"
          *ngIf="(loginForm.get('login').errors?.email) && form.submitted"></app-alert-field>

        <app-alert-field alertType=3 alertTextTranslate="general.required"
          *ngIf="(loginForm.get('login').errors?.required) && form.submitted"></app-alert-field>

      </div>

      <div class="field p-0">
        <p class="itens-login">{{ 'login.password' | translate }}:</p>
        <div style="width: 100%; display: inline-block;">
          <input type="password" formControlName="password" class="input"
            placeholder="{{ 'placeholder.password' | translate }}">
          <app-alert-field alertType=2 alertTextTranslate="login.char-min"
            *ngIf="loginForm.get('password').errors?.minlength && form.submitted"></app-alert-field>

          <app-alert-field alertType=2 alertTextTranslate="login.char-max"
            *ngIf="loginForm.get('password').errors?.maxlength && form.submitted"></app-alert-field>

        </div>
        <p class="itens-login txt-left reset-password-login">{{ 'login.forgot-password' | translate }}<a
            class="itens-login" routerLink="/forgot-password"><b> {{ 'login.password-reset' | translate }}</b></a></p>
      </div>

      <div class="field">
        <p class="control txt-center">
          <button type="submit" class="btn button-login">
            {{ 'login.button-login-label' | translate }}
          </button>
        </p>
        <p class="itens-login txt-center reset-password-login">{{ 'login.not-yet' | translate }}</p>
        <p class="txt-center"><a class="itens-login btn-signup" routerLink="/signup"><b>{{ 'login.register' | translate }}</b></a></p>
      </div>
    </div>
  </form>

</div>
