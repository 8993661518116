import { AlertFieldModule } from '../../../components/alert-field/alert-field.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyAccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { ComponentsModule } from '@app/components/components.module';
import { AlertModule } from '@app/components/alert/alert.module';
import { TranslateLoadersConfig } from '@app/utils';

import { ProfileComponent } from './profile/profile.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AccountComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    MyAccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    TranslateModule,
    AlertFieldModule,
  ]
})
export class AccountModule { }
