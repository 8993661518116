<section class="section bg-private" [ngClass]="{'section-padding': !themeService.eventMenu}">

  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-2" *ngIf="(training$ | async) as training">
        <div class="card-default-big card-classes p-20">
          <h1 class="title-default text-left" style="font-size: 18px">Módulo Atual</h1>
          <div class="columns is-multiline p-0 tool-responsive" style="padding: 5px !important;">
            <div class="column is-12 p-0">
              <select class="select" (change)="changeCurrentModule($event.target.value)" [(ngModel)]="moduleId"
                [disabled]="blockModules">
                <option *ngFor="let module of training?.modules" [value]="module.id">{{ module.position }} -
                  {{module.name}}</option>
              </select>
            </div>
          </div>
          <h1 class="title-default text-left tool-title">Ferramentas</h1>
          <div class="tool-box">
            <div class="columns is-multiline p-0 tool-responsive tool-area"
              *ngFor="let tool of currentModule?.tools; let i = index">
              <img *ngIf="tool.toolTypeId == 1" [style.background-color]="user.mainColor"
                src="/assets/images/tools/video.svg">
              <img *ngIf="tool.toolTypeId == 2" [style.background-color]="user.mainColor"
                src="/assets/images/tools/podcast.svg">
              <img *ngIf="tool.toolTypeId == 3" [style.background-color]="user.mainColor"
                src="/assets/images/tools/course.svg">
              <img *ngIf="tool.toolTypeId == 4" [style.background-color]="user.mainColor"
                src="/assets/images/tools/pdf.svg">
              <img *ngIf="tool.toolTypeId == 5" [style.background-color]="user.mainColor"
                src="/assets/images/tools/assessments.svg">
              <img *ngIf="tool.toolTypeId == 6" [style.background-color]="user.mainColor"
                src="/assets/images/tools/conference.svg">
              <span class="tool-name" [ngClass]="{'active': tool.id == toolId}">{{ tool.position }} -
                {{tool.name}}</span>
              <img src="/assets/images/icons/check-of.svg" class="tool-status"
                *ngIf="tool.toolAccessStatusId != 3 && tool.toolAccessStatusId != 4">
              <img src="/assets/images/icons/check.svg" class="tool-status"
                *ngIf="tool.toolAccessStatusId == 3 || tool.toolAccessStatusId == 4">
            </div>
          </div>
          <div class="text-center">
          </div>
        </div>
      </div>
      <div class="column is-1"></div>
      <div class="column is-8">
        <app-alert></app-alert>
        <div class="box-video-private" *ngIf="!showFeedback">
          <div class="podcast-responsive" *ngIf="loadIframePodCast">
            <img src="../../../../../../assets/images/diversas/podcast.svg" style="width: 100%;"
              *ngIf="loadImgPodcast" />
            <iframe [src]="urlContent | safeUrlResource " frameborder="0" *ngIf="loadIframePodCast"
              allow="autoplay; encrypted-media" allowfullscreen #player_container></iframe>
          </div>
          <div class="pdf-responsive" *ngIf="loadIframePdf">
            <div *ngIf="downloadDocument"
              style="display: flex; align-items: center;justify-content: center;flex-direction: column; padding-top: 5%;">
              <h1 style="font-size: 30px;">Baixando documento</h1>
              <img src="../../../../../../assets/images/tools/downloads.png" />
            </div>
            <iframe class="" [src]="urlContent | safeUrlResource" frameBorder="0" scrolling="auto"
              width="100%"></iframe>

          </div>
          <div class="video-responsive" *ngIf="playerYoutube || playerVimeo">
            <div *ngIf="playerVimeo">
              <iframe [src]="vimeoLink | safeUrlResource" frameborder="0" allowfullscreen
                allow="autoplay; encrypted-media;"></iframe>
            </div>
            <div *ngIf="playerYoutube">
              <iframe [src]="youtubeLink | safeUrlResource" frameborder="0" allowfullscreen
                allow="autoplay; encrypted-media;"></iframe>
            </div>
            <div class="plyr__video-embed" id="player" *ngIf="">
              <plyr style="display: block; width: 100%;" plyrTitle="Video 1" plyr [plyrPlaysInline]="true"
                [plyrOptions]="options" [plyrSources]="videoSources" (plyrInit)="playerP = $event"
                (plyrPlay)="played($event)" (plyrPause)="paused($event)" (plyrEnded)="ended($event)"
                (plyrReady)="ready($event)" [plyrCrossOrigin]="true"></plyr>
            </div>
          </div>
          <iframe [src]=" urlContent  | safeUrlResource " frameborder="0" *ngIf="loadIframe" allowfullscreen
            class="scorm-player" allowtransparency allow="autoplay" contextmenu="false" aria-controls="false"
            #player_frame
            sandbox="allow-scripts allow-forms allow-same-origin allow-modals allow-top-navigation allow-popups"
            id="frameScorm">
          </iframe>
          <div class="tools-actions">
            <div class="evaluation">
              <p>{{ 'training.feedback' | translate }}</p>
              <i class="far fa-thumbs-down" (click)="likeTrainingTool(false)"></i>
              <i class="far fa-thumbs-up" (click)="likeTrainingTool(true)"></i>
            </div>
            <div class="mb-50" *ngIf="tool?.description">
              <h1 class="title-default2 text-left" style="font-size: 18px;">{{ 'training.description' | translate }}</h1>
              <p class="tool-description" [innerHTML]="tool?.description | safeHtml"></p>
            </div>
            <div class="navigation">
              <a href="javascript:void(0)" *ngIf="urlPrevious" (click)="redirectTo(urlPrevious)" class="btn previous">
                < Anterior</a>
                  <a href="javascript:void(0)" *ngIf="urlNext" (click)="redirectTo(urlNext)" class="btn next">Próximo
                    ></a>
                  <a href="javascript:void(0)" *ngIf="showUrlConclusion" (click)="finishTraining()"
                    class="btn next">Concluir ></a>
            </div>
          </div>
        </div>
        <div class="box-video" *ngIf="showFeedback">
          <div class="card-concluded-area ">
            <div class="card-concluded">
              <div class="">
                <div class="text-center">
                  <div class="background-img">
                    <img class="center-margin-auto" src="/assets/images/diversas/historico.png">
                  </div>
                </div>
                <div class="text-area ml-15 mr-15 text-center">
                  <h1 class="title-card-result">{{ 'training.congratulations' | translate }}</h1>
                  <p class="text-card-result text-center" *ngIf="typeId == 1">{{ 'training.finalized-training' |
                    translate }}</p>
                  <p class="text-card-result text-center" *ngIf="typeId == 2">{{ 'training.finalized-trail' | translate
                    }}</p>
                  <div class="text-center">
                    <a class="btn-default-confirmed " (click)="redirect()">{{ 'training.conclude-course' | translate
                      }}</a>
                    <a routerLink="/trainings/type/1" class="btn-default-confirmed" *ngIf="typeId == 1"
                      (click)="redirectCertified(training.trainingTypeId, training.trainingId, training.matriculationTrainingId)">{{
                      'training.certified' | translate }}</a>
                    <a routerLink="/trails/type/2" class="btn-default-confirmed" *ngIf="typeId == 2"
                      (click)="redirectCertified(training.trainingTypeId, training.trainingId, training.matriculationTrainingId)">{{
                      'training.certified' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>