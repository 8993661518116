import { BankSlip } from "./bank-slip";
import { CreditCard } from "./credit-card";

export class Invoice {

  ID: string;
  orderId: number;
  bankSlip: BankSlip;
  creditCard: CreditCard;
  creationDate:Date;
  active:boolean;
}
