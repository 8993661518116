import { BaseView } from '../../../../shared/BaseView';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CredentialsService } from '@app/core/services/credentials.service';
import { MatriculationService } from '../../../service/matriculation.service';
import { Matriculation } from '../../../model/learning/matriculation';
import { AlertService } from '@app/components/alert/alert.service';


@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.sass']
})
export class TrainingListComponent extends BaseView  implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  searchTerms: string;
  changeTab: number = 0;
  public typeId: number;
  public show: boolean = false;
  trainings: Array<Matriculation>;
  constructor(
    public themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    private route: Router,
    public matriculationService: MatriculationService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.typeId = this.activatedRoute.snapshot.params['typeId'];
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.loadItens(this.STATUS_ALL);
    });

  }

  public loadItens(statusId: number) {
    this.changeTab = statusId;
    if (this.user && this.user.id) {
      this.matriculationService.getTrainingMatriculations(this.typeId ,this.user.id, this.searchTerms, statusId)
        .subscribe(trainings => {
          this.trainings = trainings;      
        })
    }
  }

  public redirect(trainingTypeId: number, trainingId: number, matriculationId: number) {
    if(trainingTypeId == 1){
      this.route.navigate([`trainings/${trainingId}/type/${trainingTypeId}/training-home/${matriculationId}/false`]);
    }else{
      this.route.navigate([`trails/${trainingId}/type/${trainingTypeId}/trail-home/${matriculationId}`]);
    }
  }

  public redirectCertified(trainingTypeId: number, trainingId: number, matriculationTrainingId: number){
    this.route.navigate([`trails/${trainingId}/type/${trainingTypeId}/trail-certified/${matriculationTrainingId}`]);
  }

  public cleanHistory(trainingId:number, matriculation: Matriculation) {
    this.matriculationService.cleanHistory(this.user.id, trainingId, matriculation)
        .subscribe(data=> {
          this.alertService.success('O histórico do treinamento foi excluído com sucesso.');
        });
  }

}
