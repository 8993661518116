import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ats-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  public footer: boolean = false

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe((data: NavigationEnd) => {
      if (data instanceof NavigationEnd) {
        if (data.url === '/' || data.url === '/login' || data.url.includes('/request-password') || data.url.includes('/account')) {
          this.footer = false
        } else {
          this.footer = true
        }
      }
    })
  }

}
