import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";
import { Tool } from "../model/learning/tool";
import { ToolNote } from "../model/learning/tool-note";

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class MatriculationToolService {


  constructor(private http: HttpClient) {
  }

  public getTrainingTool(userId: number, trainingId: number , matriculationId: number, toolId: number ) {
    return this.http.get<Tool>(`${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}`);
  }

  public saveToolAccess(userId: number, trainingId: number, matriculationId: number, toolId: number, tool:Tool) {
    const url = `${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}`;
    return this.http.post<Tool>(url, tool);
  }

  public updateToolAccess(userId: number, trainingId: number, matriculationId: number, toolId: number, tool:Tool) {
    const url = `${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}`;
    return this.http.put<Tool>(url, tool);
  }

  public getTrainingToolNotes(userId: any, trainingId: number, matriculationId: number, toolId: number) {
    return this.http.get<ToolNote[]>(`${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}/notes`);
  }

  public saveTrainingToolNote(userId: number, trainingId: number, matriculationId: number, toolId: number,toolNote:ToolNote) {
    const url = `${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}/notes`;
    return this.http.post<ToolNote>(url,toolNote);
  }

  public deleteTrainingToolNote(userId: number, trainingId: number, matriculationId: number, toolId: number, id: number) {
    const url = `${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}/tools/${toolId}/notes/${id}`;
    return this.http.delete<ToolNote>(url);
  }

  public likeTrainingTool(userId: number, trailId: number , matriculationId: number, toolId: number,tool:Tool){
    const url = `${API_URL}/${userId}/trainings/${trailId}/matriculations/${matriculationId}/tools/${toolId}/like`;
    return this.http.post<Tool>(url, tool);
  }


}
