import * as Actions from "../actions";
import { App } from '@app/core/models/app.model';


export interface AppState {
  data: App | null,
  error: Error | null,
  loaded: boolean,
  loading: boolean,
}

export const initialState: AppState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: Actions.AppActions): AppState {

  switch (action.type) {

    case Actions.AppActionTypes.APP_LOAD: {
      return { ...state, loading: true }
    }

    case Actions.AppActionTypes.APP_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
      }
    }

    case Actions.AppActionTypes.APP_FAIL: {
      return { ...state, loading: false, loaded: false }
    }
  }

  return state;
}
