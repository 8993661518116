
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '@app/core/services/theme.service';

import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { UserContextService } from '@app/core/services/user-context.service';
import { UserContext } from '@app/core/models/user-context';
import { AlertService } from '@app/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { MatriculationService } from '@app/views/user/service/matriculation.service';
import { UserAssessmentService } from '@app/views/user/service/user-assessments.service';
import { Question } from '@app/views/user/model/assessment/question';
import { Assessment } from '@app/views/user/model/assessment/assessment';
import { Alternative } from '@app/views/user/model/assessment/alternative';
import { Matriculation } from '@app/views/user/model/learning/matriculation';
import { UserPunctuationService } from '@app/views/user/service/user-punctuartion.service';




@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.sass']
})
export class AnswersComponent implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  matriculationId: number;
  assessmentId: number;
  questions: Array<Question>;
  questionsList: FormArray;
  progressBar: number = 0;
  currentQuestion: number = 10;

  public itensIndex = 0;

  public assessmentForm: FormGroup;

  userContext: UserContext;
  customerId: number;
  constructor(
    private userAssessmentService: UserAssessmentService,
    private form: FormBuilder,
    private route: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    private themeService: ThemeService,
    private matriculationService: MatriculationService,
    private userContextService: UserContextService,
    private userPunctuationService: UserPunctuationService
  ) { }

  ngOnInit() {

    this.themeService.showNav();

    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];
    this.assessmentId = this.activatedRoute.snapshot.params['assessmentId'];
    this.user$ = this.credentialsService.getUserCredentials();

    this.assessmentForm = this.form.group({
      assessmentId: ['', Validators.required],
      assessmentName: [],
      description: [],
      assessmentStatusId: [],
      assessmentTypeId: [],
      matriculationId: [this.matriculationId, Validators.required],
      questions: this.form.array([])
    });
    this.questionsList = this.assessmentForm.get('questions') as FormArray;


    this.user$.subscribe(user => {
      this.user = user;
      this.customerId = user.customerId;
      this.loadAssessment(this.user.id, this.matriculationId, this.assessmentId);
    });

  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  addIndexItens(index) {
    if (this.itensIndex < index) {
      this.itensIndex = index;
      document.getElementById(index).scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  get questionsFormGroup() {
    return this.assessmentForm.get('questions') as FormArray;
  }

  public loadAssessment(userId: number, matriculationId: number, assessmentId: number) {

    this.userContextService.inExecution(this.userContextService.ASSESSMENT);

    this.userAssessmentService
      .getAssessmentAnswers(userId, matriculationId, assessmentId)
      .subscribe(assessment => {          
        this.assessmentForm.patchValue(assessment);
        this.loadQuestions(assessment);
      });
  }

  private loadQuestions(data: Assessment) {
    this.removeAllQuestions();
    data.questions.forEach(question => {
      this.questionsList.push(this.setQuestion(question));
    });
  }
  private setQuestion(question: Question) {
    if (!question) {
      question = new Question();
    }
    const formGroup = this.form.group({
      questionId: [question.questionId],
      questionTitle: [question.questionTitle, [Validators.required]],
      answerId: [null, [Validators.required]],
      score: [],
      idealBetweenMin: [question.idealBetweenMin],
      idealBetweenMax: [question.idealBetweenMax],
      competenceId: [question.competenceId],
      profileId: [question.profileId],
      alternatives: this.form.array([])
    });
    const alternatives = this.getAlternativesFormGroup(formGroup);
    if (question.alternatives) {
      question.alternatives.forEach(alternative => {
        alternatives.push(this.setAlternative(alternative));
      });
    }
    return formGroup;
  };

  private setAlternative(alternative: Alternative) {

    const formGroup = this.form.group({
      alternativeId: [alternative.alternativeId],
      alternativeTitle: [alternative.alternativeTitle],
      score: [alternative.score]
    });

    return formGroup;
  }

  public getAlternativesFormGroup(formGroup: FormGroup): FormArray {
    return formGroup.get('alternatives') as FormArray;
  }

  private removeAllQuestions() {
    const total = this.questionsList.length;
    for (let i = 0; i < total; i++) {
      this.questionsList.removeAt(0);
    }
  }

  public getAnswers() {
    this.questionsList = this.assessmentForm.get('questions') as FormArray;
  }

  public setQuestionScore(question: FormGroup, alternative: FormGroup) {

    if (this.progressBar < 100) {
      const total = this.questionsList.controls.length;
      let answerCount = 1;
      for (let i = 0; i < total; i++) {
        if (
          this.questionsList.controls[i].get("answerId").value &&
          alternative.get("alternativeId").value !==
            this.questionsList.controls[i].get("answerId").value
        ) {
          answerCount++;
        }
      }
      const percent = (100 * answerCount) / total;
      this.progressBar = parseInt(percent.toString());

      question.get("score").setValue(alternative.get("score").value);
    }

  }
  
  save() {
    const assessment = this.assessmentForm.getRawValue() as Assessment;
    console.log(assessment)
    if (this.assessmentForm.valid) {

      assessment.customerId = this.customerId;
      this.userAssessmentService
        .save(this.user.id, this.matriculationId, this.assessmentId, assessment)
        .subscribe(assessment => {
          if (assessment.recommendedTrainingId && assessment.recommendedTrainingId > 0) {
            const matriculation = new Matriculation();
            matriculation.trainingId = assessment.recommendedTrainingId;
            matriculation.recommendedAssessmentId = assessment.assessmentId;
            this.matriculationService.matriculation(this.user.id, matriculation.trainingId, matriculation)
              .subscribe(training => {                
                this.userContextService.stopExecution();
                this.route.navigate([`assessments/${this.assessmentId}/report/${this.matriculationId}/recommendedTrail/${assessment.recommendedTrainingId}`]);
              }, error => {                
                this.alertService.danger(this.translate.instant('assessment.save-error'));
                this.userContextService.stopExecution();
                this.route.navigate([`assessments/${this.assessmentId}/report/${this.matriculationId}`]);
              })
          } else {
            this.userContextService.stopExecution();
            this.route.navigate([`assessments/${this.assessmentId}/report/${this.matriculationId}`]);
          }
          this.userPunctuationService.savePunctuation(this.user.id, this.user.customerId, 1, this.assessmentId, 40)
            .subscribe(result => {            
              console.log('FINALIZOU O ASSESSMENT - PONTOS');
            });
        });
    }
  }
  
  public cancel() {
    this.userContextService.stopExecution();
    this.route.navigate([`/assessments`]);
  }


}
