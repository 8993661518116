<form class="login-form form" #form='ngForm' [formGroup]="registerPasswordForm" (submit)="save()">

  <div class="column is-12" style="padding-bottom: 60px;">
    <ats-language-switch login="show"></ats-language-switch>
  </div>
  <div class="field text-center logo-login" *ngIf="(customerPreference$ | async) as customerPreference">
    <img src="{{getLogo(customerPreference?.logo)}}">
  </div>

  <h1 class="title-login">{{ 'register.new-password' | translate }}</h1>
  <p class="sub-title-login">{{ 'register.register-new-password' | translate }} {{userEmail}}</p>

  <!-- <div class="notification is-danger" [innerHTML]=""></div> -->

  <div class="margin-login">
    <div class="field p-0">
      <p class="itens-login">{{ 'register.input-pass' | translate }}</p>
      <input type="password" class="input" formControlName="password" placeholder="{{ 'register.type-new-password' | translate }}" />
      <app-alert-field alertType=3 alertTextTranslate="register.required"
        *ngIf="registerPasswordForm.get('password').errors?.required && form.submitted"></app-alert-field>
        <app-alert-field alertType=2 alertTextTranslate="login.weak-password" *ngIf="registerPasswordForm.get('password').errors?.pattern && form.submitted"></app-alert-field>
    </div>

    <div class="field p-0">
      <p class="itens-login">{{ 'register.input-cfm-pass' | translate }}</p>
      <input type="password" class="input" formControlName="passwordConfirm" placeholder="{{ 'register.new-password-confirm' | translate }}" />
      <app-alert-field alertType=3 alertTextTranslate="register.required"
        *ngIf="registerPasswordForm.get('passwordConfirm').errors?.required && form.submitted"></app-alert-field>
      <app-alert-field alertType=2 alertTextTranslate="register.dont-match"
        *ngIf="registerPasswordForm.get('passwordConfirm').errors?.matchPassword && form.submitted"></app-alert-field>
    </div>

    <div class="field">
      <p class="control txt-center">
        <button type="submit" class="btn button-login">
          {{ 'general.save' | translate }}
        </button>
      </p>
    </div>
  </div>
</form>
