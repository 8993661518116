<div class="column is-12">
  <img class="icon-not-found" src="../../../../assets/images/login/icon-alert-not-found.png">
</div>

<div class="column is-12">
  <h1 class="title">
    Conta não cadastrada
  </h1>
</div>

<div class="column is-12">
  <p class="text">
    Não foi localizada nenhuma conta com este domínio, verifique a url digitada ou entre em contato com o fornecedor
    da sua conta.
  </p>
</div>

<div class="column is-12">
  <img class="img-not-found" src="../../../../assets/images/login/img-not-found.png">
</div>
