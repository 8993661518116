<section class="split-section " style="background: #60D5BD;" [ngClass]="{'bg-page': showBlocked}">
  <div class="split-section__column">
    <div class="img-color"></div>
  </div>
  <div class="split-section__column format-login background-section" [ngClass]="{'': showBlocked}">
    <app-alert></app-alert>
    <router-outlet *ngIf="!showLoading && !showCustomerBlocked && !showNotFound"></router-outlet>
    <app-loading-page *ngIf="showLoading"></app-loading-page>
    <app-blocked-account *ngIf="showCustomerBlocked"></app-blocked-account>
    <app-not-found-account *ngIf="showNotFound"></app-not-found-account>
  </div>
</section>