export class UserInvite {
    conferenceId: number;

    id: number;

    userId: number;

    guestName: string;

    guestEmail: string;

    newRegistration: boolean;

    alreadyRegistered: boolean;

}
