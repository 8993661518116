
export class User {
  id: number;
  cpf: string;
  fullname: string;
  email: string;
  cellphone: string;
  birthday: Date;
  frmtBirthday: string;
  photo: string;
  zipcode: string;
  street: string;
  number: string;
  district: string;
  complement: string;
  cityId: number;
  stateId: number;
  countryId: number;
  customerId: number;
  groupId: number;
}
