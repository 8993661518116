import { FormGroup } from '@angular/forms';

export class AppValidator {

  static MatchPassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {

      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ matchPassword: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static MatchEmail(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ matchPassword: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static MatchCPF(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);

      // set error on matchingControl if validation fails[]
      if (control.value) {

        if (!AppValidator.cpf(control.value)) {
          control.setErrors({ cpfInvalid: true });
        } else {
          control.setErrors(null);
        }
      }
    }
  }

  // static MatchEmail(control: AbstractControl) {
  //   const email = control.get('email').value;
  //   const confirmEmail = control.get('confirmEmail').value;
  //   if (email != confirmEmail) {
  //     control.get('confirmEmail').setErrors({ matchEmail: true });
  //   } else {
  //     return null;
  //   }
  // }

  public static cpf(cpf: string): boolean {
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if ((cpf == '00000000000') || (cpf == '11111111111')
      || (cpf == '22222222222') || (cpf == '33333333333')
      || (cpf == '44444444444') || (cpf == '55555555555')
      || (cpf == '66666666666') || (cpf == '77777777777')
      || (cpf == '88888888888') || (cpf == '99999999999')) {
      return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    } else {
      return true;
    }
  }

  static MatchDateStartDateEnd(controlMonthStartName: string, controlYearStartName: string,
    controlMonthEndName: string, controlYearEndName: string) {
    return (formGroup: FormGroup) => {
      const controlMonthStart = formGroup.get(controlMonthStartName);
      const controlYearStart = formGroup.get(controlYearStartName);
      const controlMonthEnd = formGroup.get(controlMonthEndName);
      const controlYearEnd = formGroup.get(controlYearEndName);

      if (controlMonthStart.value && controlYearStart.value
                                  && controlMonthEnd.value
                                  && controlYearEnd.value) {

        const dateStart = new Date(controlYearStart.value, (controlMonthStart.value + 1), 1);
        const dateEnd = new Date(controlYearEnd.value, (controlMonthEnd.value + 1), 1);

        if (dateEnd < dateStart) {
          controlMonthStart.setErrors({ matchDateStartEnd: true });
          controlYearStart.setErrors({ matchDateStartEnd: true });
          controlMonthEnd.setErrors({ matchDateStartEnd: true });
          controlYearEnd.setErrors({ matchDateStartEnd: true });
        } else {
          controlMonthStart.setErrors(null);
          controlYearStart.setErrors(null);
          controlMonthEnd.setErrors(null);
          controlYearEnd.setErrors(null);

        }
      }
    }
  }

  static MatchDateDayMounth(controlDayName: string, controlMonthName: string, controlYearName: string) {
    return (formGroup: FormGroup) => {
      const controlDay = formGroup.get(controlDayName);
      const controlMonth = formGroup.get(controlMonthName);
      const controlYear = formGroup.get(controlYearName);

      const mounthsWith31 = [1, 3, 5, 7, 8, 10, 12];
      const day = controlDay.value;
      const mounth = controlMonth.value;
      const year = controlYear.value;

      if (mounth === 2) {
        let bissexto = false;
        if (Number(year) % 400 === 0) {
          bissexto = true;
        } else if ((Number(year) % 4 === 0) && (Number(year) % 100 !== 0)) {
          bissexto = true;
        } else {
          bissexto = false;
        }
        if (bissexto && Number(day) > 29) {
          controlDay.setErrors({ matchDateDayMounth: true });
          controlMonth.setErrors({ matchDateDayMounth: true });
          controlYear.setErrors({ matchDateDayMounth: true });
        } else if (Number(day) > 28) {
          controlDay.setErrors({ matchDateDayMounth: true });
          controlMonth.setErrors({ matchDateDayMounth: true });
          controlYear.setErrors({ matchDateDayMounth: true });
        }
      }

      if (!mounthsWith31.includes(mounth) && Number(day) === 31) {
        if (mounth) {
          controlDay.setErrors({ matchDateDayMounth: true });
          controlMonth.setErrors({ matchDateDayMounth: true });
          //controlYear.setErrors({ matchDateDayMounth: true });
        }
      }
    };
  }

  static MatchSurname(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);
      if (control && control.value) {

        const clearText = control.value.trim();
        const surnameArray = clearText.split(' ');

        if (surnameArray.length === 1) {
          control.setErrors({ surnameInvalid: true });
        }
      }
    };
  }

}
