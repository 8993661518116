import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found-account',
  templateUrl: './not-found-account.component.html',
  styleUrls: ['./not-found-account.component.sass']
})
export class NotFoundAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
