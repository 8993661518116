import { MatriculationService } from '@app/views/user/service/matriculation.service';
import { Matriculation } from '@app/views/user/model/learning/matriculation';
import { Router, ActivatedRoute } from '@angular/router';
import { OnInit, Component, Input } from '@angular/core';
import { ThemeService } from '@app/core/services/theme.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { BaseView } from '@app/views/shared/BaseView';
import * as html2pdf from 'html2pdf.js';
import { environment } from '@env/environment';
@Component({
  selector: 'app-certified',
  templateUrl: './certified.component.html',
  styleUrls: ['./certified.component.sass']
})
export class CertifiedComponent extends BaseView implements OnInit {

  @Input() timeout = 3000;

  user$: Observable<Credentials>;
  user: Credentials;
  matriculation: Matriculation;
  matriculationId: number;
  isDevelopment = environment.development;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    public matriculationService: MatriculationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super()
  }

  ngOnInit() {
    this.user$ = this.credentialsService.getUserCredentials();
    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];

    this.user$.subscribe(user => {
      this.user = user;
      this.loadItens();
    });
  }

  generetePdf() {
    var element = document.getElementById('element-to-print');
    var opt = {
      margin: [0, 0, 0, 0],
      filename: 'certificado.pdf',
      image: {
          type: 'jpeg',
          quality: 1
      },
      jsPDF: {
          unit: 'in',
          format: 'A4',
          orientation: 'landscape'
      },
      html2canvas: {
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          allowTaint: false,
      },
      pagebreak: {
          mode: ['avoid-all', 'css', 'legacy']
      },
  };
  html2pdf().set(opt).from(element).save();
  }

  public loadItens() {
    if (this.user && this.user.id && this.matriculationId) {
      this.matriculationService.getTrainingMatriculationCertified(this.user.id, this.matriculationId)
        .subscribe(data => {
          this.matriculation = data;         
        })
    }
  }
}
