<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12 pb-0">
        <h1 class="title-default mb-50" [style.color]="user.mainColor">{{'products-basket.purchase' |
          translate}}</h1>
      </div>

      <div class="column is-8" *ngIf="order?.products?.length > 0">
        <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-basket.basket' |
          translate}}</h1>
        <div class="box-default" *ngFor="let product of order.products; let i = index " style="margin-bottom: 10px;">
          <div class="columns is-multiline">
            <div class="text-center">
              <img class="img-info product-img-item" [src]="getProductImage(product.urlImage)">
            </div>
            <div class="column">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <p class="text-default-card m-0"><b>{{product.name}}</b>
                    <a class="link-default hover-itens remove-item-mobile" (click)="removeItem(i)">{{'products-basket.remove-item' | translate}}</a>
                  </p>
                  <p class="sub-text">{{product.briefDescription}}</p>
                </div>
                <div class="column is-12" *ngIf="">
                  <i class="fas fa-star icon-classification hover-itens icon-classification-active"></i>
                  <i class="fas fa-star icon-classification hover-itens"></i>
                  <i class="fas fa-star icon-classification hover-itens"></i>
                  <i class="fas fa-star icon-classification hover-itens"></i>
                  <i class="fas fa-star icon-classification hover-itens"></i>
                  <span class="classification">7 {{'products-basket.classifications' | translate}}</span>
                </div>
                <div class="column is-12">
                  <p class="text-default-card m-0" *ngIf="product.showAllDescrition">{{product.description}}</p>
                  <p class="text-default-card m-0" *ngIf="!product.showAllDescrition">{{product.limitedDescription}}</p>
                </div>
                <div class="column is-12 pt-0">
                  <span class="readmore hover-itens m-0" *ngIf="!product.showAllDescrition"
                    (click)="(product.showAllDescrition = !product.showAllDescrition)">{{ 'products.read-more' |
                    translate }}</span>
                  <span class="readmore hover-itens m-0" *ngIf="product.showAllDescrition"
                    (click)="(product.showAllDescrition = !product.showAllDescrition)">{{ 'products.read-less' |
                    translate }}</span>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column center-vertical">
                  <span class="text-default-card m-0">{{'products-basket.quantity' | translate}}</span>
                  <span class="sub-pagination hover-itens" (click)="lessItem(i)">-</span>
                  <input class="input quantity-pagination" type="text" value="{{product.quantity}}">
                  <span class="sub-pagination hover-itens" (click)="moreItem(i)">+</span>
                </div>
                <div class="column text-right">
                  <p class="text-default-card mr-10 mb-0 discount" *ngIf="product.discount" >{{product.fmtPrice}}</p>
                  <span class="text-default-card mb-0 price" *ngIf="product.discount"><b>{{product.fmtDiscountPrice}}</b></span>
                  <span class="text-default-card mb-0 price" *ngIf="!product.discount"><b>{{product.fmtPrice}}</b></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4" *ngIf="order?.products?.length > 0">
        <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-basket.resume' | translate}}</h1>
        <div class="box-default p-10">
          <div class="colums is-multiline">
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.sub-total' | translate}}
                      <span *ngIf="order.totalItens > 1">({{order.totalItens}} itens)</span>
                      <span *ngIf="order.totalItens  == 1">({{order.totalItens}} item)</span>
                    </b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0 iten-sub-total"><b>{{order.fmtSubTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.amount' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0 iten-sub-total"><b>{{order.fmtTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.in-until' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtTotal}}</b></p>
                  <p class="text-default-card text-right m-0"
                      *ngIf="order?.totalInstallments"
                      style="font-size: 14px;">{{'products-basket.in-until' |
                    translate}} <b> {{order.totalInstallments}}x</b> {{'general.of' | translate}}
                    <b>{{order.fmtInstallmentValue}} </b> {{'products-basket.interest-free' | translate}}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-12" *ngIf="order.totalDiscounted">
              <hr class="m-0">
            </div>
            <div class="column is-12" *ngIf="order.totalDiscounted">
              <p class="btn-discount m-0">{{'products-basket.saving-up' | translate}} {{order.fmtTotalDiscounted}}</p>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <a class="btn-default-confirmed column is-12"
                (click)="purchase()">{{'general.continue' | translate}}</a>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <a class="btn-default-cancel column is-12" routerLink="/virtual-store">{{'products-basket.more-products'
                | translate}}</a>
            </div>
          </div>
        </div>
      </div>

      <!-- CASO A CESTA VAZIA -->
      <div class="column is-12" *ngIf="!order || order?.products?.length == 0 || noProduct">
        <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-basket.basket' | translate}}
        </h1>
        <div class="columns is-multiline">
          <div class="column is-9 center-margin-auto">
            <div class="box-default null-basket-img-padding">
              <div class="null-basket-img">
                <img src="assets/images/icons/sacola.svg">
              </div>
              <p class="text-default-card text-center mt-50"><b>{{'products-basket.empty-basket' | translate}}</b></p>
              <div class="text-center">
                <a class="btn-default-confirmed mr-10" routerLink="/dashboard">Página inicial</a>
                <a class="btn-default-confirmed" routerLink="/virtual-store">{{'products-basket.keep-buying' |
                  translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
