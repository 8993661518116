export class CreditCard {

  number: string;

  holderName: string;

  brand: string;

  displayNumber: string;

  token: string;

  invoiceId: string;

  quantityPlots: number;

  installmentValue: number;
}
