import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as ThemeStore from '@app/core/store';
import { ThemeService } from "@app/core/services/theme.service"

@Component({
  selector: 'app-theme',
  template: `<div class="theme-loading">Theme Component</div>`,
  styleUrls: ['./theme.component.sass']
})
export class ThemeComponent implements OnInit {

  constructor(
    private routeParam: ActivatedRoute,
    private theme: ThemeService,
    private router: Router,
    private store: Store<ThemeStore.CoreState>
  ) { }

  ngOnInit() {
    this.routeParam.paramMap.subscribe(param => {
      this.store.dispatch(new ThemeStore.ThemeLoad(param.get('name')));
      this.router.navigate(['/']);
    })
  }
  
}
