<div *ngIf="(user$ | async) as user">
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation" [style.background-color]="user.mainColor">
      <div class="navbar-brand">  
        <div class="center-vertical responsive-language" *ngIf="!context.inExecution">
          <ats-language-switch *ngIf=""></ats-language-switch>
        </div>

        <a role="button" class="navbar-burger burger" (click)="responsiveMenu = !responsiveMenu"
          [ngClass]="{'is-active': responsiveMenu}" aria-label="menu" aria-expanded="false"
          data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div style="display: flex;">
        <img class="size-logo" src="../../../assets/images/logo/longevita-solid.svg" routerLink="/dashboard">
        <a class="navbar-brand" href="/" style="font-size:24px; font-weight: 500; text-decoration-line: none; color: #7d7a65;">longev<span style="font-weight: 600; color: #7d7a65;">prev</span></a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu" [ngClass]="{'is-active': responsiveMenu}">
        <div class="navbar-start" *ngIf="!context.inExecution">
          <a class="navbar-item navbar-item-houver" [ngClass]="{'active-itens-menu': showRouter === '/dashboard'}"
            routerLink="/dashboard" (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/dashboard.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.dashboard' | translate }}</p>
            </div>
          </a>

          <a class="navbar-item navbar-item-houver" *ngIf="showVirtualStoreModule"
            [ngClass]="{'active-itens-menu': showRouter === '/virtual-store'}" routerLink="/virtual-store"
            (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/store.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.store' | translate }}</p>
            </div>
          </a>

          <a class="navbar-item navbar-item-houver" *ngIf="showCarrerPlanModule && false"
            [ngClass]="{'active-itens-menu': showRouter === '/career-path'}" routerLink="/career-path"
            (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/certificate.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.career_path' | translate }}</p>
            </div>
          </a>

          <!-- <a class="navbar-item navbar-item-houver" *ngIf="showAssessmentModule"
            [ngClass]="{'active-itens-menu': showRouter === '/assessments'}" routerLink="/assessments"
            (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/assessment-01.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.assessment' | translate }}</p>
            </div>
          </a> -->

          <!-- <a class="navbar-item navbar-item-houver" *ngIf="showTrailsModule"</a>

          <a class="navbar-item navbar-item-houver" *ngIf="showTrailsModule"
            [ngClass]="{'active-itens-menu': showRouter === '/trails/type/2'}" routerLink="/trails/type/2"
            (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/trilha.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.trail' | translate }}</p>
            </div>
          </a> -->

          <a class="navbar-item navbar-item-houver" *ngIf="showTrainingsModule"
            [ngClass]="{'active-itens-menu': showRouter === '/trainings/type/1'}" routerLink="/trainings/type/1"
            (click)="showProfile = false; responsiveMenu = false">
            <img src="/assets/images/icons/treinamento.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.training' | translate }}</p>
            </div>
          </a>

          <a class="navbar-item navbar-item-houver" *ngIf="showConferencesModule"
            [ngClass]="{'active-itens-menu': showRouter === '/conference'}" routerLink="/conference"
            (click)="responsiveMenu = false">
            <img src="/assets/images/icons/conferencia.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.conference' | translate }}</p>
            </div>
          </a>
          <a class="navbar-item navbar-item-houver" *ngIf="showVirtualStoreModule"
            [ngClass]="{'active-itens-menu': showRouter === '/virtual-store/purchases/order'}"
            routerLink="/virtual-store/purchases/order" (click)="responsiveMenu = false">
            <img src="/assets/images/icons/sacola.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.shopping-cart' | translate }}</p>
            </div>
          </a>
          <a class="hover-sub navbar-item navbar-item-houver"
            [ngClass]="{'active-itens-menu': showRouter === '/profile' || showRouter === '/order-tracking'}"
            (click)="showProfile = !showProfile">
            <img src="/assets/images/icons/perfil.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.profile' | translate }}</p>
            </div>
            <div class="sub-menu" *ngIf="showProfile">
              <a class="navbar-item-sub" (click)="navigation('/order-tracking');responsiveMenu = false">
                <img src="/assets/images/icons/sacola.svg" *ngIf="false">
                <div class="text-center">
                  <p class="text-nav-bar">{{ 'navbar.my-requests' | translate }}</p>
                </div>
              </a>
              <a class="navbar-item-sub" (click)="navigation('/profile');responsiveMenu = false">
                <img src="/assets/images/icons/register.svg" *ngIf="false">
                <div class="text-center">
                  <p class="text-nav-bar">{{ 'navbar.my-data' | translate }}</p>
                </div>
              </a>
            </div>
          </a>

          <a class="navbar-item navbar-item-houver show-exit" (click)="logout()">
            <img src="/assets/images/icons/exit.svg">
            <div class="text-center">
              <p class="text-nav-bar">{{ 'navbar.menu-logout' | translate }}</p>
            </div>
          </a>

        </div>
        <div class="navbar-start" *ngIf="context.inExecution">
        </div>
        <div style="border-left: 1px solid #D4D8E3" class="center-vertical" *ngIf="!context.inExecution">
          <ats-language-switch class="responsive-language-none" *ngIf=""></ats-language-switch>
        </div>
        <div class="navbar-end" *ngIf="!context.inExecution">
          <div class="navbar-item">
            <img src="/assets/images/icons/exit.svg">
            <div class="buttons">
              <div class="text-center" style="cursor: pointer;" (click)="logout()">
                <p class="text-nav-bar">{{ 'navbar.menu-logout' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-end" *ngIf="context.inExecution">
          <div class="navbar-item">
            <div class="buttons" style="display: flex; justify-content: center; align-items: center;">
              <img src="/assets/images/icons/exit.svg">
              <div class="text-center" style="cursor: pointer;" (click)="close()">
                <p class="text-nav-bar">{{ 'navbar.menu-back' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <nav class="nav nav-vertical navbar-menu" *ngIf="dontShowNav !== 1 && !context.inExecution" id="v-pills-tab"
      role="tablist" aria-orientation="vertical" [ngClass]="{'show-menu-responsive': themeService.eventMenu}">
      <i class="fas fa-align-justify icon-left" (click)="viewMenu();"
        [ngClass]="{'icon-left-min': themeService.eventMenu}" *ngIf="false"></i>
        <!-- <a class="navbar-item ">
        <img class="size-logo" src="{{getLogo(user.logo)}}" routerLink="/dashboard">
      </a> -->
      <div class="nav-user-vertical  nav-vertical-resposive" *ngIf="!themeService.eventMenu">
       
        <div class="columns mt-15">
          <div class="column is-12">
            <div class="text-center">
              <h1 class="photo-name center-vertical" [style.background]="user.mainColor" *ngIf="!user.photo">
                {{user.nameInitials}}
                <img class="img-camera" src="assets/images/icons/camera.svg" alt="" title="Alterar imagem"
                  routerLink="/profile">
              </h1>
              <div class="box-img-profile" *ngIf="user.photo">
                <img src="{{getPhoto(user.photo)}}" title="Preview" class="nav-img-user" />
                <img class="img-camera" src="assets/images/icons/camera.svg" alt="" title="Alterar imagem"
                  routerLink="/profile">
              </div>
            </div>

            <h1 class="name-user">{{user.fullName}}</h1>
            <p class="email-user">{{user.userName}}</p>


            <div class="columns background-ranking is-multiline mt-20" style="display: flex !important;"
              *ngIf="userPunctuation?.patentId && userPunctuation?.patentName">
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <img class="nav-img-patent ml-0" src="assets/images/icons/patent_default.png"
                  *ngIf="userPunctuation?.patentUrlImage" [src]="getImage(userPunctuation?.patentUrlImage)">
                <img class="nav-img-vertical ml-0" *ngIf="!userPunctuation?.patentUrlImage"
                  src="assets/images/icons/patent_default.png" [style.background-color]="user.mainColor">
              </div>
              <div class="column is-6 center-vertical p-0" style="max-width: 75% !important;width: 75%;">
                <p class="text-competences" [style.color]="user.mainColor">{{userPunctuation?.patentName}}</p>
              </div>
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;" *ngIf="false">
                <p class="number-competences"></p>
              </div>
            </div>

            <div class="columns background-ranking is-multiline mt-20" style="display: flex !important;" *ngIf="false">
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <img class="nav-img-vertical ml-0" src="/assets/images/icons/engajamento.svg"
                  [style.background-color]="user.mainColor">
              </div>
              <div class="column is-6 center-vertical p-0" style="max-width: 50%;">
                <p class="text-competences" [style.color]="user.mainColor">{{ 'navbar.engagement' | translate }}</p>
              </div>
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <p class="number-competences">{{userPunctuation?.engagement}}%</p>
              </div>
            </div>
            <div class="columns is-multiline background-ranking" style="display: flex !important;">
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <img class="nav-img-vertical ml-0" src="/assets/images/icons/pontos.svg"
                  [style.background-color]="user.mainColor">
              </div>
              <div class="column is-6 center-vertical p-0" style="max-width: 50%;">
                <p class="text-competences" [style.color]="user.mainColor">{{ 'navbar.points' | translate }}</p>
              </div>
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <p class="number-competences">{{userPunctuation?.punctuation}}</p>
              </div>
            </div>
            <div class="columns is-multiline background-ranking" style="display: flex !important;">
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <img class="nav-img-vertical ml-0" src="/assets/images/icons/ranking.svg"
                  [style.background-color]="user.mainColor">
              </div>
              <div class="column is-6 center-vertical p-0" style="max-width: 50%;">
                <p class="text-competences" [style.color]="user.mainColor">{{ 'navbar.ranking' | translate }}</p>
              </div>
              <div class="column is-3 center-vertical p-0" style="max-width: 25%;">
                <p class="number-competences">{{userPunctuation?.position}}ª</p>
              </div>
            </div>

            <!-- CALENDÁRIO -->
            <!-- <div class="columns is-multiline mt-50">
            <div class="columns is-multiline mt-50" *ngIf="false">
              <div class="column is-12 center-vertical">
                <p class="title-gender" [style.background]="user.mainColor">{{ 'navbar.agenda' | translate }}</p>
              </div>
              <div class="column is-12">
                <div [ngSwitch]="view" style="padding: 9px;">
                  <div class="columns is-multiline box-calendar">
                    <div class="column is-2 center-vertical" style="justify-content: center; padding-bottom: 0;">
                      <i mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()" class="fas fa-chevron-left calendar-icons"></i>
                    </div>
                    <div class="column is-8 text-center" style="justify-content: center; padding-bottom: 0;">
                      <h3 class="calendar-title">{{ viewDate | calendarDate:(view + 'ViewTitle'):'pt' }}</h3>
                    </div>
                    <div class="column is-2 center-vertical" style="justify-content: center; padding-bottom: 0;">
                      <i class="fas fa-chevron-right calendar-icons" mwlCalendarNextView [view]="view"
                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"></i>
                    </div>
                    <div class="column is-12">
                      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                        [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                        (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)">
                      </mwl-calendar-month-view>
                      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)">
                      </mwl-calendar-week-view>
                      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)">
                      </mwl-calendar-day-view>
                    </div>

                    <ng-template #modalContent let-close="close">
                      <div class="modal-header">
                        <h5 class="modal-title">{{ 'navbar.ocurred' | translate }}</h5>
                        <button type="button" class="close" (click)="close()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
