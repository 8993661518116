import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';

import { debounceTime, switchMap, map, first, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class EmailNotTakenValidatorService {

    constructor(private authService: AuthService) { }

    checkUserEmailTaken() {

        return (control: AbstractControl) => {

            return control
                .valueChanges
                .pipe(debounceTime(300))
                .pipe(switchMap(email =>
                    this.authService.checkEmailTaken(email)
                ))
                .pipe(map(isTaken => isTaken ? { userEmailTaken: true } : null))
                .pipe(first());
        }

    }
}
