import * as Actions from "../actions";
import { Credentials } from '@app/core/models/credentials';


export interface AuthState {
  credentials: Credentials | null,
  user: object | null
  error: any,
  loaded: boolean,
  loading: boolean,
}

export const initialState: AuthState = {
  credentials: null,
  user: null,
  error: null,
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: Actions.AuthActions): AuthState {

  switch (action.type) {

    case Actions.AuthActionTypes.USER_REQUEST: {
      return { 
        ...state, 
        loading: true 
      }
    }

    case Actions.AuthActionTypes.AUTH_REQUEST: {
      return { 
        ...state, 
        loading: true 
      }
    }

    case Actions.AuthActionTypes.AUTH_SUCCESS: {
      return {
        ...state,
        credentials: action.payload,
        loading: false,
        loaded: true,
      }
    }

    case Actions.AuthActionTypes.USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        loaded: true,
      }
    }

    case Actions.AuthActionTypes.AUTH_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      }
    }

    case Actions.AuthActionTypes.USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      }
    }
  }

  return state;
}
