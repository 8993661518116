import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
// import * as ThemeStore from '@app/theme/store';
import * as ThemeStore from "@app/core/store/selectors/theme.selectors"
import { ThemeService } from "@app/core/services/theme.service";
import { LanguageService } from "@app/core/services/language.service";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  constructor(
    private theme: ThemeService,
    private store: Store<any>,
    private language: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ){}

  ngOnInit(){
    this.store.select(ThemeStore.getThemeData).subscribe(tema => {
      this.theme.setTheme(tema);
    })
    this.language.setLocale();

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(map(() => this.activatedRoute))
    .pipe(map(route => {
      while (route.firstChild) route = route.firstChild;
      return route;
    }))
    .pipe(switchMap(route => route.data))
    .subscribe(event => this.titleService.setTitle(event.title));
}


}
