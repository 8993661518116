
import { HttpClient } from "@angular/common/http";
import { Injectable, Output, EventEmitter } from "@angular/core";

import { environment } from "@env/environment";
import { Assessment } from "../model/assessment/assessment";
import { UserAssessmentReport } from "../model/assessment/user-assessment-report";



const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class UserAssessmentService {

  @Output() printResume: EventEmitter<boolean> = new EventEmitter();


  constructor(private http: HttpClient) { }


  public getAssessments(userId: number, name: string, statusId: number) {
    if (name) {
      name = encodeURIComponent(name);
    } else {
      name = '';
    }
    return this.http.get<Assessment[]>(`${API_URL}/${userId}/assessments?statusId=${statusId}&name=${name}`);
  }

  public getAssessment(userId: number, assessmentId: number , matriculationId: number ) {
    return this.http.get<Assessment>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}`);
  }
  
  public getAssessmentAnswers(userId: number, matriculationId: number, assessmentId: number) {
    return this.http.get<Assessment>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}/answers`);
  }
 
  public save(userId: number,matriculationId: number, assessmentId: number, assesment: Assessment) {
    return this.http.post<Assessment>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}/answers`, assesment);
  }

  public getAssessmentReport(userId: number,  assessmentId: number,matriculationId: number) {
    return this.http.get<UserAssessmentReport>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}/reports`);
  }

  public getGraphicsReport(userId: number,  assessmentId: number,matriculationId: number) {
    return this.http.get<UserAssessmentReport>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}/reports/graphics`);
  }

  public matriculate(userId: number, assessmentId: number, assesment: Assessment){
    return this.http.post<Assessment>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations`, assesment);
  }

  public update(userId: number, assessmentId: number, matriculationId: number, assesment: Assessment){
    return this.http.put<Assessment>(`${API_URL}/${userId}/assessments/${assessmentId}/matriculations/${matriculationId}`, assesment);
  }

  public updatePrintResume(print: boolean) {
    this.printResume.emit(print);
  }

}
