
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConferenceRoutingModule } from './conference-routing.module';

import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoadersConfig } from '@app/utils';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { ComponentsModule } from '@app/components/components.module';
import { AlertModule } from '@app/components/alert/alert.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ConferenceListComponent } from './conference-list/conference-list.component';
import { ConferenceProductsComponent } from './conference-home/conference-products/conference-products.component';
import { ConferencePlayerComponent } from './conference-home/conference-player/conference-player.component';
import { ConferenceChatComponent } from './conference-home/conference-chat/conference-chat.component';
import { ConferenceFilesComponent } from './conference-home/conference-files/conference-files.component';
import { ConferenceNotesComponent } from './conference-home/conference-notes/conference-notes.component';
import { AlertFieldModule } from '@app/components/alert-field/alert-field.module';
import { ConferenceHomeComponent } from './conference-home/conference-home.component';
import { FileDownloadComponent } from './conference-home/modal/file-download/file-download.component';
import { InviteUsersComponent } from './conference-home/modal/invite-users/invite-users.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ClipboardModule } from 'ngx-clipboard';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [ConferenceListComponent,
     ConferenceHomeComponent,
     ConferenceProductsComponent,
     ConferencePlayerComponent,
     ConferenceChatComponent,
     ConferenceFilesComponent,
     ConferenceNotesComponent,
     FileDownloadComponent,
     InviteUsersComponent
    ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    EditorModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    ConferenceRoutingModule,
    TranslateModule,
    AlertFieldModule,
    ClipboardModule,
  ]
})
export class ConferenceModule { }
