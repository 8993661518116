import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { RecoverPasswordGuard } from '@app/core/auth/recover-password.guard';
import { TranslateLoadersConfig } from "@app/utils";
import { AppRoutingModule } from '@app/app.routes';
import { AppComponent } from '@app/app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TagInputModule } from 'ngx-chips';

import { CoreModule } from "./core/core.module";
import { ToastrModule } from 'ngx-toastr';
import { LanguageService } from '@app/core/services/language.service';

import { environment } from "@env/environment";

import { ComponentsModule } from "@components/components.module";
import { ValidationsModule } from '@app/components/validations/validations.module';
import { BackArrowModule } from './components/back-arrow/back-arrow.module';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { LoginModule } from './views/login/login.module';
import { AssessmentModule } from './views/user/evaluation/assessments/assessment.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TrainingModule } from './views/user/learning/trainings/training.module';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { VirtualStoreModule } from './views/user/virtual-store/virtual-store.module';
import { CareerPathModule } from './views/user/learning/career-path/career-path.module';
import { AccountModule } from './views/user/account/account.module';
import { ConferenceModule } from './views/user/conference/conference.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { AlertModule } from './components/alert/alert.module';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
      ],
  imports: [
    AlertModule,
    LoginModule,
    DashboardModule,
    AccountModule,
    VirtualStoreModule,
    AssessmentModule,
    TrainingModule,
    ConferenceModule,
    CareerPathModule,
    TagInputModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(TranslateLoadersConfig),
    NgxGoogleAnalyticsModule.forRoot('traking-code'),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({maxAge:25, logOnly: !environment.development}),
    EffectsModule.forRoot([]),
    CoreModule,
    ValidationsModule,
    ComponentsModule,
    ToastrModule.forRoot(), // ToastrModule added
    ChartsModule,
    BackArrowModule,
    EditorModule,
    NgxMaskModule.forRoot(maskConfig),  
  ],
  providers: [
    AuthGuard,
    RecoverPasswordGuard,
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (LanguageService) => LanguageService.locale
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
