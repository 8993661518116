import { Alternative } from "./alternative";

export class Question {
  questionId: number;
  answerId: number;
  questionTitle: string;
  profileId: number;
	idealBetweenMin: number;
	idealBetweenMax: number;
  competenceId: number;
  alternatives: Array<Alternative>
}
