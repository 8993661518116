import { BaseView } from "@app/views/shared/BaseView";
import { MatriculationToolService } from "./../../../service/matriculation-tool.service";
import { MatriculationService } from "./../../../service/matriculation.service";

import { ThemeService } from "@app/core/services/theme.service";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { Credentials } from "@app/core/models/credentials";
import { CredentialsService } from "@app/core/services/credentials.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserContext } from "@app/core/models/user-context";
import { UserContextService } from "@app/core/services/user-context.service";
import { AlertService } from "@app/components/alert/alert.service";
import { environment } from "@env/environment";
import { Tool } from "@app/views/user/model/learning/tool";
import { Matriculation } from "@app/views/user/model/learning/matriculation";
import { ScormService } from "@app/views/user/service/scorm.service";
import { TranslateService } from "@ngx-translate/core";
import { UserPunctuationService } from "@app/views/user/service/user-punctuartion.service";
import { Module } from "@app/views/user/model/learning/module";

declare const Player: any;
declare const Vimeo: any;

@Component({
  selector: "app-training-tool",
  templateUrl: "./training-tool.component.html",
  styleUrls: ["./training-tool.component.sass"],
})
export class TrainingToolComponent extends BaseView implements OnInit {
  public typeId: number;
  trainingId: number;
  toolId: number;
  moduleId: number;
  matriculationId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  tool: Tool;
  tool$: Observable<Tool>;

  showFeedback: boolean = false;
  showUrlConclusion: boolean;
  urlContent: string;
  public API: any;

  public YT: any;
  public player: any;
  public reframed: Boolean = false;
  loadIframe: boolean;
  loadPlayerYoutube: boolean;
  loadImgPodcast: boolean;
  loadIframePodCast: boolean;
  loadIframePdf: boolean;
  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  public score = 0;
  public readonly maxScore = 100;
  public readonly minScore = 0;
  public readonly apiVersion: string = "not found";
  public showSearch: boolean;
  public showMenu = false;

  userContext: UserContext;

  public findAPITries = 0;
  @ViewChild("player_frame") player_frame: ElementRef<HTMLIFrameElement>;

  public toolInicinitialized: boolean;
  public vimeoLink: string;
  public youtubeLink: string;
  public playerVimeo: boolean = false;
  public playerYoutube: boolean = false;
  training: Matriculation;
  training$: Observable<Matriculation>;
  currentModule: Module;
  urlPrevious: string;
  urlNext: string;
  blockModules: boolean = false;
  scormInterval: any;
  downloadDocument:  boolean = false;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private matriculationService: MatriculationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private matriculationToolService: MatriculationToolService,
    private userContextService: UserContextService,
    private alertService: AlertService,
    private userPunctuationService: UserPunctuationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.typeId = this.activatedRoute.snapshot.params["typeId"];
    this.trainingId = this.activatedRoute.snapshot.params["trainingId"];
    this.matriculationId = this.activatedRoute.snapshot.params["matriculationId"];
    this.moduleId = this.activatedRoute.snapshot.params["moduleId"];
    this.toolId = this.activatedRoute.snapshot.params["toolId"];
    this.user$ = this.credentialsService.getUserCredentials();   
    this.userContextService.inMatriculartionExecutionById(this.typeId == 1 ? this.userContextService.TRAINING : this.userContextService.TRAIL, this.trainingId, this.matriculationId);
    this.user$.subscribe((user) => {
      this.user = user;
      this.training$ = this.matriculationService.getTrainingMatriculation(this.user.id, this.trainingId, this.matriculationId);
      this.training$.subscribe((matriculation) => {
        this.training = matriculation;
        this.loadCurrentModule(matriculation);
        this.loadCurrentTool(matriculation);
      });
    });
  }

  public checkBlockOfTheNextModule(modules: Module[]) {
    const blocks = modules.filter(x => x.dateReleased);
    if (blocks.length > 0) {
      this.blockModules = true
    }
  }

  public loadCurrentTool(matriculation: Matriculation) {
    this.currentModule = matriculation.modules.filter(
      (x) => x.id == this.moduleId
    )[0];
    this.tool = this.currentModule.tools.filter(x => x.id == this.toolId)[0];
    console.log(this.tool);
    if (this.tool) {
      this.toolInicinitialized = true;
      this.getContentUrl(this.tool);
    }
  }

  public loadCurrentModule(matriculation: Matriculation) {
    this.currentModule = matriculation.modules.filter((x) => x.id == this.moduleId)[0];
    this.checkBlockOfTheNextModule(matriculation.modules);
    this.loadNavigation(matriculation);
  }

  public changeCurrentModule(id) {
    this.currentModule = this.training.modules.filter((x) => x.id == id)[0];
    const currentTool = this.currentModule.tools[0];
    const url = this.getUrlNavigation(
      this.training,
      this.currentModule.id,
      currentTool.id
    );
    this.redirectTo(url);
  }

  public loadNavigation(matriculation: Matriculation) {
    const currentTool = this.currentModule.tools.filter((x) => x.id == this.toolId)[0];
    const previousPosition = currentTool.position - 1;
    const nextPositon = currentTool.position + 1;

    const previousTool = this.currentModule.tools.filter((x) => x.position == previousPosition)[0];
    if (previousTool) {
      this.urlPrevious = this.getUrlNavigation(
        matriculation,
        previousTool.moduleId,
        previousTool.id
      );
    } else {
      const previousModule = matriculation.modules.filter((x) => x.position == this.currentModule.position - 1)[0];
      if (previousModule) {
        const lastPostion = previousModule.tools.length - 1;
        const previousModuleFistTool = previousModule.tools[lastPostion];
        this.urlPrevious = this.getUrlNavigation(
          matriculation,
          previousModuleFistTool.moduleId,
          previousModuleFistTool.id
        );
      }
    }

    const nextTool = this.currentModule.tools.filter((x) => x.position == nextPositon)[0];
    if (nextTool) {
      this.urlNext = this.getUrlNavigation(
        matriculation,
        nextTool.moduleId,
        nextTool.id
      );
    } else {
      if (!this.blockModules) {
        const nextModule = matriculation.modules.filter((x) => x.position > this.currentModule.position)[0];
        if (nextModule) {
          const nextModuleFistTool = nextModule.tools[0];
          this.urlNext = this.getUrlNavigation(
            matriculation,
            nextModuleFistTool.moduleId,
            nextModuleFistTool.id
          );
        } else if (matriculation.matriculationStatusId == 3 || !nextTool) {
          this.showUrlConclusion = true;
        }
      }
    }
  }

  public getUrlNavigation(matriculation: Matriculation, moduleId: number, toolId: number) {
    if (matriculation.trainingTypeId == 1) {
      return `trainings/${matriculation.trainingId}/type/${matriculation.trainingTypeId}/training-tool/${matriculation.matriculationId}/module/${moduleId}/tool/${toolId}`;
    } else {
      return `trails/${matriculation.trainingId}/type/${matriculation.trainingTypeId}/trail-tool/${matriculation.matriculationId}/module/${moduleId}/tool/${toolId}`;
    }
  }

  public likeTrainingTool(like: boolean) {
    this.user$.subscribe((user) => {
      this.tool.like = like;
      this.matriculationToolService
        .likeTrainingTool(
          user.id,
          this.trainingId,
          this.matriculationId,
          this.tool.id,
          this.tool
        )
        .subscribe((tool) => {
          this.alertService.success(
            this.translate.instant("training.thanks-for-feedback")
          );
        });
    });
  }


  public updateMatriculation(callback: any) {
    const matriculation = new Matriculation();
    matriculation.userId = this.user.id;
    matriculation.trainingId = this.trainingId;
    matriculation.matriculationId = this.matriculationId;

    this.matriculationService
      .updateMatriculation(matriculation.userId, matriculation.trainingId, matriculation.matriculationId, matriculation)
      .subscribe(
        (data) => {
          if (data.matriculationStatusId == 2) {
            this.userPunctuationService
              .savePunctuation(
                this.user.id,
                this.user.customerId,
                this.typeId == 1 ? 10 : 11,
                this.trainingId,
                this.typeId == 1 ? 100 : 30
              )
              .subscribe((result) => {
                callback();
              });
          } else {
            callback();
          }
        },
        (error) => {
          this.alertService.warning(
            this.translate.instant("training.finalize-error")
          );
        }
      );
  }

  public toggleFeedback() {
    this.showFeedback = !this.showFeedback;
  }

  public finishTraining() {
    this.updateMatriculation(() => {
      this.toggleFeedback()
    })
  }

  public finishedTool(callback: any) {
    if (this.tool.toolTypeId == 3) {
      const API = window["API"];
      if (API.apiInitialized) {
        this.updateMatriculation(
          API.LMSFinish("", () => {
            callback();
          })
        );
      } else {
        callback();
      }
    } else {
      this.updateMatriculation(callback);
    }
  }

  private getContentUrl(tool: Tool) {
    this.toolInicinitialized = true;
    if (tool.toolTypeId == 1) {
      // VIDEO
      if (tool.url.includes("youtu")) {
        this.playerYoutube = true;
        let videoId = this.getVideoId(tool);
        this.youtubeLink = `https://mcs-ingames.s3.amazonaws.com/player/index.html?id=${videoId}`;
        this.updateTrackTool(3, "0");
      } else if (tool.url.includes("vimeo")) {
        this.playerVimeo = true;
        let videoId = this.getVideoId(tool);
        this.vimeoLink = `https://mcs-ingames.s3.amazonaws.com/player/index.html?id=${videoId}`;
        this.updateTrackTool(3, "0");
      }
    } else if (tool.toolTypeId == 2) {
      // PODCAST
      this.loadIframePodCast = true;
      this.loadImgPodcast = true;
      this.urlContent = tool.url;
      this.updateTrackTool(3, "0");
    } else if (tool.toolTypeId == 3) {
      // CURSO SCORM
      this.loadImgPodcast = false;
      this.initScorm();
      if (environment.development) {
        this.urlContent =
          "../../../../../../assets/scorm/scorm_teste/scormdriver/indexAPI.html";
      } else {
        this.urlContent = this.getResourceUriScorm(
          tool.folder + "/" + tool.lessons[0].url + "?wmode=transparent"
        );
      }
      this.loadIframe = true;
    } else if (tool.toolTypeId == 4) {
      // PDF
      this.loadIframePdf = true;
      this.urlContent = this.getResourceUri(tool.url);
      if (tool.url.includes('.doc') || tool.url.includes('.docx') ||
        tool.url.includes('.xls') || tool.url.includes('.xlsx')) {
          this.downloadDocument = true;
      }
      this.updateTrackTool(3, "0");
    }
  }

  private getResourceUriScorm(resourceUrl: string) {
    if (!resourceUrl) {
      return null;
    }
    const bucketUri = `${environment.domainUser}/s3/`;
    return `${bucketUri}${resourceUrl}`;
  }

  private updateTrackTool(statusId, lessonLocation) {

    if (this.toolInicinitialized) {
      const currentStatus = this.tool.toolAccessStatusId;
      if (!currentStatus || currentStatus != 3) {
        this.user$.subscribe((user) => {
          this.tool.toolAccessStatusId = statusId;
          this.tool.lessonLocation = lessonLocation;
          this.matriculationToolService
            .updateToolAccess(
              user.id,
              this.trainingId,
              this.matriculationId,
              this.tool.id,
              this.tool
            )
            .subscribe((data) => {
              this.tool = data;

              if (this.tool.toolAccessStatusId == 2) {
                this.userPunctuationService
                  .savePunctuation(
                    this.user.id,
                    this.user.customerId,
                    this.typeId == 1 ? 10 : 11,
                    this.trainingId,
                    10
                  )
                  .subscribe((result) => {
                    console.log("INICIANDO FERRAMENTA ");
                  });
              } else if (this.tool.toolAccessStatusId == 3) {
                this.userPunctuationService
                  .savePunctuation(
                    this.user.id,
                    this.user.customerId,
                    this.typeId == 1 ? 10 : 11,
                    this.trainingId,
                    10
                  )
                  .subscribe((result) => {
                    console.log("FINALIZOU A FERRAMENTA ");
                  });
              }
            });
        });
      }
    }
  }

  initScorm() {
    this.user$.subscribe((user) => {
      const matriculation = new Matriculation();
      matriculation.userId = user.id;
      matriculation.trainingId = this.trainingId;
      matriculation.matriculationId = this.tool.matriculationId;
      window["API"] = new ScormService(
        matriculation,
        this.matriculationService,
        this.tool,
        this.matriculationToolService
      );
      const currentModule = this.training.modules.filter(
        (x) => x.id == this.moduleId
      )[0];
      if (currentModule) {
        const currentTool = currentModule.tools.filter(
          (x) => x.id == this.toolId
        )[0];
        if (currentTool.toolAccessStatusId < 3) {
          this.scormInterval = setInterval(
            () => this.verifyScormStatus(),
            2000
          );
        }
      }
    });
  }

  verifyScormStatus() {
    const API = window["API"];

    if (API.apiInitialized) {
      const status = API.LMSGetValue("cmi.core.lesson_status");
      if (status == "passed" || status == "completed") {
        clearInterval(this.scormInterval);
        console.log("Curso Finalizou");
        this.finishedTool(() => {
          this.showUrlConclusion = true;
        });
      } else {
        console.log("Aind não Finalizou o Curso");
      }
    } else {
      console.log("Não inicializou a api do scorm");
      clearInterval(this.scormInterval);
    }
  }

  getVideoId(tool: Tool) {
    const link = tool.url.split("/");
    const size = link.length - 1;
    let videoId = link[size];

    if (videoId.includes("watch?v=")) {
      if (videoId.includes("&")) {
        const sublink = videoId.substring(
          videoId.indexOf("=") + 1,
          videoId.indexOf("&")
        );
        videoId = sublink;
      } else {
        const sublink = videoId.split("=");
        videoId = sublink[1];
      }
    }
    return videoId;
  }

  redirectUrl() {
    if (this.typeId == 1) {
      this.route.navigate([
        `trainings/${this.trainingId}/type/1/training-home/${this.matriculationId}`,
      ]);
    } else {
      this.route.navigate([
        `trails/${this.trainingId}/type/2/trail-home/${this.matriculationId}`,
      ]);
    }
  }
  public redirect() {
    this.userContextService.stopExecution();
    this.finishedTool(() => {
      this.redirectUrl();
    });
  }

  public redirectTo(url: string) {
    this.finishedTool(() => {
      window.location.href = `#/${url}`;
      window.location.reload();
    });
  }

  public redirectCertified(trainingTypeId: number, trainingId: number, matriculationTrainingId: number) {
    this.userContextService.stopExecution();
    this.route.navigate([
      `trails/${trainingId}/type/${trainingTypeId}/trail-certified/${matriculationTrainingId}`,
    ]);
  }
}
