import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as AppReducers from "../reducers";

export const selectCoreState = createFeatureSelector<AppReducers.CoreState>('core');

export const getAppState = createSelector(
  selectCoreState,
  (state: AppReducers.CoreState) => state.app
);

export const getAppData = createSelector(
  getAppState,
  AppReducers.selectAppData
);

export const getAppError = createSelector(
  getAppState,
  AppReducers.selectAppError
)