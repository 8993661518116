<section class="section" *ngIf="(user$ | async) as user" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12 pb-0">
        <h1 class="title-default mb-50" [style.color]="user.mainColor">{{ 'products-basket.resume' | translate }}</h1>
      </div>
      <div class="column is-9 center-margin-auto">
        <div class="box-default p-50">
          <h1 class="title-default2 m-0 text-center" [style.color]="user.mainColor">{{ 'store.purchase-data' | translate
            }}</h1>
          <div class="colums is-multiline">
            <div class="column is-12 text-center mt-50" *ngIf="order?.paymentTypeId == 2">
              <p class="check-payment"><i class="fas fa-check-circle"></i> {{ 'store.payment-success' | translate }}</p>
            </div>
            <!-- BOLETO -->
            <div class="column is-12 text-center mt-50" *ngIf="order?.paymentTypeId == 1">
              <div class="columns is-multiline">
                <div class="column is-4 center-margin-auto">
                  <img [src]="order?.invoice?.bankSlip?.barcode">
                </div>
                <div class="column is-4 center-margin-auto">
                  <p class="text-default-black text-left"><b>{{ 'store.copy-code' | translate }}
                    </b><b>{{order?.invoice?.bankSlip?.barcodeData}}</b></p>
                </div>
              </div>
            </div>
            <!-- CARTAO -->
            <div class="column is-12 text-center mt-20">
              <!-- BOLETO -->
              <p class="text-default-card" *ngIf="order?.paymentTypeId == 1">{{ 'store.confirm-bankslip' | translate }}
                <b>{{order?.payer?.email}}</b>
              </p>
              <p *ngIf="order?.paymentTypeId == 1">
                {{ 'store.confirmation-advice' | translate }}<br>
                {{ 'store.expire-date-advice' | translate }}</p>
              <!-- BOLETO -->
              <p class="text-default-card" *ngIf="order?.paymentTypeId == 2">{{ 'store.confirm-fiscal' | translate }}
                <b>{{order?.payer?.email}}</b>
              </p>
            </div>
            <div class="column is-12 mt-20">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0">
                    <span ></span>
                    <b *ngIf="order?.totalItens == 1">{{ 'store.total' | translate }} ({{order?.totalItens}} item)</b>
                    <b *ngIf="order?.totalItens > 1">{{ 'store.total' | translate }} ({{order?.totalItens}} itens)</b>
                  </p>

                </div>
                <div class="column text-right">
                  <p class="text-default-black m-0"><b>{{order?.fmtSubTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12"  >
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{ 'store.discounts' | translate }}</b></p>
                </div>
                <div class="column text-right">
                  <p class="text-default-black m-0" *ngIf="order?.totalDiscounted"><b>{{order?.fmtTotalDiscounted}}</b></p>
                  <p class="text-default-black m-0" *ngIf="!order?.totalDiscounted"><b>R$ 0,00</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"  *ngIf="order?.paymentTypeId == 1"><b>{{ 'store.bankslip-payment' | translate }}</b></p>
                  <p class="text-default-card m-0"  *ngIf="order?.paymentTypeId == 2"><b>{{ 'store.creditcard-payment' | translate }}</b></p>
                </div>
                <div class="column text-right">
                  <p class="text-default-card m-0" style="display: inline-block"><b class="mr-10">{{ 'store.total-value'
                      | translate }}</b></p>
                  <p class="text-default-black m-0" style="display: inline-block"><b>{{order?.fmtTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <!-- BOLETO -->
            <div class="column is-12 text-center" *ngIf="order?.paymentTypeId == 1">
              <p class="text-default-black m-0"><b>{{ 'store.value-advice' | translate }}</b></p>
            </div>
            <!-- BOLETO -->
            <div class="column is-12 mt-50 text-center">
              <a class="btn-default-confirmed" (click)="redirect()">{{ 'store.track-order' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
