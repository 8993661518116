import { of, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { mergeMap, map, catchError, switchMap, tap } from "rxjs/operators";
import * as themeActions from "../actions/theme.actions";
import { ThemeService } from "@app/core/services/theme.service";

@Injectable()
export class ThemeEffects {

  constructor(private actions: Actions, private themeService: ThemeService) { }

  @Effect()
  themeLoad = this.actions.pipe(
    ofType<themeActions.ThemeLoad>(themeActions.ThemeActionsTypes.THEME_LOAD),
    mergeMap(action => this.themeService.getTheme(action.payload).pipe(
      map(theme => new themeActions.ThemeSuccess(theme)),
      catchError(error => of(new themeActions.ThemeFail(error)))
    ))
  )

}