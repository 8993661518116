<div class="language-switch" *ngIf="login">
  <a
    class="language-switch__option"
    [ngClass]="{'active': setActive(language)}"
    *ngFor="let language of languages"
    (click)="switchLanguage(language)">
    <img class="img-active" *ngIf="language.code == 'pt'" src="assets/images/icons/br.png" alt="">
    <img class="img-active" *ngIf="language.code == 'en'" src="assets/images/icons/en.png" alt="">
    <img class="img-active" *ngIf="language.code == 'es'" src="assets/images/icons/es.png" alt="">


    <img class="img-none" *ngIf="language.code == 'pt'" src="assets/images/icons/br-none.png" alt="">
    <img class="img-none" *ngIf="language.code == 'en'" src="assets/images/icons/en-none.png" alt="">
    <img class="img-none" *ngIf="language.code == 'es'" src="assets/images/icons/es-none.png" alt="">
  </a>
</div>

<div class="language-switch list-language" *ngIf="!login">
  <a
    class="language-switch__option block-langhage"
    [ngClass]="{'active': setActive(language)}"
    *ngFor="let language of languages"
    (click)="showLanguage = !showLanguage">

      <img class="img-active" *ngIf="language.code == 'pt'" src="assets/images/icons/br.png" alt="">
      <img class="img-active" *ngIf="language.code == 'es'" src="assets/images/icons/es.png" alt="">
      <img class="img-active" *ngIf="language.code == 'en'" src="assets/images/icons/en.png" alt="">
  </a>

  <div class="show-new-languages" [ngClass]="{'active-languages': showLanguage}">
    <a
      class="language-switch__option block-langhage"
      (click)="switchLanguage(languages[0])">
      <div class="show-language">
        <p [ngClass]="{'active-text': activeLanguage == 'pt'}">{{'language.portuguese' | translate }}</p>
        <img class="img-none" src="assets/images/icons/br.png" alt="">
      </div>

    </a>
    <a
      class="language-switch__option block-langhage"
      (click)="switchLanguage(languages[1])">
      <div class="show-language">
        <p [ngClass]="{'active-text': activeLanguage == 'es'}">{{ 'language.spanish' | translate }}</p>
        <img class="img-none" src="assets/images/icons/es.png" alt="">
      </div>

    </a>
    <a
      class="language-switch__option block-langhage"
      (click)="switchLanguage(languages[2])">
      <div class="show-language">
        <p [ngClass]="{'active-text': activeLanguage == 'en'}">{{ 'language.english' | translate }}</p>
        <img class="img-none" src="assets/images/icons/en.png" alt="">
      </div>

    </a>
  </div>
</div>
