import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/components/alert/alert.service';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { UserContextService } from '@app/core/services/user-context.service';
import { BaseView } from '@app/views/shared/BaseView';
import { Inscription } from '@app/views/user/model/conference/inscription';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { UserPunctuationService } from '@app/views/user/service/user-punctuartion.service';
import { Observable } from 'rxjs';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '@env/environment';
import { ModalService } from '@app/components/modal-container/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { InviteUsersComponent } from '../modal/invite-users/invite-users.component';
import { FileDownloadComponent } from '../modal/file-download/file-download.component';

@Component({
  selector: 'app-conference-player',
  templateUrl: './conference-player.component.html',
  styleUrls: ['./conference-player.component.sass']
})
export class ConferencePlayerComponent extends BaseView implements OnInit, OnDestroy {


  @Input() inscriptionId: number;
  @Input() conferenceId: number;
  @Input() customerId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  conference: Inscription;
  private jitsi: any;
  execution: boolean;
  controlConference: any;
  private stompClient = null;
  disabled: boolean;
  @Output() conferenceChildForm = new EventEmitter<Inscription>();

  constructor(private userContextService: UserContextService,
    private router: Router,
    private conferenceInscriptionService: ConferenceInscriptionService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private modal: ModalService,
    private translate: TranslateService,
    private userPunctuationService: UserPunctuationService,
    private credentialsService: CredentialsService) {
    super();
  }

  ngOnInit() {
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      if (this.conference) {
        this.loadData(this.conference.conferenceId)
      }
    });
  }

  public loadData(conferenceId) {
    this.user$.subscribe(user => {
      this.user = user;
      if (this.conference) {
        this.connect();
      }
    });
  }

  private initJitsi() {

    const options = {
      roomName: this.conference.name,
      width: '100%',
      height: 702,
      parentNode: document.querySelector('#meet'),
      userInfo: {
        displayName: this.user.fullName,
        id: this.user.id.toString(), // the id of the participant that changed his avatar.
        avatarURL: this.getPhoto(this.user.photo) // the new avatar URL.
      },
      configOverwrite: {
        enableWelcomePage: false,
        prejoinPageEnabled: false,
      },
      interfaceConfigOverwrite: {
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        noSsl: true,
        SHOW_JITSI_WATERMARK: false,
        HIDE_DEEP_LINKING_LOGO: true,
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_POWERED_BY: false,
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'closedcaptions', 'fullscreen', 'videoquality'
        ],
      },
    };

    options['parentNode'] = document.querySelector('#meet');

    this.jitsi = new (window as any).JitsiMeetExternalAPI('meet.jit.si', options);

  }

  ngOnDestroy() {
    this.endConference();
  }

  startJitsi() {
    if (this.jitsi) {
      this.jitsi.executeCommand('subject', '');
    }
  }

  stopJitsi() {
    if (this.jitsi) {
      this.jitsi.executeCommand('hangup');
    }
  }

  connect() {

    const socket = new SockJS(environment.endPointWebsocketConference);
    this.stompClient = Stomp.over(socket);

    const _this = this;

    this.stompClient.connect({}, function (frame) {

      _this.setConnected(true);

      _this.stompClient.subscribe('/jitsy/status', function (conference) {
        const result = JSON.parse(conference.body);
        _this.update(_this.user.customerId, _this.user.id, _this.conference.conferenceId, _this.inscriptionId, result.conferenceStatusId);
        _this.conferenceChildForm.emit(result);
      });

      _this.sendBroadcast();
    });

  }

  startConference() {
    this.initJitsi();
    this.startJitsi();
    this.execution = true;
  }

  endConference() {
    this.disconnect();
    this.stopJitsi();
  }

  update(customerId: number, userId: number, conferenceId: number, inscriptionId: number, inscriptionStatusId: number) {

    this.conferenceInscriptionService.getConferenceInscription(userId, conferenceId, inscriptionId)
      .subscribe(matriculation => {
        matriculation.inscriptionStatusId = inscriptionStatusId;
        this.conferenceInscriptionService.update(userId, conferenceId, inscriptionId, matriculation)
          .subscribe(insc => {
            if (insc.inscriptionStatusId == this.STATUS_INITIATES) {
              this.startConference();
              this.userPunctuationService.savePunctuation(userId, customerId, 8, conferenceId, 100)
                .subscribe(result => {
                  console.log('INICIOU CONFERENCIA - PONTOS');
                });
            } else if (insc.inscriptionStatusId == this.STATUS_COMPLETED) {
              this.endConference();
            }
          })
      });
  }

  disconnect() {
    if (this.stompClient != null) {

      this.sendBroadcast();

      const _this = this;
      this.stompClient.disconnect(function () {
        console.log('disconnected...');
        _this.setConnected(false);
      });
    }
  }

  sendBroadcast() {
    try{
      if (this.stompClient != null) {
        this.stompClient.send("/conference/jitsy/status", {}, JSON.stringify(this.conference));
      }
    } catch (e) {

    }   
  }

  setConnected(connected: boolean) {
    this.disabled = !connected;
    if (connected) {

    }
  }

  public openShareUsers() {
    this.modal.openParam(InviteUsersComponent, this.conference, {});
  }

  public openFilesDownload() {
    this.modal.openParam(FileDownloadComponent, this.conference, {});
  }

  public likeConference(like: boolean) {
    this.user$.subscribe(user => {
      this.conference.like = like;
      this.conferenceInscriptionService.likeConference(user.id, this.conferenceId, this.inscriptionId, this.conference)
        .subscribe(conference => {
          this.conference = conference;
          this.alertService.success("Obrigado pelo seu feedback");
        });
    });
  }

  copy() {
    this.onSucess(null, this.translate.instant('mod.copy-link'), this.alertService)
  }

  getUserLink(conference: Inscription) {
    if (conference) {
      if (!environment.development) {
        return `${this.user.subDomainName}.${environment.domainUser}/#${conference.conferenceLink}&senderId=${this.user.id}`;
      } else {
        return `${environment.domainUser}/#${conference.conferenceLink}&subdomain=${this.user.subDomainName}&senderId=${this.user.id}`;
      }
    }
  }
}
