export class UserPunctuation {

    id: number;

	userId: number;

	punctuation: number = 0;

	position: number = 0;

	patentId: number;

	patentName: string;

	patentUrlImage: string;

	customerId: number;

	engagement: number;

	moduleId:number;

	moduleItemId: number;
}
