import { SummaryComponent } from './products/purchases/sumary/summary.component';
import { OrderTrackingComponent } from './products/purchases/ order-tracking/order-tracking.component';
import { TranslateLoadersConfig } from '@app/utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AlertModule } from '@app/components/alert/alert.module';
import { ComponentsModule } from '@app/components/components.module';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualStoreRoutingModule } from './virtual-store-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertFieldModule } from '@app/components/alert-field/alert-field.module';

import { ChartsModule } from 'ng2-charts';
import { ProductListComponent } from './products/product-list/product-list.component';
import { PaymentComponent } from './products/purchases/payment/payment.component';
import { VirtualStoreComponent } from './virtual-store.component';
import { OrderComponent } from './products/purchases/order/order.component';
import { PayerComponent } from './products/purchases/payer/payer.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    VirtualStoreRoutingModule,
    TranslateModule,
    AlertFieldModule,

  ],
  declarations: [
    VirtualStoreComponent,
    ProductListComponent,
    OrderComponent,
    PayerComponent,
    OrderTrackingComponent,
    PaymentComponent,
    SummaryComponent
  ]
})
export class VirtualStoreModule { }
