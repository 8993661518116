import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as ThemeReducers from "../reducers"

export const selectCoreState = createFeatureSelector<ThemeReducers.CoreState>('core');

export const getThemeState = createSelector(
  selectCoreState,
  (state: ThemeReducers.CoreState) => state.theme
);

export const getThemeData = createSelector(
  getThemeState,
  ThemeReducers.selectTheme
);
