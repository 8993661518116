<h1 class="title-default2" [style.color]="user.mainColor">{{ 'profile.requests' | translate }}</h1>
<div class="box-default mb-20">
  <div class="columns is-multiline">
    <div class="column text-center p-0">
      <a class="text-default-card hover-itens m-0 "
      [ngClass]="{'text-active':orderStatusId == 0}"
      (click)="searchOrders(ALL_ORDERS)">
        {{ 'products-purchases.all-orders' | translate }}</a>
    </div>
    <div class="column text-center p-0">
      <a class="text-default-card hover-itens m-0"
      [ngClass]="{'text-active':orderStatusId == 1}"
      (click)="searchOrders(1)">
        {{ 'products-purchases.open-orders' | translate }}</a>
    </div>
    <div class="column text-center p-0">
      <a class="text-default-card hover-itens m-0"
      [ngClass]="{'text-active':orderStatusId == 2}"
      (click)="searchOrders(2)">
        {{ 'products-purchases.orders-delivered' | translate }}</a>
    </div>
  </div>
</div>
<div *ngIf="orders?.length == 0">
    <h1>Nenhum pedido encontrado!</h1>
</div>

<div class="box-default mt-20" *ngFor="let order of orders">
  <div class="columns is-multiline">
    <div class="column">
      <p class="text-default-card m-0"><b>{{ 'profile.request' | translate }} - </b>{{order.id}}</p>
    </div>
    <div class="column">
      <p class="text-default-card m-0">
        <b *ngIf="order.orderStatusId == 2">{{order.fmtReceivedDate}} - </b>
        <b *ngIf="order.orderStatusId != 2">{{order.fmtPaymentDate}} - </b>
        <span [ngClass]="{'text-success':order.orderStatusId != 5,'text-error':order.orderStatusId == 5  }">
          {{'products-purchases.orders-status-'+order.orderStatusId  | translate }}
        </span></p>
    </div>
    <div class="column text-right">
      <a class="link-default hover-itens " (click)="order.openDetails = !order.openDetails">{{'products-purchases.view-summary' | translate}}</a>
    </div>
    <div class="column is-12" *ngFor="let product of order.products">
      <div class="columns">
        <div class="" style="min-width: 100px;">
          <img class="img-info product-img-item" [src]="getProductImage(product.urlImage)">
        </div>
        <div class="ml-15">
          <p class="text-default-card m-0"><b>{{product.name}}</b>
          </p>
          <p class="text-default-card m-0 mt-10" style="color: #555555;"><b>{{product.quantity}} unidade(s) -
              {{product.fmtPrice}} cada</b></p>
        </div>
      </div>
      <div class="column is-12" *ngIf="order.openDetails" >
        <div class="steps-timeline">
          <div class="columns">

            <div class="column steps-one" *ngFor="let track of product.trackings; let i = index">
              <div class="box-steps-img "  [ngClass]="{'border-active':(track.active && order.orderStatusId != 5),
                                               'payment-not-approved-cicle-active' :(track.active && order.orderStatusId == 5) }">
                <img class="steps-img"
                  *ngIf="track.orderStatusId != 5"
                [src]="getTrackingImage(order, track.orderStatusId, track.active )" alt="" />
                <span *ngIf="track.orderStatusId == 5" class="payment-not-approved-icon" >X</span>
              </div>
              <div class="steps-texts">

                <h3 class="steps-name" *ngIf="track.orderStatusId == 8 && product.orderItemTypeId == 1 ">
                    {{'products-purchases.orders-status-'+track.orderStatusId+'-2'  | translate }}</h3>
                <h3 class="steps-name" *ngIf="track.orderStatusId == 8 && product.orderItemTypeId == 2 " >
                    {{'products-purchases.orders-status-'+track.orderStatusId+'-1'  | translate }}</h3>
                <h3 class="steps-name" *ngIf="track.orderStatusId != 8 " >
                    {{'products-purchases.orders-status-'+track.orderStatusId  | translate }}</h3>
                <p class="text-default-card"><b>{{track.fmrDate}}</b></p>
              </div>
              <div class="line-timeline "
              [ngClass]="{'border-active':track.active  && product.trackings[i+1].active }"
              *ngIf="order.orderStatusId != 5 && track.index < product?.trackings?.length"></div>

              <div class="line-timeline "
              [ngClass]="{'payment-not-approved-border-active':track.active  && product.trackings[i+1].active }"
              *ngIf="order.orderStatusId == 5 && track.index < product?.trackings?.length"></div>
            </div>
          </div>
        </div>

      </div>
      <div class="column is-12 text-center" *ngIf="product?.orderStatusId == 8 && product?.orderItemTypeId ==1">
        <a class="btn-default-confirmed" (click)="redirectProductModule(product)"> {{ getOrderItemName(product?.moduleId) }}</a>
      </div>
    </div>
    <div class="column is-12" *ngIf="order.openDetails" >
      <div class="column is-12 text-center">
        <a class="btn-default-confirmed"  *ngIf="order.orderStatusId != 2 && order.orderStatusId != 5 && false">{{'products-purchases.exchange-or-return' | translate}}</a>
        <a class="btn-default-confirmed"  *ngIf="order.orderStatusId == 5" (click)="redirectPayment(order)">Substituir a forma de Pagamento</a>
        <a class="btn-default-confirmed"  *ngIf="order.orderStatusId == 2" (click)="redirectPayment(order)">Continar com a compra</a>
      </div>
      <div class="column is-12 text-center" *ngIf="order.orderStatusId != 2">
        <hr class="m-0">
      </div>
      <div class="column is-12" *ngIf="order.orderStatusId != 2">
        <div class="columns desktop-columns">
          <div class="column is-4 desktop-itens border-right">
            <p class="text-default-card text-center"><b>{{'products-purchases.payment' | translate}}</b></p>
            <p class="text-default-card text-center" *ngIf="order?.paymentTypeId == 1">
              {{'products-purchases.bank-slip' | translate}}</p>
            <div class="columns center-vertical" *ngIf="order?.paymentTypeId == 1">
              <div class="column is-12">
                <img class="img-purchases" [src]="order?.invoice?.bankSlip?.barcode">
              </div>
            </div>
            <div class="columns center-vertical" *ngIf="order?.paymentTypeId == 1">
              <div class="column is-12">
                <p class="text-default-black text-left"><b>{{ 'store.copy-code' | translate }}
                </b><b>{{order?.invoice?.bankSlip?.barcodeData}}</b></p>
              </div>
            </div>
            <p class="text-default-card text-center" *ngIf="order?.paymentTypeId == 2">
              {{'products-purchases.credit-card' | translate}}</p>
            <div class="columns center-vertical" *ngIf="order?.paymentTypeId == 2">
              <div class="column is-3">
                <img class="img-purchases" [src]="getBrand(order?.invoice?.creditCard?.brand)">
              </div>
              <div class="column is-9" >
                <p class="text-default-card m-0">{{order?.invoice?.creditCard?.displayNumber}}</p>
                <p class="text-default-card m-0">{{order.fmtTotal}} em {{order?.invoice?.creditCard?.quantityPlots}}x</p>
              </div>
            </div>
          </div>
          <div class="column is-4 desktop-itens border-right">
            <p class="text-default-card text-center"><b>{{'products-purchases.total-paid' | translate}}</b></p>
            <div class="columns is-multiline">
              <div class="column pt-0 pb-0">
                <p class="text-default-card m-0">{{'products-purchases.sub-total' | translate}}</p>
              </div>
              <div class="column pt-0 pb-0 text-right">
                <p class="text-default-card m-0">{{order.fmtSubTotal}}</p>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column pt-0 pb-0">
                <p class="text-default-card m-0">{{'products-purchases.discounts' | translate}}</p>
              </div>
              <div class="column pt-0 pb-0 text-right">
                <p class="text-default-card m-0" *ngIf="order.fmtTotalDiscounted">{{order.fmtTotalDiscounted}}</p>
                <p class="text-default-card m-0" *ngIf="!order.fmtTotalDiscounted">R$ 0,00</p>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column pt-0 pb-0">
                <p class="text-default-card m-0">{{'products-purchases.freight' | translate}}</p>
              </div>
              <div class="column pt-0 pb-0 text-right">
                <p class="text-default-card m-0">R$ 0,00</p>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <hr class="m-0" style="background-color: #777777;">
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column pt-0 pb-0">
                <p class="text-default-card m-0"><b>{{'products-purchases.total' | translate}}</b></p>
              </div>
              <div class="column pt-0 pb-0 text-right">
                <p class="text-default-card m-0"><b>{{order.fmtTotal}}</b></p>
              </div>
            </div>
          </div>
          <div class="column is-4 desktop-itens">
            <p class="text-default-card text-center"><b>{{'products-purchases.resume' | translate}}</b></p>
            <p class="text-default-card m-0" [style.color]="user.mainColor">{{'products-purchases.email' | translate}}:
            </p>
            <p class="text-default-card">{{order.payer.email}}</p>
            <p class="text-default-card m-0" [style.color]="user.mainColor">
              {{'products-purchases.you-will-be-welcomed-by' | translate}}:</p>
            <p class="text-default-card">{{order.delivery.receivedBy}}</p>
            <p class="text-default-card m-0" [style.color]="user.mainColor">{{'products-purchases.sent-to' |
              translate}}:</p>
            <p class="text-default-card">{{order.delivery.address.street}},
              <span *ngIf="order.delivery.address.complement">{{order.delivery.address.complement}}, </span>
              {{order.delivery.address.countryName}}, {{order.delivery.address.cityName}}
              {{order.delivery.address.zipCode | mask:'0000-000'}}
            </p>

          </div>
        </div>
      </div>
      <div class="column is-12 mt-20" *ngIf="false">
        <p class="text-default-card text-center">{{'products-purchases.order-details' | translate}} - <span
            class="hover-itens" style="color: #1CB0F6">{{'general.back' | translate}}</span></p>
      </div>
    </div>
  </div>
</div>
