import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrainingRoutingModule } from './training-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '@app/components/alert/alert.module';
import { ComponentsModule } from '@app/components/components.module';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { ChartsModule } from 'ng2-charts';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TrainingHomeComponent } from './training-home/training-home.component';
import { TranslateLoadersConfig } from '@app/utils';
import { TrainingListComponent } from './traininig-list/training-list.component';
import { CertifiedComponent } from './certified/certified.component';
import { AlertFieldModule } from './../../../../components/alert-field/alert-field.module';
import { TrainingToolComponent } from './training-tool/training-tool.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TrainingListComponent,
    TrainingToolComponent,
    TrainingHomeComponent,
    CertifiedComponent
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    TrainingRoutingModule,
    TranslateModule,
    AlertFieldModule
  ]
})
export class TrainingModule { }
