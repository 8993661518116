<div class="column is-12">
  <img class="icon-time" src="../../../../assets/images/login/icon-time.png">
</div>

<div class="column is-12">
  <h1 class="title">
    Só um instante
  </h1>
</div>

<div class="column is-12">
  <p class="text">
    Estamos preparando seu ambiente.
  </p>
</div>

<div class="column is-12 container-loader">
  <div class="loader">
    <span style="--i:1;"></span>
    <span style="--i:2;"></span>
    <span style="--i:3;"></span>
    <span style="--i:4;"></span>
    <span style="--i:5;"></span>
    <span style="--i:6;"></span>
    <span style="--i:7;"></span>
    <span style="--i:8;"></span>
    <span style="--i:9;"></span>
    <span style="--i:10;"></span>
    <span style="--i:11;"></span>
    <span style="--i:12;"></span>
    <span style="--i:13;"></span>
    <span style="--i:14;"></span>
    <span style="--i:15;"></span>
    <span style="--i:16;"></span>
    <span style="--i:17;"></span>
    <span style="--i:18;"></span>
    <span style="--i:19;"></span>
    <span style="--i:20;"></span>
    <div class="rocket"></div>

  </div>
</div>
