<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default" [style.color]="user.mainColor">{{ 'assessment.title-assessment' | translate }}</h1>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control has-icons-left has-icons-right">
            <input class="input input-search" type="text" placeholder="{{ 'placeholder.general' | translate }}"
              [(ngModel)]="searchTerms">
            <span class="icon is-small is-right show-icon-search" style="height: 3.7em">
              <img src="/assets/images/icons/lupa.png" (click)="loadItens(STATUS_ALL)">
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <a class="text-menu-filter" (click)="loadItens(STATUS_ALL)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_ALL}">{{ 'assessment.tab-all' | translate }}</a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_NOT_INITIATED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_NOT_INITIATED}">{{ 'assessment.tab-not-started' |
          translate }}</a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_INITIATES)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_INITIATES}">{{ 'assessment.tab-started' | translate
          }}</a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_COMPLETED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_COMPLETED}">{{ 'assessment.tab-concluded' |
          translate }}</a>
      </div>
      <div class="column is-12 mt-30">
        <div class="columns is-multiline">
          <div class="cards">
            <div class="card" *ngFor="let assessment of assessments">
              <picture class="card__picture">
                <img class="card__imagem" [src]="getImage(assessment.urlImage)">
              </picture>
              <div class="card__info">
                <p *ngIf="assessment.fmtFinishDate" class="card__date">{{ assessment.fmtFinishDate }}</p>
                <h2 class="card__title">
                  {{assessment.assessmentName}}
                </h2>
                <p class="card__description" [innerHTML]="limitDescription(assessment.description, 150) | safeHtml">
                </p>
                <p class="card__description" *ngIf="!assessment.description">{{'assessment.not-description' | translate
                  }}
                </p>
              </div>
              <div class="card__buttons">
                <a class="card__button" *ngIf="assessment.assessmentStatusId != 3"
                  (click)="redirect(assessment.assessmentId,assessment.matriculationId)">{{'assessment.realize'
                  | translate }}</a>
                <a class="card__button" *ngIf="assessment.assessmentStatusId == 3 && assessment.reportAccessWhenFinished"
                  (click)="redirectToReport(assessment.assessmentId,assessment.matriculationId)">
                  {{ 'assessment.concluded' | translate }}</a>
                <div *ngIf="assessment.assessmentStatusId == 3 && !assessment.reportAccessWhenFinished" class="card__button--message">
                  <h1>Relatório disponível com o consultor</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <p class="info-default-list" *ngIf="!assessments || assessments?.length == 0">
          {{ 'assessment.none-assessment' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>