import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerPreference } from '../model/customer-preference';

const API_URL = `${environment.baseUrl}/v1/users/customers`;
@Injectable({
  providedIn: 'root'
})
export class CustomerPreferenceService {

  private customerPreferenceSubject = new BehaviorSubject<CustomerPreference>(null);

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  public getCustomerPreferenceBySubdomainName(subdomainName: string, areaRedirect: string, refValue: number, invite: number, senderId: number, publisher: boolean) {
    return this.http.get<CustomerPreference>(`${API_URL}/groups/${encodeURIComponent(subdomainName)}`)
      .pipe(tap((customerPreference: CustomerPreference) => {
        if (customerPreference) {
          customerPreference.invite = invite;
          customerPreference.redirect = areaRedirect;
          customerPreference.refValue = refValue;
          customerPreference.senderId = senderId;
          customerPreference.publisher = publisher;
          this.localStorageService.setLocalStorage("preference", customerPreference)
          this.customerPreferenceSubject.next(customerPreference);
        }
      }
      ));
  }

  public deleteCustomerPreference(){
    this.localStorageService.removeLocalStorage('preference');
  }

  public getCustomerPreference(customerId: number, groupId: number, areaRedirect: string, refValue: number) {
    return this.http.get<CustomerPreference>(`${API_URL}/${customerId}/groups/${groupId}`)
      .pipe(tap((customerPreference: CustomerPreference) => {
        customerPreference.redirect = areaRedirect;
        customerPreference.refValue = refValue;
        this.localStorageService.setLocalStorage("preference", customerPreference)
        this.customerPreferenceSubject.next(customerPreference);
      }
      ));
  }

  public getPreference() {
    return this.customerPreferenceSubject.asObservable();
  }

}
