<section *ngIf="matriculation" class="section" [ngClass]="{'section-padding': !themeService?.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default">Certificado</h1>
      </div>
      <div class="column center-vertical" style="justify-content: flex-end;">
        <a class="btn-certified" (click)="generetePdf()">Download</a>
      </div> 
      <div class="column is-12">     
        <div class="box-certified" id="element-to-print"  [ngClass]="{'disabled-item': pdfCreated }">
          <hr class="hr-top-1"  [style.background-color]="user.mainColor">
          <hr class="hr-bottom-1" [style.background-color]="user.mainColor">
          <div class="box-itens-certified">
            <div class="columns is-multiline">
              <div class="column is-12 mt-20">
                <img class="img-certified" :src="{{getLogo(user.logo)}}" *ngIf="isDevelopment">  
                <img class="img-certified" :src="{{getLogo(user.logo)}}" *ngIf="!isDevelopment" crossOrigin="anonymous">               
              </div>
              <div class="column is-12 mt-10">
                <p class="text-certified"><b>Certificamos que</b></p>
              </div>
              <div class="column is-12">
                <h1 class="name-user-certified">{{user?.fullName}}</h1>
              </div>
              <div class="column is-12">
                <p class="text-certified">Finalizou o curso online </p>
              </div>
              <div class="column is-12">
                <h2 class="name-course-certified" [innerHtml]="matriculation?.name | safeHtml"></h2>
              </div>
              <div class="column is-12">
                <p class="text-certified">Com carga horária estimada em {{matriculation.workload}} h(s).</p>
                <p class="text-certified">Iniciado em {{matriculation?.startDateFormat}} e concluído em {{matriculation?.endDateFormat}}.</p>
                <p class="text-certified" *ngIf="false">{{matriculation?.totalActivities}} de {{matriculation?.concludedActivities}} atividades concluídas.</p>
              </div>
              <div class="column is-12">
                <hr class="mt-60 mb-20">
              </div>
              <div class="column is-12 mt-20">
                <div class="columns is-multiline">
                  <div class="column">
                    <hr />
                    <p class="text-certified" style="font-size: 14px;">Aluno</p>
                  </div>
                  <div class="column">
                    <p class="text-link-certified" *ngIf="false">http://ingames.com.br/certificado/65s5e-8safvt-asa5e-ass5a.</p>
                  </div>
                  <div class="column">
                    <hr />
                    <p class="text-certified" style="font-size: 14px;">Coordenador</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
