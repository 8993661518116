import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { BaseView } from '@app/views/shared/BaseView';
import { ChatMessage } from '@app/views/user/model/conference/chat-message';
import { Inscription } from '@app/views/user/model/conference/inscription';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { Observable } from 'rxjs';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '@env/environment';
@Component({
  selector: 'app-conference-chat',
  templateUrl: './conference-chat.component.html',
  styleUrls: ['./conference-chat.component.sass']
})
export class ConferenceChatComponent extends BaseView implements OnInit, OnDestroy {

  user$: Observable<Credentials>;
  user: Credentials;
  @Input() conference: Inscription;
  chatMessage = new ChatMessage();
  chats: Array<ChatMessage>;
  chats$: Observable<ChatMessage[]>;
  controlChat: any;
  submit: boolean;
  private stompClient = null;
  disabled: boolean;
  public chatForm: FormGroup;
  submitChat = false;
  constructor(private conferenceInscriptionService: ConferenceInscriptionService,
    private form: FormBuilder,
    private credentialsService: CredentialsService) {
    super();
  }

  ngOnInit() {

  }

  clearField() {
    this.submitChat = false;
  }

  public loadChat(customerId, inscriptionId, conferenceId) {
    this.credentialsService.getUserCredentials().subscribe(user => {
      this.user = user;

      this.conferenceInscriptionService.getChats(customerId, conferenceId, inscriptionId)
        .subscribe(chats => {
          this.chats = chats;
          if (chats) {
            setTimeout(() => this.addIndexItens(chats.length - 2), 2000);
          }
        })
    });

  }

  ngOnDestroy() {
    this.disconnect()
  }

  connect() {

    const socket = new SockJS(environment.endPointWebsocketConference);
    this.stompClient = Stomp.over(socket);

    const _this = this;

    this.stompClient.connect({}, function (frame) {

      _this.setConnected(true);

      _this.stompClient.subscribe('/chat/messages', function (message) {
        const result = JSON.parse(message.body);
        _this.chats.push(result);
        _this.submitChat = false;
        setTimeout(() => _this.addIndexItens(_this.chats.length - 2), 600);
      });

      _this.sendConnection('Entrou na conferência.');
    });

  }

  public sendMessage() {

    this.submitChat = true;

    if (!this.chatMessage.message) {
      return;
    }
    this.sendBroadcast(this.chatMessage);
  }

  sendConnection(message) {

    this.chatMessage.message = message;
    this.sendBroadcast(this.chatMessage);
  }

  sendBroadcast(chatMessage: ChatMessage) {

    chatMessage.customerId = this.user.customerId;
    chatMessage.consultantId = this.user.id;
    chatMessage.groupId = this.user.groupId;
    chatMessage.photo = this.user.photo;
    chatMessage.name = this.user.fullName;
    chatMessage.conferenceId = this.conference.conferenceId;
    chatMessage.nameInitials = this.user.nameInitials;
    chatMessage.isConsultant = true;
    this.stompClient.send("/conference/chat/message", {}, JSON.stringify(chatMessage));
    this.chatMessage.message = null;
  }

  disconnect() {
    if (this.stompClient != null) {

      this.sendConnection('Saiu da conferência ');
      const _this = this;
      this.stompClient.disconnect(function () {
        console.log('disconnected...');
        _this.setConnected(false);
      });
    }
  }

  setConnected(connected: boolean) {

    this.disabled = !connected;

    if (connected) {

    }
  }

  addIndexItens(index) {
    if (document.getElementById(index)) {
      document.getElementById(index).scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }



}
