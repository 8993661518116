import { Component, Input, OnInit } from '@angular/core';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { BaseView } from '@app/views/shared/BaseView';
import { ConferenceFile } from '@app/views/user/model/conference/conference-file';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { Observable } from 'rxjs';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-conference-files',
  templateUrl: './conference-files.component.html',
  styleUrls: ['./conference-files.component.sass']
})
export class ConferenceFilesComponent extends BaseView implements OnInit {
  
  @Input() inscriptionId: number;
  @Input() conferenceId: number;
  @Input() customerId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  files$: Observable<ConferenceFile[]>;

  constructor(private conferenceInscriptionService: ConferenceInscriptionService,
    private credentialsService: CredentialsService) { 
      super();
    }

  ngOnInit() {

    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      this.files$ = this.conferenceInscriptionService.getFiles(this.user.id, this.conferenceId, this.inscriptionId);    
    });
  }

}
