import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public hasLocal(key: string) {
    if (this.getLocalStorage(key)) { return true; }
    return false;
  }

  public setLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getLocalStorage(key: string): string {
    return localStorage.getItem(key);
  }

  public getLocalStorageObject(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public hasLocalStorage(key: string): boolean {
    return localStorage.hasOwnProperty(key);
  }

}
