import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CredentialsService } from '../services/credentials.service';
import { TokenService } from '../services/token.service';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService,
    private credentialsService: CredentialsService,
    private router: Router,
    private translate: TranslateService,
    private alertService: AlertService,
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let changedRequest = req;
    const headerSettings: { [name: string]: string | string[]; } = {};
    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }
    const token = this.tokenService.getToken();
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
    }

    //headerSettings['Content-Type'] = 'application/json;charset=utf-8';

    const newHeader = new HttpHeaders(headerSettings);
    changedRequest = req.clone({
      headers: newHeader,
      withCredentials: true
    });

    return next.handle(changedRequest).pipe(
      tap(
        event => {          ;

        },
        error => {
          this.handleErrors(error);
        }
      ),
      finalize(() => { })
    );
  }

  private handleErrors(err: HttpErrorResponse) {
    let errorMessage: string;
    if (err.status === 0) {
      errorMessage = this.translate.instant('login.not-connected');
      this.credentialsService.resetCredentials();
      this.router.navigate(['']);
    } else if (err.status === 401 || err.status === 403) { // O token expirou ou nem foi enviado. Fazer novamente autenticação.
      this.credentialsService.resetCredentials();
      this.router.navigate(['']);
    } else { // ocorreu outro erro. Mostrar mensagem ao utilizador
      if (err.error)

        errorMessage = err.error.message;
      else
        errorMessage = err.message;
    }

    if (errorMessage) {
      this.alertService.warning(errorMessage);
    }
  }
}


