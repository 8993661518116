<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-11">
        <div class="columns is-multiline">
          <!-- BEM VINDO -->
          <div class="column is-12" *ngIf="highlights.length  == 0">
            <div class="welcome">
              <div>
                <h1 class="title-welcome">{{ 'dashboard.wellcome' | translate }}</h1>
                <p class="text-welcome">{{ 'dashboard.platform' | translate }} <b>Longevprev</b></p>
              </div>
            </div>
          </div>
          <!-- CAROUSEL -->
          <div class="column is-12" *ngIf="highlights.length > 0">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators ">
                <li data-target="#carouselExampleIndicators"
                    data-slide-to="{{i}}"
                    [ngClass]="{'active': activeItem == i}"
                    (click)="accessItem(i)"
                    *ngFor="let highlight of highlights; let i = index;"></li>

              </ol>
              <div class="carousel-inner">
                <div class="carousel-item "  [ngClass]="{'active': activeItem == i}"  *ngFor="let highlight of highlights; let i = index;">
                    <div class="column is-12 p-0 overflow">
                      <div class="card-concluded p-20">
                        <div class="columns is-multiline center-vertical">
                          <div class="column text-center">
                            <img class="center-margin-auto"
                              src="/assets/images/diversas/atividades.png">
                          </div>
                          <div class="column is-9">
                            <div class="ml-15" style="padding: 10px;">
                              <h1 class="title-card-result" *ngIf="highlight.typeId == 1">{{ 'dashboard.assessment-recomendation' | translate }} </h1>
                              <h1 class="title-card-result" *ngIf="highlight.typeId == 2">{{ 'dashboard.trail-recomendation' | translate }} </h1>
                              <p class="text-card-result"*ngIf="highlight.typeId == 1">{{ 'dashboard.assessment-recomendation-received' | translate }}</p>
                              <p class="text-card-result"*ngIf="highlight.typeId == 2">{{ 'dashboard.trail-recomendation-received' | translate }} <b>{{highlight.name}}</b></p>
                            </div>
                          </div>
                          <div class="column is-12 mb-50 text-center">
                            <a class="btn-default-confirmed" (click)="redirect(highlight.id, highlight.matriculationId,highlight.typeId)">
                              <span *ngIf="highlight.typeId == 1">{{ 'dashboard.realize-assessment' | translate }}</span>
                              <span *ngIf="highlight.typeId == 2">{{ 'dashboard.realize-trail' | translate }}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- GRÁFICO -->
          <div class="column is-12" *ngIf="(userAssessmentReport$ | async) as userAssessmentReport">
            <div class="card-concluded p-20">
              <div class="columns is-multiline">
                <div class="column is-7 center-margin-auto">
                  <div class="border-chart " *ngIf="chartReady">
                    <canvas style="position: relative; height:100vh; width:100vw" baseChart [datasets]="radarChartData" [labels]="radarChartLabels"
                      [colors]="radarChartOptionsColors"
                      [options]="radarChartOptions" 
                      [chartType]="radarChartType">
                    </canvas>
                  </div>
                </div>
                <div class="column is-12 mt-5">
                  <h1 class="text-center title-card-result" style="font-size: 20px;">{{userAssessmentReport.assessmentName}}</h1>
                  <div class="columns">
                    <div class="column is-6">
                      <div class="border-chart"  *ngIf="chartReady" >
                        <canvas baseChart [datasets]="weakerLineChartData" [labels]="weakerLineChartLabels"
                          [options]="weakerLineChartOptions" [colors]="weakerLineChartColors"
                          [legend]="weakerLineChartLegend" [chartType]="weakerLineChartType"
                          [plugins]="weakerLineChartPlugins">
                        </canvas>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="border-chart"  *ngIf="chartReady">
                        <canvas baseChart [datasets]="strongerLineChartData" [labels]="strongerLineChartLabels"
                          [options]="strongerLineChartOptions" [colors]="strongerLineChartColors"
                          [legend]="strongerLineChartLegend" [chartType]="strongerLineChartType"
                          [plugins]="strongerLineChartPlugins">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-right">
        <div class="columns is-multiline">
          <!--  ATIVIDADES -->
          <div class="column is-12">
            <div class="box-card-img" [ngClass]="{'none-box-card': showActivities}">
              <img class="img-info" src="/assets/images/icons/alert2.png" (click)="showActivities = !showActivities">

              <div class="box-card" *ngIf="!showActivities && !userPending.pendingActivities">
                <p class="title-default-card">{{ 'dashboard.pending-activities' | translate }}</p>
                <p class="text-default-card">{{ 'dashboard.no-pending' | translate }}</p>
                <img src="/assets/images/diversas/atividades.png">
              </div>

              <div class="box-card" *ngIf="!showActivities && userPending.pendingActivities">
                <p class="title-default-card">{{ 'dashboard.pending-activities' | translate }}</p>
                <div class="columns is-multiline mt-20">
                  <div class="column is-12">
                    <div class="box-activities">
                      <img src="/assets/images/icons/assessment-01.svg">
                      <p class="name-activities">{{ 'assessment.assessment' | translate }}</p>
                      <p class="score-activities">{{userPending.countPendingAssessments}}</p>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="box-activities">
                      <img src="/assets/images/icons/trilha.svg">
                      <p class="name-activities">{{ 'trail.trails' | translate }}</p>
                      <p class="score-activities">{{userPending.countPendingTrails}}</p>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="box-activities">
                      <img src="/assets/images/icons/treinamento.svg">
                      <p class="name-activities">{{ 'training.trainings' | translate }}</p>
                      <p class="score-activities">{{userPending.countPendingTraininigs}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SEM HISTÓRICO -->
          <div class="column is-12" *ngIf="!historicAssessments || historicAssessments?.length == 0">
            <div class="box-card-img" [ngClass]="{'none-box-card': showHistoric}">
              <img class="img-info" src="/assets/images/icons/historico.png" (click)="showHistoric = !showHistoric">
              <div class="box-card" *ngIf="!showHistoric">
                <p class="title-default-card">{{ 'dashboard.pending-activities' | translate }}</p>
                <p class="text-default-card">{{ 'dashboard.no-pending' | translate }}</p>
                <img src="/assets/images/diversas/historico.png">
              </div>
            </div>
          </div>
          <!-- COM HISTORICO -->
          <div class="column is-12" *ngIf="historicAssessments?.length > 0">
            <div class="box-card-img" [ngClass]="{'none-box-card': showHistoric}">
              <img class="img-info" src="/assets/images/icons/historico.png" (click)="viewHistoric(historicAssessments.length)">
              <i class="fas fa-exclamation-circle icon-alert" *ngIf="showIconHistories"></i>
              <div class="card-timeline-dashboard box-card" *ngIf="!showHistoric"
                style="border: 0px solid; box-shadow: none;">
                <p class="title-default-card">{{ 'assessment.history' | translate }}</p>
                <div aria-orientation="vertical" style="overflow: auto; max-height: 420px; padding-left: 8px;">
                    <ul class="mt-20">
                      <li *ngFor="let assessment of historicAssessments; let i = index;">
                        <strong>
                          <img class="img-icons"
                              style="cursor: pointer;"
                            (click)="loadGraphic(assessment)"
                            src="/assets/images/icons/historico.png"></strong>
                        <div class="bar-time-line" *ngIf="historicAssessments?.length > 1 && historicAssessments?.length < i"></div>
                        <p>{{assessment.assessmentName}}</p>
                        <p>{{assessment.fmtFinishDate}}</p>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
