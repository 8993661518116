import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

const API_URL = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})

export class FileService {

  constructor(private httpClient: HttpClient) { }

  uploadFile(folder: string, file: File): Observable<any> {
    const data: FormData = new FormData();
    data.append('folder', folder);
    data.append('file', file);

    return this.httpClient.post<any>(API_URL + '/v1/files/upload', data,
      {
        observe: 'events',
        reportProgress: true,

      });
  }

  uploadFileAssessment(folder: string, file: File, id: number, matriculationId: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('folder', folder);
    data.append('file', file);

    const url = API_URL + '/v1/files/uploadAssessment/users/' + id + '/assessments/' + matriculationId;
    return this.httpClient.post<any>(url, data,
      {
        observe: 'events',
        reportProgress: true,

      });
  }
}
