import { Guest } from "./guest";

export class Invite {
    conferenceId: number;
    message: string;
    conferenceName: string;
    subDomain: string;
    conferenceLink: string;
    invitedBy: string;
    frmtDate: string;    
	startTime: string;
	endTime: string;
    free:boolean;
    userId: number;
    frmtStartHour: string;
    frmtEndHour: string;
    guests: Array<Guest>;
}
