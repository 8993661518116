import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ThemeService } from '@app/core/services/theme.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CustomerPreference } from '../account/model/customer-preference';
import { CustomerPreferenceService } from '../account/service/customer-preference.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  customerPreference: CustomerPreference;
  public showBlocked = false;
  public showLoading = false;
  public showNotFound = false;
  public showCustomerBlocked = false;
  public color :string = '#41466e' ; 
  customerPreference$: Observable<CustomerPreference>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private customerPreferenceService: CustomerPreferenceService,
  ) { }
 
  ngOnInit() {

    let subdomain = "";
    let areaRedirect = "";
    let refValue = 0;
    let invite = 0;
    let senderId = 0;
    let publisher = false;
    const hostname = window.location.hostname;

    this.resetFlow();
   
    this.customerPreferenceService.getPreference()
      .subscribe(preference => {
        this.customerPreference = preference;      
     
        this.activatedRoute.queryParamMap.subscribe(queryParams => {

          subdomain = hostname.split('.')[0];
          if (environment.development) {
            subdomain = queryParams.get("subdomain");
            if (!subdomain) {
              subdomain = "eneagramacognitivo"
            }
          }

          this.showFlowItem('loading');
     
          if (queryParams.get("redirect-assessment")) {
            refValue = parseInt(queryParams.get("redirect-assessment"));
            areaRedirect = "redirect-assessment";
            this.customerPreference = null;
            this.customerPreferenceService.deleteCustomerPreference();
          } else if (queryParams.get("redirect-training")) {
            refValue = parseInt(queryParams.get("redirect-training"));
            if(queryParams.get("publisher")){
              publisher = Boolean(queryParams.get("publisher"));
            }
            areaRedirect = "redirect-training";
            this.customerPreferenceService.deleteCustomerPreference();
            this.customerPreference = null;
          } else if (queryParams.get("redirect-conference")) {
    
            if(queryParams.get("senderId")){
              senderId = parseInt(queryParams.get("senderId"));
            }

            if (queryParams.get("invite")) {
              invite = parseInt(queryParams.get("invite"));
            }

            if (queryParams.get("redirect-conference")) {
              refValue = parseInt(queryParams.get("redirect-conference"));
            }
            areaRedirect = "redirect-conference";
            this.customerPreference = null;
            this.customerPreferenceService.deleteCustomerPreference();
          } else if (queryParams.get("redirect-product")) {
            refValue = parseInt(queryParams.get("redirect-product"));
            areaRedirect = "redirect-product";
            this.customerPreference = null;
            this.customerPreferenceService.deleteCustomerPreference();
          }    
          if (subdomain) {
            setTimeout(() => {
              this.loadPreferences(subdomain, areaRedirect, refValue, invite, senderId ,publisher)
            }, 1000);
          } else {
            this.showFlowItem('not-found');
          }
        });
      });
  }

  resetFlow() {
    this.showLoading = false;
    this.showBlocked = false;
    this.showNotFound = false;
    this.showCustomerBlocked = false;
  }

  showFlowItem(name) {
    this.showBlocked = true;
    this.color = '#fff';
    this.showLoading = (name == 'loading' ? true : false);
    this.showNotFound = (name == 'not-found' ? true : false);
    this.showCustomerBlocked = (name == 'customer-blocked' ? true : false);
  }

  setColorOrDefault(color) {
    if(color){
      this.color = color;
    }else{
      this.color = '#41466e';
    }   
  }
  
  loadPreferences(subdomain, areaRedirect, refValue, invite, senderId, publisher) {

    if (!this.customerPreference) {
     
      this.customerPreferenceService.getCustomerPreferenceBySubdomainName(subdomain, areaRedirect, refValue, invite, senderId, publisher)
        .subscribe(preference => {
          this.showLoading = false;
          this.customerPreference = preference;
          if (preference) {
            this.setColorOrDefault(preference.color); 
            if (preference.customerBlocked) {
              //this.customerPreference = null;
              this.showFlowItem('customer-blocked');
            }
          } else {
            this.showFlowItem('not-found');
          }
        }, error => {
          console.log(error);
        });
    } else {
      this.showLoading = false;
      if (this.customerPreference) {
        this.setColorOrDefault(this.customerPreference.color); 
        if (this.customerPreference.customerBlocked) {
          this.customerPreference = null;
          this.showFlowItem('customer-blocked');
        }
      } else {
        this.showFlowItem('not-found');
      }
    }
  }
}
