<div class="card-default-big p-20 card-message">
  <h1 class="title-default text-left" style="font-size: 18px; color: #333333; font-weight: normal">{{
    'conference.chat' | translate }}</h1>

  <div *ngIf="chats && chats.length > 0" class="itens-message">
    <div class="columns is-multiline" *ngFor="let chat of chats; let i = index">
      <p id="{{i}}">
        <span class="photo-name-chat" *ngIf="!chat.photo">{{chat.nameInitials}}</span>
        <img class="" *ngIf="chat.photo" class='img-chat' src="{{getPhoto(chat.photo)}}">
        <span class="name-chat">{{chat.name}}</span>: <span class='mensagem-chat'>{{chat.message}}</span>
      </p>
    </div>
  </div>
  <div *ngIf="!chats || chats.length === 0" class="column mt-10 is-12 center-vertical">
    <p>{{ 'conference.nobody' | translate }}</p>
  </div>

  <div class="columns is-multiline card-message-btn" >
    <div class="column is-9">
      <textarea class="input" (focus)="clearField()" [(ngModel)]="chatMessage.message"
        [ngClass]="{'input-error': !chatMessage.message && submitChat}"  maxlength="200"></textarea>
    </div>
    <div class="column is-3">
      <a class="btn-to-share" (click)="sendMessage()"
        style="display: block; text-align: center;">{{ 'conference.send' | translate
        }}</a> 
    </div>
  </div>

</div>