export const environment = {
  domainConsultant:'https://admin.mcstecnologia.com',
  domainUser:'https://user.mcstecnologia.com',
  development : false,
  baseUrl: 'https://skills-user-api.mirevo.com.br',
  bucketUri:'https://mcs-ingames.s3.amazonaws.com/',
  IUGU_AccountID: 'D09F2840142B0E4FB2A71C6159BC6650',
  tinyMCEKey: 'n83tie11jj5cienk156c3huruhakhzc17y8rh5ua23oyiegw',
  endPointWebsocketConference:'https://skills-websockets.mirevo.com.br/conference'
}
