import { Injectable } from '@angular/core';
import { BehaviorSubject, pipe, Observable } from 'rxjs';
import { Credentials } from '../models/credentials';
import * as jwt_decode from 'jwt-decode';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CredentialsService {


  private credentialsSubject = new BehaviorSubject<Credentials>(null);

  constructor(
    private tokenService: TokenService,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) {
    this.tokenService.hasToken() && this.decodeAndNotify();
  }

  getUserCredentials() {
     return  this.credentialsSubject.asObservable();
  }

  decodeAndNotify() {
    const token = this.tokenService.getToken();
    const decode = jwt_decode(token);
    const credentials = JSON.parse(decode.sub) as Credentials;
    this.credentialsSubject.next(credentials);
  }

  setToken(token: string) {
    this.tokenService.setToken(token);
    this.decodeAndNotify();
  }

  resetCredentials(){
    this.logout();
  }

  logout() {
    this.localStorageService.removeLocalStorage('preference');
    this.tokenService.removeToken();
    this.credentialsSubject.next(null);
  }

  isLogged() {
    const logged = this.tokenService.hasToken();
    return logged;
  }

  update(credentials: Credentials) {
    this.credentialsSubject.next(credentials);
  }
}
