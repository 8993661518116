<section class="section" *ngIf="(user$ | async) as user" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12 pb-0">
        <h1 class="title-default mb-50" [style.color]="user.mainColor">{{ 'store.payment' | translate }}</h1>
      </div>
      <div class="column is-8">
        <form #form='ngForm' target="_blank" id="payment-form" [formGroup]="paymentForm">
          <div class="box-default p-box">
            <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{ 'store.payment-method' | translate }}</h1>
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="control">
                  <div>
                    <label class="radio text-left text-default-black mb-10">
                      <input type="radio" [value]="1" name="paymentTypeId" formControlName="paymentTypeId">
                      <span [style.color]="user.mainColor"></span>
                      <p>{{ 'store.bank-slip' | translate }} - <b> {{ 'store.value-advice2' | translate }}</b></p>
                    </label>
                  </div>
                  <div class="is-inactive">
                    <label class="radio text-left text-default-black mb-10">
                      <input type="radio" [value]="2" name="paymentTypeId" formControlName="paymentTypeId">
                      <span></span>
                      <p>{{ 'store.credit-debit' | translate }} - <b> {{ 'store.payment-advice' | translate }}</b></p>
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="paymentForm.get('paymentTypeId').value" class="column is-12">
                <div class="column is-12" *ngIf="paymentForm.get('paymentTypeId').value == 1">
                  <div class="box-alert-payment">
                    <p class="text-aviso mb-10 text-center">{{ 'store.conclude' | translate }}</p>
                    <p class="text-aviso m-0">- {{ 'store.generate-bank-slip' | translate }}</p>
                    <p class="text-aviso m-0">- {{ 'store.pay' | translate }}</p>
                    <p class="text-aviso m-0">- {{ 'store.wait-payment' | translate }}</p>
                    <p class="text-avisom m-0">- {{ 'store.track-product-status' | translate }}</p>
                  </div>
                </div>
                <div class="column is-12" *ngIf="paymentForm.get('paymentTypeId').value == 2">
                  <div class="box-alert-payment">
                    <p class="text-aviso mb-10 text-center">{{ 'store.conclude' | translate }}</p>
                    <p class="text-aviso m-0">- Preencha os dados do cartão e clique em Finalizar compra.</p>
                    <p class="text-aviso m-0">- Aguarde a validação do pagamento.</p>
                    <p class="text-aviso m-0">- Após a validação e confirmação.</p>
                    <p class="text-aviso m-0">- Em instantes o produto será liberado.</p>
                  </div>
                </div>
                <div class="column is-12 payment-data">
                  <h1 class="title-default2 m-0" [style.color]="user.mainColor">
                    {{ 'store.purchase-information' | translate }}
                  </h1>
                </div>
                <div class="column is-6">
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.fullname' | translate }}
                  </p>
                  <p class="text-default-black m-0">{{order?.payer?.fullname}}</p>
                </div>
                <div class="column is-6 center-vertical" style="justify-content: flex-end">
                  <a class="link-default" style="font-size: 16px;" (click)="redirectPayer()">{{ 'store.change' |
                    translate }}</a>
                </div>
                <div class="column is-12" *ngIf="false">
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.you-be-received-by' |
                    translate }}</p>
                  <p class="text-default-black m-0">{{order?.delivery?.receivedBy}}</p>
                </div>
                <div class="column is-12" *ngIf="false">
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.send-to' | translate }}</p>
                  <p class="text-default-black m-0">{{order?.delivery?.address.street}},
                    {{order?.delivery?.address.number}}
                    <span *ngIf="order?.deliveryComplement">, {{order?.delivery?.address.complement}} </span>
                  </p>
                  <p class="text-default-black m-0">{{order?.delivery?.address.district}} -
                    {{order?.delivery?.address.zipcode | mask:'00000-000' }} </p>
                  <p class="text-default-black m-0">{{order?.delivery?.address.countryName}},
                    {{order?.delivery?.address.stateName}} -
                    {{order?.delivery?.address.cityName}}
                  </p>
                </div>
                <div class="column is-12" >
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.send-to' | translate }}</p>
                  <p class="text-default-black m-0">{{order?.payer?.billingAddress.street}},
                    {{order?.payer?.billingAddress.number}}
                    <span *ngIf="order?.payer?.billingAddress.complement">, {{order?.payer?.billingAddress.complement}} </span>
                  </p>
                  <p class="text-default-black m-0">{{order?.payer?.billingAddress.district}} -
                    {{order?.payer?.billingAddress.zipcode | mask:'00000-000' }} </p>
                  <p class="text-default-black m-0">{{order?.payer?.billingAddress.countryName}},
                    {{order?.payer?.billingAddress.stateName}} -
                    {{order?.payer?.billingAddress.cityName}}
                  </p>
                </div>
              </div>
              <!-- CARTÃO -->
              <div *ngIf="paymentForm.get('paymentTypeId').value == 2" class="column is-12">
                <div class="column is-12 mt-50 text-center mb-50">
                  <div class="payment-cartao center-margin-auto">
                    <div class="columns is-multiline">
                      <div class="column">
                        <div class="chip">
                          <img src="/assets/images/icons/chip.png" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="bandeira">
                          <img *ngIf="urlImageBrand" [src]="urlImageBrand" />
                        </div>
                      </div>
                      <div class="column is-12 mt-20 credit-card-mobile">
                        <p class="text-default-black" style="font-size: 24px;"
                          *ngIf="paymentForm.get('creditCardNumber').value">{{paymentForm.get('creditCardNumber').value
                          | mask:"0000 0000 0000 0000"}}</p>
                        <p class="text-default-black credit-card-numbers" style="font-size: 24px;"
                          *ngIf="!paymentForm.get('creditCardNumber').value">0000 0000 0000 0000</p>
                      </div>
                      <div class="column">
                        <p class="text-default-black" *ngIf="!paymentForm.get('creditCardHolderName').value">{{
                          'store.cardholder' | translate }}</p>
                        <p class="text-default-black" *ngIf="paymentForm.get('creditCardHolderName').value">
                          {{paymentForm.get('creditCardHolderName').value.toUpperCase()}}
                        </p>
                      </div>
                      <div class="column">
                        <p class="text-default-black" *ngIf="paymentForm.get('expirationCard').value">
                          {{paymentForm.get('expirationCard').value | mask:'00/00'}}</p>
                        <p class="text-default-black" *ngIf="!paymentForm.get('expirationCard').value">{{
                          'store.validity'
                          | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12" *ngIf="false">
                  <div class="control">
                    <label class="radio text-left text-default-black mb-10">
                      <input type="radio" [value]="1" name="payment">
                      <span [style.color]="user.mainColor"></span>
                      {{ 'store.debit' | translate }}
                    </label>
                    <label class="radio text-left text-default-black mb-10">
                      <input type="radio" [value]="2" name="payment">
                      <span></span>
                      {{ 'store.credit' | translate }}
                    </label>
                  </div>
                </div>
                <div class="column is-12">
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.fullname-card' | translate
                    }}
                  </p>
                  <input class="input" autocomplete="off" data-iugu="full_name"
                    [ngClass]="{'input-error':paymentForm.get('creditCardHolderName').errors?.required && submitted }"
                    formControlName="creditCardHolderName" (focus)="validateCreditCardHolderName()">
                  <app-alert-field alertType=3 alertTextTranslate="general.required"
                    *ngIf="(paymentForm.get('creditCardHolderName').errors?.required && (submitted || paymentForm.get('creditCardHolderName').touched))">
                  </app-alert-field>
                </div>
                <div class="columns is-multiline " style="padding: 10px;">
                  <div class="column is-6">
                    <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.card-number' | translate
                      }}
                    </p>
                    <input class="input" autocomplete="off" data-iugu="number" mask="0000 0000 0000 0000"
                      [ngClass]="{'input-error':paymentForm.get('creditCardNumber').errors?.required && submitted }"
                      formControlName="creditCardNumber" (blur)="validateCreditCardNumber()">
                    <app-alert-field alertType=3 alertTextTranslate="general.required"
                      *ngIf="(paymentForm.get('creditCardNumber').errors?.required && (submitted || paymentForm.get('creditCardNumber').touched))">
                    </app-alert-field>
                    <app-alert-field alertType=3 alertTextTranslate="general.invalid-number-creditcard"
                      *ngIf="(paymentForm.get('errorCreditCardNumber').errors?.creditCardNumberInvalid)">
                    </app-alert-field>
                  </div>
                  <div class="column is-3">
                    <p class="text-default-questions limit-text-box-basic" [style.color]="user.mainColor">{{
                      'store.expire-date' | translate
                      }}
                    </p>
                    <input class="input" autocomplete="off" data-iugu="expiration" (blur)="validateExpirationString()"
                      [ngClass]="{'input-error':paymentForm.get('expirationCard').errors?.required && submitted }"
                      formControlName="expirationCard" maxlength="5" mask="00/00"
                      [readonly]="!paymentForm.get('creditCardNumber').value">
                    <app-alert-field alertType=3 alertTextTranslate="general.required"
                      *ngIf="(paymentForm.get('expirationCard').errors?.required && (submitted || paymentForm.get('expirationCard').touched))">
                    </app-alert-field>
                    <app-alert-field alertType=3 alertTextTranslate="general.invalid-expiration-creditcard"
                      *ngIf="(paymentForm.get('errorExpirationCard').errors?.expirationCardInvalid)">
                    </app-alert-field>
                  </div>
                  <div class="column is-3">
                    <p class="text-default-questions limit-text-box-basic" [style.color]="user.mainColor">{{
                      'store.code' | translate }}</p>
                    <input class="input" data-iugu="verification_value" *ngIf="!cvvAmex" mask="000"
                      (blur)="validateCVV()"
                      [ngClass]="{'input-error':paymentForm.get('cvvCard').errors?.required && submitted }"
                      formControlName="cvvCard" [readonly]="!paymentForm.get('creditCardNumber').value">
                    <input class="input" data-iugu="verification_value" mask="0000" *ngIf="cvvAmex"
                      (blur)="validateCVV()"
                      [ngClass]="{'input-error':paymentForm.get('cvvCard').errors?.required && submitted }"
                      formControlName="cvvCard" [readonly]="!paymentForm.get('creditCardNumber').value">
                    <app-alert-field alertType=3 alertTextTranslate="general.required"
                      *ngIf="(paymentForm.get('cvvCard').errors?.required && (submitted || paymentForm.get('cvvCard').touched))">
                    </app-alert-field>
                    <app-alert-field alertType=3 alertTextTranslate="general.invalid-cvv-creditcard"
                      *ngIf="(paymentForm.get('errorCvvCard').errors?.errorCvvCardInvalid)">
                    </app-alert-field>
                  </div>
                </div>

                <div class="column is-12">
                  <p class="text-default-questions" [style.color]="user.mainColor">{{ 'store.installments-total' |
                    translate
                    }}</p>
                  <select class="select"
                    [ngClass]="{'input-error':paymentForm.get('quantityPlots').errors?.required && submitted }"
                    formControlName="quantityPlots" (change)="createCreditCardToken()" [readonly]="(!this.paymentForm.get('creditCardNumber').value
                    || !this.paymentForm.get('creditCardHolderName').value
                    || !this.paymentForm.get('cvvCard').value
                    || !this.paymentForm.get('expirationCard').value)">
                    <option value="null">{{ 'profile.select' | translate }}</option>
                    <option *ngFor="let plots of  order.plots" [ngValue]="plots.quantity">{{plots.quantity}} {{
                      'store.installments' | translate }}
                      {{plots.fmtPrice}}
                      {{ 'store.money' | translate }}
                    </option>
                  </select>
                  <app-alert-field alertType=3 alertTextTranslate="general.required"
                    *ngIf="(paymentForm.get('quantityPlots').errors?.required && (submitted || paymentForm.get('quantityPlots').touched))">
                  </app-alert-field>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <div class="center-vertical" *ngIf="false">
                      <input type="checkbox" class="checkbox m-0" value="None" id="alertCheck" name="check"  />
                      <label for="alertCheck" class="text-default-card m-0">{{ 'store.save-informations' | translate
                        }}</label>
                    </div>
                  </div>
                </div>
                <!-- CARTÃO -->
              </div>
              <div *ngIf="paymentForm.get('paymentTypeId').value" class="column is-12">
                <div class="column is-12 title-default2 m-0">
                  <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{ 'store.payment-data' | translate }}
                  </h1>
                </div>

                <div class="column is-12">
                  <p class="text-default-card m-0">
                    <b *ngIf="paymentForm.get('paymentTypeId').value == 1">{{ 'store.bankslip-payment' | translate
                      }}</b>
                    <b *ngIf="paymentForm.get('paymentTypeId').value == 2">{{ 'store.creditcard-payment' | translate
                      }}</b>
                    <span class="payment-total">{{ 'store.total-value' | translate }} <b>{{order?.fmtTotal}}</b></span>
                  </p>
                </div>
                <div class="column is-12">
                  <hr class="m-0">
                </div>
                <div class="column is-12 payment-data text-center">
                  <p class="text-default-black m-0" style="font-size: 14px;">{{ 'store.buy-advice' | translate }}
                    <a class="link-default hover-itens">{{ 'store.service-terms' | translate }}</a>
                  </p>
                </div>
                <div class="column is-12 text-center">
                  <a class="btn-default-confirmed" (click)="payBankSlip()"
                    *ngIf="paymentForm.get('paymentTypeId').value == 1">{{
                    'store.generate-bankslip' |
                    translate
                    }}</a>
                  <a class="btn-default-confirmed" (click)="payCreditCard()"
                    *ngIf="paymentForm.get('paymentTypeId').value == 2 && !submitCreditCard">{{
                    'store.perform-payment' | translate
                    }}</a>
                  <a class="btn-default-confirmed"
                    *ngIf="paymentForm.get('paymentTypeId').value == 2 && submitCreditCard">Aguarde ...</a>
                </div>
                <div class="column is-12">
                  <hr class="m-0">
                </div>
                <div class="column is-12">
                  <span class="text-default-card m-0" style="font-size: 12px;">{{ 'store.copyright' | translate
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-4" *ngIf="order?.products?.length > 0">
        <div class="box-default">
          <div class="colums is-multiline">

            <div class="column is-12" *ngFor="let product of order?.products; let i = index">
              <div class="columns is-multiline">
                <div class="pr-10 center-img-responsive">
                  <img class="img-info product-img-item" [src]="getProductImage(product.urlImage)">
                </div>
                <div class="">
                  <p class="text-default-black m-0">{{product.name}}</p>
                  <p class="text-default-black m-0 mt-10" style="font-size: 22px;"><b>{{product.fmtPrice}}</b></p>
                </div>
                <div class="column is-12 center-vertical" style="justify-content: center;">
                  <span class="text-default-black m-0 text-quantity">{{ 'store.quantity' | translate }}</span>
                  <span class="sub-pagination hover-itens" (click)="lessItem(i)">-</span>
                  <input class="input quantity-pagination" type="text" value="{{product.quantity}}">
                  <span class="sub-pagination hover-itens" (click)="moreItem(i)">+</span>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.sub-total' | translate}}
                      <span *ngIf="order.totalItens > 1">({{order.totalItens}} itens)</span>
                      <span *ngIf="order.totalItens  == 1">({{order.totalItens}} item)</span>
                    </b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtSubTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.discount' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtTotalDiscounted}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.amount' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtTotal}}</b></p>
                  <p class="text-default-card text-right m-0" style="font-size: 14px;">{{'products-basket.in-until' |
                    translate}} <b> {{order.totalInstallments}}x</b> {{'general.of' | translate}}
                    <b>{{order.fmtInstallmentValue}} </b> {{'products-basket.interest-free' | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
