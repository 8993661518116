import { Action } from "@ngrx/store";

export enum AppActionTypes {
  APP_LOAD = '[App] App Load',
  APP_FAIL = '[App] App Error',
  APP_SUCCESS = '[App] App Success',
}

export class AppLoad implements Action {
  readonly type = AppActionTypes.APP_LOAD;
  constructor(public payload: any) { }
}

export class AppFail implements Action {
  readonly type = AppActionTypes.APP_FAIL;
  constructor(public payload: any) { }
}

export class AppSuccess implements Action {
  readonly type = AppActionTypes.APP_SUCCESS;
  constructor(public payload: any) { }
}

export type AppActions = AppLoad | AppFail | AppSuccess