
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AlertService } from '@app/components/alert/alert.service';
import { ConfirmModalComponent } from '@app/components/modal-container/confirm-modal/confirm-modalcomponent';
import { ModalService } from '@app/components/modal-container/modal.service';
import { environment } from '@env/environment';
export class BaseView {
  public readonly STATUS_ALL = 0;
  public readonly STATUS_NOT_INITIATED = 1;
  public readonly STATUS_INITIATES = 2;
  public readonly STATUS_COMPLETED = 3;

  constructor() {}

  public confirmModal(message: string, callback: any, modal: ModalService) {
    modal.openConfirm(ConfirmModalComponent, message, callback, { mode: "" });
  }

  public alertModal(message: string, callback?: any) {}

  public getLetterOfTheAlphabet(index: number) {
    const alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    return alphabet[index];
  }

  public onRegisterSuccess(
    data: any,
    message: string,
    alertService: AlertService,
    callback?: any
  ) {
    window.scroll(0, 0);
    alertService.success(message);
    setTimeout(() => {
      callback();
    }, 2000);
  }

  public onSucess(data: any, message: string, alertService: AlertService) {
    window.scroll(0, 0);
    alertService.success(message);
  }

  public onError(data: any, message: string, alertService: AlertService) {
    window.scroll(0, 0);
    alertService.danger(message);
  }

  disableFieldValidation(formGroup: FormGroup, fieldName: string) {
    formGroup.get(fieldName).setValue(null);
    formGroup.get(fieldName).setValidators(null);
    formGroup.get(fieldName).updateValueAndValidity();
  }

  enableFieldValidation(
    formGroup: FormGroup,
    fieldName: string,
    validators: ValidatorFn | null | undefined
  ) {
    formGroup.get(fieldName).setValidators(validators);
    formGroup.get(fieldName).updateValueAndValidity();
  }

  public clearField(abstractControl: AbstractControl) {
    abstractControl.setValue(abstractControl.value.trim());
  }

  public getResourceUri(resourceUrl: string) {
    if (!resourceUrl) {
      return null;
    }
    const bucketUri = environment.development
      ? environment.bucketUri
      : `${environment.domainUser}/s3/`;
    return `${bucketUri}${resourceUrl}`;
  }

  public getLogo(logo) {
    if (!logo) {
      return "/assets/images/logo/home-logo.png";
    }
    return this.getResourceUri(logo);
  }

  public getPhoto(photo) {
    if (!photo) {
      return "";
    }
    return this.getResourceUri(photo);
  }

  public getImage(image) {
    if (!image) {
      return "/assets/images/icons/purchases/product_white_circle.png";
    }
    return this.getResourceUri(image);
  }

  public getProductImage(image) {
    if (!image) {
      return "/assets/images/icons/sacola.svg";
    }
    return this.getResourceUri(image);
  }

  public convertDateToString(date) {
    if (!date) {
      return "";
    }
    const array = date.toString().split("/");
    const day = array[0];
    const month = array[1];
    const yaer = array[2];
    const returnDate = `${yaer}-${month}-${day}`;
    return returnDate;
  }

  public convertStringToDate(date) {
    if (!date) {
      return null;
    }
    const array = date.toString().split("-");
    const year = array[0]; // 16-1980-08
    const month = array[1];
    const day = array[2];
    const returnDate = `${year}/${month}/${day}`;

    return returnDate;
  }

  public limitDescription(description: string, limit: number = 150): string {
    return description.length < limit
      ? description
      : `${description.substring(0, limit)}...`;
  }
}
