import { UserContextService } from '@app/core/services/user-context.service';
import { MatriculationService } from '../user/service/matriculation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Credentials } from '@app/core/models/credentials';
import { BaseView } from '@app/views/shared/BaseView';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { Color, Label } from 'ng2-charts';
import { ChartType, ChartDataSets, RadialChartOptions } from 'chart.js';
import { Observable } from 'rxjs';
import { UserAssessmentReport } from '../user/model/assessment/user-assessment-report';
import { Highlight } from './model/highlight';
import { UserPending } from '../user/model/user/user-pending';
import { Assessment } from '../user/model/assessment/assessment';
import { UserAssessmentService } from '../user/service/user-assessments.service';
import { UserService } from '../user/service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserPunctuationService } from '../user/service/user-punctuartion.service';


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.sass"],
})
export class DashboardComponent extends BaseView implements OnInit {
  public activeItem: number = 0;
  public titlePagination: Text;
  public showActivities = true;
  public showHistoric = true;

  public radarChartLabels: Label[];
  public radarChartData: ChartDataSets[];
  public radarChartType: ChartType = "radar";

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    maintainAspectRatio: true,

    scale: {
      angleLines: {
        display: false,
      },
      ticks: {
        display: false,
        beginAtZero: true,
        min: 0,
        max: 100,
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
  };
  radarChartOptionsColors: Color[];

  weakerLineChartData: ChartDataSets[];
  weakerLineChartLabels: Label[];
  weakerLineChartOptions = {
    responsive: true,

    scales: {
      xAxes: [
        {
          display: true,
          barPercentage: 0.3,
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  weakerLineChartColors: Color[];
  weakerLineChartLegend = true;
  weakerLineChartPlugins = [];
  weakerLineChartType = "bar";

  strongerLineChartData: ChartDataSets[];
  strongerLineChartLabels: Label[];
  strongerLineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          barPercentage: 0.3,
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  strongerLineChartColors: Color[];
  strongerLineChartLegend = true;
  strongerLineChartPlugins = [];
  strongerLineChartType = "bar";

  user$: Observable<Credentials>;
  user: Credentials;

  userAssessmentReport$: Observable<UserAssessmentReport>;
  userAssessmentReport: UserAssessmentReport;

  public pendingActivities: boolean = false;

  matriculationId: number;
  assessmentId: number;
  userPending = new UserPending();

  public historicAssessments: Array<Assessment>;

  public highlights = new Array<Highlight>();
  public windowWidth = window.innerWidth;
  public showIconHistories = true;
  chartReady: boolean = false;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    public userAssessmentService: UserAssessmentService,
    private activatedRoute: ActivatedRoute,
    private matriculationService: MatriculationService,
    private userService: UserService,
    private translate: TranslateService,
    private route: Router,
    private userContextService: UserContextService
  ) {
    super();
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (this.windowWidth < 1024) {
      this.showActivities = false;
      this.showHistoric = false;
    }
    this.userContextService.stopExecution();
    this.matriculationId =
      this.activatedRoute.snapshot.params["matriculationId"];
    this.assessmentId = this.activatedRoute.snapshot.params["assessmentId"];
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe((user) => {
      this.user = user;

      this.userService.getUserPending(user.id).subscribe((pending) => {
        this.userPending = pending;
        this.loadHighlights(user);
        this.loadHistoricAssessments();
      });
    });
  }

  ngAfterViewInit() {}

  public loadHighlights(user: Credentials) {
    this.userAssessmentService
      .getAssessments(user.id, null, this.STATUS_NOT_INITIATED)
      .subscribe((assessments) => {
        if (assessments) {
          assessments.forEach((assesment) => {
            const highlight = new Highlight();
            highlight.id = assesment.assessmentId;
            highlight.name = assesment.assessmentName;
            highlight.typeId = 1;
            highlight.matriculationId = assesment.matriculationId;
            highlight.description = assesment.description;
            this.highlights.push(highlight);
          });
        }
      });
    this.matriculationService
      .getTrainingMatriculations(2, user.id, null, this.STATUS_NOT_INITIATED)
      .subscribe((trails) => {
        if (trails) {
          trails.forEach((trail) => {
            const highlight = new Highlight();
            highlight.id = trail.trainingId;
            highlight.name = trail.name;
            highlight.typeId = 2;
            highlight.matriculationId = trail.matriculationId;
            highlight.description = trail.trainingDescription;
            this.highlights.push(highlight);
          });
        }
      });
  }

  public loadHistoricAssessments() {
    if (this.user.id) {
      this.userAssessmentService
        .getAssessments(this.user.id, null, this.STATUS_COMPLETED)
        .subscribe((assessments) => {
          if (assessments && assessments.length > 0) {
            this.historicAssessments = assessments;
            const item = localStorage.getItem("histories");
            if (this.historicAssessments.length == parseInt(item)) {
              this.showIconHistories = false;
            }
            if (this.assessmentId && this.matriculationId) {
              this.loadAssessmentGraphics(
                this.assessmentId,
                this.matriculationId
              );
            } else {
              this.loadAssessmentGraphics(
                assessments[0].assessmentId,
                assessments[0].matriculationId
              );
            }
          }
        });
    }
  }

  loadGraphic(assessment: Assessment) {
    this.loadAssessmentGraphics(
      assessment.assessmentId,
      assessment.matriculationId
    );
  }

  loadAssessmentGraphics(assessmentId, matriculationId) {
    this.userAssessmentReport$ = this.userAssessmentService.getAssessmentReport(
      this.user.id,
      assessmentId,
      matriculationId
    );

    this.userAssessmentReport$.subscribe((userAssessmentReport) => {
      this.userAssessmentReport = userAssessmentReport;
      this.chartReady = true;
      this.loadRadarData(userAssessmentReport);
      this.loadWeakerCompetencies(userAssessmentReport);
      this.loadStrongerCompetencies(userAssessmentReport);
    });
  }

  public loadRadarData(userAssessmentReport: UserAssessmentReport) {
    this.radarChartLabels = [];

    userAssessmentReport.allCompetences.forEach((competence) => {
      this.radarChartLabels.push(`${competence.name} / ${competence.score}`);
    });

    this.radarChartOptionsColors = [
      {
        backgroundColor: "rgba(187, 190, 201, 0.7)",
        borderColor: "rgb(149, 151, 156)",
        pointBackgroundColor: "rgb(149, 151, 156)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(149, 151, 156)",
      },
    ];

    const data = [];
    userAssessmentReport.allCompetences.forEach((competence) => {
      data.push(competence.score);
    });

    this.radarChartData = [
      { data: data, label: "Competência Medidas", fill: true },
    ];
  }

  public loadWeakerCompetencies(userAssessmentReport: UserAssessmentReport) {
    this.weakerLineChartLabels = [];
    userAssessmentReport.weakerCompetencies.forEach((competence) => {
      this.weakerLineChartLabels.push(competence.name);
    });

    const data = [];
    userAssessmentReport.weakerCompetencies.forEach((competence) => {
      data.push(competence.score);
    });
    const labels = [];
    userAssessmentReport.weakerCompetencies.forEach((competence) => {
      labels.push(competence.name);
    });

    this.weakerLineChartData = [
      { data: data, label: this.translate.instant("report.weak-competences2") },
    ];

    this.weakerLineChartColors = [
      {
        backgroundColor: "rgba(255, 99, 132)",
      },
    ];
  }

  public loadStrongerCompetencies(userAssessmentReport: UserAssessmentReport) {
    this.strongerLineChartLabels = [];
    userAssessmentReport.strongerCompetencies.forEach((competence) => {
      this.strongerLineChartLabels.push(competence.name);
    });

    const data = [];
    userAssessmentReport.strongerCompetencies.forEach((competence) => {
      data.push(competence.score);
    });

    this.strongerLineChartData = [
      {
        data: data,
        label: this.translate.instant("report.strong-competences2"),
      },
    ];

    this.strongerLineChartColors = [
      {
        backgroundColor: "rgba(22, 138, 240)",
      },
    ];
  }

  public redirect(id: number, matriculationId: number, typeId: number) {
    if (typeId == 1) {
      this.route.navigate([`/assessments/${id}/intro/${matriculationId}`]);
    } else if (typeId == 2) {
      this.route.navigate([
        `/trails/${id}/type/${typeId}/trail-home/${matriculationId}`,
      ]);
    } else if (typeId == 3) {
      this.route.navigate([
        `/trainings/${id}/type/${typeId}/training-home/${matriculationId}`,
      ]);
    }
  }

  accessItem(index) {
    this.activeItem = index;
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {}

  public viewHistoric(histories) {
    localStorage.setItem("histories", histories);
    this.showHistoric = !this.showHistoric;
    this.showIconHistories = false;
  }
}
