import { CanLoad, Route, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from 'app/core/services/auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private auth: AuthService,
    private route: Router
  ) { }

  checkAuth(): boolean {
    const logged = this.auth.isLoggedIn();
    if (!logged) {
      this.route.navigate(['']);
    }
    return logged;
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  canActivate(): boolean {
    return this.checkAuth();
  }

}
