export class ChatMessage {
    id: number;
    message: string;
    conferenceId: number;
    customerId: number;
    consultantId: number;
    groupId: number;
    name: string;
    nameInitials: string;
    photo: string;
    isConsultant: boolean;
}
