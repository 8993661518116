import { Module } from "./module";

export class Matriculation{

  userId: number;
  trainingId: number;
  urlImage:string;
  trainingDescription: string;
  trainingName: string;
  matriculationStatusId: number;
  matriculationId: number;
  publisher: boolean;
  recommendedAssessmentId: number;
  trainingTypeId: number;
  modules: Array<Module>;
  workload: number;
  totalActivities: number;
  concludedActivities: number;
  allowsRandomAccess: boolean;
  name: string;
  startDate: Date;
  endDate: Date;
  startDateFormat: string;
  endDateFormat: string;
  assessmentMatriculationId: number;
}
