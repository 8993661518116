import { Theme } from '../models/theme.model';


export const defaultTheme: Theme = {
  name: 'default',
  primary: '#2699fb',
  onPrimary: '#FFFFFF',
  secondary: '#111D4A',
  onSecondary: '#FFFFFF',
  accent: '#2699fb',
  showMenu: false
}
