import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserContext } from '../models/user-context';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  public readonly ASSESSMENT = 1;
  public readonly TRAINING = 2;
  public readonly TRAIL = 3;
  public readonly VIRTUALSTORE = 4;
  public readonly CONFERENCE = 5;

  public userContext = new UserContext();

  public userSource = new BehaviorSubject<UserContext>(this.userContext);

  constructor() { }

  inExecution(type) {
    this.setCurrentType(type);
    this.userContext.inExecution =  true;
  }

  inMatriculartionExecutionById(type, id, matriculationId){
    this.setCurrentType(type);
    this.userContext.inExecution =  true;
    this.userContext.id = id;
    this.userContext.matriculationId  = matriculationId;
  }
  stopExecution(){
    this.setCurrentType(0);
    this.userContext.inExecution =  false;  
  }

  getId(){
    return this.userContext.id;
  }

  getMatriculationId(){
    return this.userContext.matriculationId;
  }

  getCurrentType(){
    return this.userContext.typeId;
  }

  setCurrentType(type){
    this.userContext.typeId = type;
  }

}
