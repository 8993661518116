import * as actionTheme from "../actions/theme.actions";
import { defaultTheme } from "../../theme/theme.default"
import { Theme } from '@app/core/models/theme.model';

export interface ThemeState {
  data: Theme,
  loaded: boolean,
  loading: boolean,
}

export const initialState: ThemeState = {
  data: defaultTheme,
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: actionTheme.ThemeActions): ThemeState {

  switch (action.type) {

    case actionTheme.ThemeActionsTypes.THEME_LOAD: {
      return { ...state, loading: true }
    }

    case actionTheme.ThemeActionsTypes.THEME_SET:
    case actionTheme.ThemeActionsTypes.THEME_SUCCESS: {
      return {
        data: {
          ...action.payload,
        },
        loading: false,
        loaded: true,
      }
    }

    case actionTheme.ThemeActionsTypes.THEME_FAIL: {
      return { ...state, loading: false, loaded: false }
    }
  }

  return state;
}
