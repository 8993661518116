import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { TrainingToolComponent } from './training-tool/training-tool.component';
import { TrainingHomeComponent } from './training-home/training-home.component';
import { CertifiedComponent } from './certified/certified.component';
import { TrainingListComponent } from './traininig-list/training-list.component';


const routes: Routes = [
  {
    path: "",
    component: TrainingListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trainings/type/:typeId",
    component: TrainingListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trails/type/:typeId",
    component: TrainingListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trainings/:trainingId/type/:typeId/training-home/:matriculationId/:publisher",
    component: TrainingHomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trainings/:trainingId/type/:typeId/training-home/:matriculationId",
    component: TrainingHomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trainings/:trainingId/type/:typeId/training-certified/:matriculationId",
    component: CertifiedComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trainings/:trainingId/type/:typeId/training-tool/:matriculationId/module/:moduleId/tool/:toolId",
    component: TrainingToolComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trails/:trainingId/type/:typeId/trail-home/:matriculationId",
    component: TrainingHomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trails/:trainingId/type/:typeId/trail-certified/:matriculationId",
    component: CertifiedComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "trails/:trainingId/type/:typeId/trail-tool/:matriculationId/module/:moduleId/tool/:toolId",
    component: TrainingToolComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainingRoutingModule { }
