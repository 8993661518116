import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtual-store',
  templateUrl: './virtual-store.component.html',
  styleUrls: ['./virtual-store.component.scss']
})
export class VirtualStoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
