import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '@app/core/services/language.service';
import { Language } from '@app/core/models/language.model';

@Component({
  selector: 'ats-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.sass']
})
export class LanguageSwitchComponent implements OnInit {

  public languages: Language[] = this.language.availableLanguages;
  public activeLanguage: any = 'pt';
  public showLanguage = false;

  @Input() login: string | null = null;

  constructor(private language: LanguageService) {
  }

  ngOnInit() {
  }

  switchLanguage(language: Language) {
    this.language.updateLocale(language);
  }

  setActive(setLanguage: Language) {
    const sessionLanguage: Language = this.language.getSessionLanguage();
    if (sessionLanguage.code) {
      this.activeLanguage = sessionLanguage.code;

    }
    return setLanguage.code === sessionLanguage.code;
  }

}
