import { Component, OnInit } from '@angular/core';

import { ModalService } from '@app/components/modal-container/modal.service';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.sass']
})
export class ConfirmModalComponent implements OnInit {

  public message: string;

  constructor(
    private modal: ModalService
  ) { }

  ngOnInit() {
    this.message = this.modal.message;
  }

  close() {
    this.modal.destroy();
  }

  public confirm() {
    this.modal.destroy();
    this.modal.myCallback();
  }

}
