<form #form='ngForm' [formGroup]="resetPasswordForm" class="login-form form">
  <div class="column is-12" style="padding-bottom: 60px;">
    <ats-language-switch login="show"></ats-language-switch>
  </div>
  <div class="field text-center logo-login" *ngIf="(customerPreference$ | async) as customerPreference">
    <img src="{{getLogo(customerPreference?.logo)}}">
  </div>
  <div class="padding-itens">
    <h1 class="title-login">{{ 'recover.password-recover' | translate }}</h1>
    <p class="sub-title-login">
      {{ 'recover.forgot-title' | translate }}
    </p>
    <div class="margin-login">
      <div class="field p-0 m-0">
        <p class="itens-login">{{ 'recover.input-email' | translate }}</p>
        <input class="input" type="login" [formControl]="resetPasswordForm.get('login')"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" placeholder="{{ 'placeholder.email' | translate }}"
          style="margin: 0;"
          [ngClass]="{'select-check': (!resetPasswordForm.get('login').errors && resetPasswordForm.get('login').value),
                            'inputt-check-error': (resetPasswordForm.get('login').errors || !resetPasswordForm.get('login').value)&& form.submitted}">
        <app-alert-field alertType=2 alertTextTranslate="register.invalid-email"
          *ngIf="(resetPasswordForm.get('login').errors?.pattern) && form.submitted"></app-alert-field>

        <app-alert-field alertType=2 alertTextTranslate="register.email_not_taken"
          *ngIf="resetPasswordForm.get('login').errors?.userEmailNotTaken && (form.submitted || resetPasswordForm.get('login').touched)"></app-alert-field>
      </div>
      <div class="field">
        <p class="control text-center">
          <button type="submit" (click)="sendReset()" class="btn button-login mt-50">{{ 'recover.send' | translate }}</button>
        </p>
        <p class="itens-login text-center reset-password-login">{{ 'recover.come-back-to' | translate }}</p>
        <p class="text-center"><a class="itens-login" (click)="close()"><b>{{ 'recover.lower-login' | translate }}</b></a></p>
      </div>
    </div>
  </div>
</form>
