import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.sass']
})
export class BackArrowComponent {

  @Input() textAlongArrow: string | null = null;

  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
