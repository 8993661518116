import { Tracking } from "./tracking";

export class OrderItem {
  id: number;
  orderId: number;
  productId: number;
  orderItemStatusId: number;
  name: string;
  urlImage: string;
  description: string;
  limitedDescription: string;
  price: number;
  discount: number;
  discountPrice: number;
  quantity: number;
  creationDate: Date;
  active: boolean;
  orderItemTypeId:  number;

  moduleItemId:number;
	moduleId:number;
	moduleLink: string;
  trackings: Array<Tracking>;
}
