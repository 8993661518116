import { ReportComponent } from './report/report.component';
import { IntroComponent } from './intro/intro.component';

import { AnswersComponent } from './answers/answers.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@app/core/auth/auth.guard';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';



const routes: Routes = [
  {
    path: '', component: AssessmentListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'assessments/:assessmentId/intro/:matriculationId', component: IntroComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'assessments/:assessmentId/answers/:matriculationId', component: AnswersComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  } ,
  {
    path: 'assessments/:assessmentId/report/:matriculationId', component: ReportComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'assessments/:assessmentId/report/:matriculationId/recommendedTrail/:recommendedTrainingId', component: ReportComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssessmentRoutingModule { }
