import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { UserPunctuation } from "../model/user/user-punctuation";

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class UserPunctuationService {

  constructor(private http: HttpClient) { }

  public getPunctuation(userId: number) {

    return this.http.get<UserPunctuation>(`${API_URL}/${userId}/punctuations`);
  }

  public savePunctuation(userId: number,customerId:number, moduleId: number, moduleItemId:number, punctuation: number ){
    
    const userPunctuation = new UserPunctuation();
    userPunctuation.punctuation = punctuation;
    userPunctuation.customerId = customerId;
    userPunctuation.userId = userId;
    userPunctuation.moduleId =  moduleId;
    userPunctuation.moduleItemId = moduleItemId;
    
    return this.http.post<UserPunctuation>(`${API_URL}/${userId}/punctuations`, userPunctuation);
  }

}
