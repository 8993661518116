import { BaseView } from './../../shared/BaseView';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/components/alert/alert.service';
import { ValidationService } from '@app/components/validations/validation.service';
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';
import { CustomerPreference } from '@app/views/account/model/customer-preference';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserContextService } from '@app/core/services/user-context.service';
import { ConferenceUserInviteService } from '@app/views/user/service/conference-user-invite.service';
import { Credentials } from '@app/core/models/credentials';
import { UserInvite } from '@app/views/user/model/conference/user-invite';
import { AuthToken } from '@app/core/models/auth-token';
import { CredentialsService } from '@app/core/services/credentials.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.sass']
})
export class SignInComponent extends BaseView implements OnInit {

  loginForm: FormGroup;
  error: object;
  user: any;
  customerPreference$: Observable<CustomerPreference>;
  customerPreference: CustomerPreference;

  constructor(
    private form: FormBuilder,
    private route: Router,
    private validation: ValidationService,
    private alertService: AlertService,
    private auth: AuthService,
    private translate: TranslateService,
    private customerPreferenceService: CustomerPreferenceService,
    private userContextService: UserContextService,
    private conferenceUserInviteService: ConferenceUserInviteService,
    private credentialsService: CredentialsService,
  ) {
    super();
  }

  ngOnInit() {

    this.customerPreference$ = this.customerPreferenceService.getPreference();
    this.customerPreference$.subscribe(preference => {
      this.customerPreference = preference;
    });

    this.loginForm = this.form.group({
      groupId: [null],
      customerId: [null],
      login: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      socialNetworkAccount: [null],
      externalId: [null, []]
    });
  }



  public login() {

    if (this.loginForm.valid) {

      this.loginForm.get('groupId').setValue(this.customerPreference.groupId);
      this.loginForm.get('customerId').setValue(this.customerPreference.customerId);

      this.auth.login(this.loginForm.value).
        subscribe(
          data => {       
            this.onLoginSuccessRedirectArea(data);
          },
          err => this.onLoginError(err.error)
        );

    } else {
      this.validation.markAllFormFieldsAsTouched(this.loginForm);
    }
  }

  private onLoginSuccessRedirectArea(data: AuthToken) {
    if (!this.customerPreference || !this.customerPreference.redirect) {
      this.route.navigate(['/dashboard']);
    } else {
      const redirect = this.customerPreference.redirect;
      if (redirect == 'redirect-assessment') {
        if (this.customerPreference.refValue) {
          this.userContextService.inExecution(this.userContextService.ASSESSMENT);
          this.customerPreferenceService.deleteCustomerPreference();
          this.route.navigate([`assessments/${this.customerPreference.refValue}/intro/0`]);
        }
      } else if (redirect == 'redirect-training') {
        this.customerPreferenceService.deleteCustomerPreference();
        this.route.navigate([`trainings/${this.customerPreference.refValue}/type/1/training-home/0/${this.customerPreference.publisher}`]);
      } else if (redirect == 'redirect-conference') {      
        if (this.customerPreference.invite) {
          this.conferenceUserInviteService.getUserInvite(0, this.customerPreference.refValue, this.customerPreference.invite)
            .subscribe(invite => {
              if (!invite.newRegistration) {
                invite.alreadyRegistered = true;
                this.conferenceUserInviteService.updateUserInvite(0, this.customerPreference.refValue, this.customerPreference.invite, invite)
                  .subscribe(data => {
                    this.customerPreferenceService.deleteCustomerPreference();
                    this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
                  })
              } else {
                this.customerPreferenceService.deleteCustomerPreference();
                this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
              }
            });
        } else if (this.customerPreference.senderId) {
          const userInvite = new UserInvite()
          userInvite.conferenceId = this.customerPreference.refValue;
          this.credentialsService.getUserCredentials()
            .subscribe((user) => {
              userInvite.userId = this.customerPreference.senderId;
              userInvite.guestName = user.fullName;
              userInvite.guestEmail =  user.email;      
              userInvite.newRegistration = false;      
              userInvite.alreadyRegistered = true;
              this.conferenceUserInviteService.saveUserInvite(0,this.customerPreference.refValue, userInvite).subscribe(data => {
                this.customerPreferenceService.deleteCustomerPreference();
                this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
              });
            });
        } else {
          this.customerPreferenceService.deleteCustomerPreference();
          this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
        }
      } else if (redirect == 'redirect-product') {
        this.customerPreferenceService.deleteCustomerPreference();
        this.route.navigate([`/virtual-store`]);
      }
    }

  }

  private onLoginError(error) {
    this.alertService.danger(this.translate.instant('login.not-found'));
  }
}
