import { AlertService } from './../../../../../../components/alert/alert.service';
import { Product } from '@app/views/user/model/products/product';
import { UserContextService } from '@app/core/services/user-context.service';

import { CredentialsService } from '@app/core/services/credentials.service';
import { ThemeService } from '@app/core/services/theme.service';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';
import { BaseView } from '../../../../../shared/BaseView';
import { VirtualStoreBase } from '../../../virtual-store-base';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '@app/views/user/model/products/order';
import { OrderItem } from '@app/views/user/model/products/order-item';
import { UserService } from '@app/views/user/service/user.service';
import { User } from '@app/views/user/model/user/user';
import { Payer } from '@app/views/user/model/products/payer';
import { Delivery } from '@app/views/user/model/products/delivery';
import { Address } from '@app/views/user/model/products/address';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.sass']
})
export class OrderComponent extends VirtualStoreBase implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  order$: Observable<Order>;
  order: Order;
  orderId: number;
  productId: number;
  noProduct: boolean;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private virtualStoreService: VirtualStoreService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {

    window.scroll(0, 0);

    this.orderId = this.activatedRoute.snapshot.params['orderId'];

    this.productId = this.activatedRoute.snapshot.params['productId'];

    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.loadData();
    });
  }

  public loadData() {

    if (this.productId) {
      this.virtualStoreService.getProduct(this.user.id, this.user.customerId, this.productId)
        .subscribe(product => {

          if (product) {
            this.virtualStoreService.getProductOrder(this.user.id, this.ORDER_CREATED)
              .subscribe(order => {

                if (order) {
                  this.order = order;
                  this.addProduct(product);
                  this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
                    .subscribe(order => {
                      this.order = order;
                    });
                } else {
                  this.userService.getUser(this.user.id)
                    .subscribe(user => {
                      this.order = new Order();
                      this.order.userId = this.user.id;

                      this.order.orderStatusId = this.ORDER_CREATED;
                      this.order.products = new Array<OrderItem>();

                      this.loadPayer(user);

                      this.addProduct(product);

                      this.virtualStoreService.createProductsOrder(this.user.id, this.order)
                        .subscribe(order => {
                          this.order = order;
                        });
                    });
                }
              });

          } else {
            this.noProduct = true;
          }
        }, error => {
          this.noProduct = true;
        });
    } else if (this.orderId) {
      this.virtualStoreService.getProductOrderById(this.user.id, this.orderId)
        .subscribe(order => {
          this.order = order;
        });
    } else {
      this.virtualStoreService.getProductOrder(this.user.id, this.ORDER_CREATED)
        .subscribe(order => {
          this.order = order;
          if(!this.order){
            this.virtualStoreService.getProductOrder(this.user.id, this.ORDER_RECEIVED)
            .subscribe(order => {
              this.order = order;
            });
          }
        });
    }

  }

  public removeItem(index) {

    this.order.products.splice(index, 1);
    this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.order = order;
      });
  }

  private addProduct(product: Product) {

    if (!this.existsProduct(product)) {
      const orderItem = new OrderItem();
      orderItem.quantity = 1;
      if (this.order.id) {
        orderItem.orderId = this.order.id;
      }
      orderItem.urlImage = product.urlImage;
      orderItem.name = product.name;
      orderItem.description = product.description;
      orderItem.limitedDescription = product.description;
      orderItem.price = product.price;
      orderItem.discount = product.discount;
      orderItem.productId = product.id;
      orderItem.discountPrice = product.discountPrice;

      if (product.moduleId) {
        orderItem.orderItemTypeId = 1;
      } else {
        orderItem.orderItemTypeId = 2;
      }

      this.order.products.push(orderItem);
    }

  }

  private loadPayer(user: User) {

    this.order.payer = new Payer();
    this.order.payer.fullname = user.fullname;
    this.order.payer.cpf = user.cpf;
    this.order.payer.email = user.email;
    this.order.payer.cellphone = user.cellphone;
    this.order.payer.userId = user.id;
    this.order.payer.birthday = user.birthday;
    this.order.payer.frmtBirthday = user.frmtBirthday;
    this.order.payer.billingAddress = new Address();   
    this.order.payer.billingAddress.zipcode = user.zipcode;
    this.order.payer.billingAddress.street = user.street;
    this.order.payer.billingAddress.number = user.number;
    this.order.payer.billingAddress.complement = user.complement;
    this.order.payer.billingAddress.district = user.district;
    this.order.payer.billingAddress.cityId = user.cityId;

    /*
    this.order.delivery = new Delivery();
    this.order.delivery.address = new Address();
    this.order.delivery.address.zipcode = user.zipcode;
    this.order.delivery.address.street = user.street;
    this.order.delivery.address.number = user.number;
    this.order.delivery.address.complement = user.complement;
    this.order.delivery.address.district = user.district;
    this.order.delivery.address.cityId = user.cityId;
    */
  }

  public purchase() {

    this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.route.navigate([`virtual-store/purchases/payer/${this.order.id}`]);
      });
  }


  public existsProduct(product: Product) {

    if (this.order.products) {

      if (this.order.products.length == 0) {
        return false;
      }

      for (let i = 0; i < this.order.products.length; i++) {
        if (this.order.products[i].productId == product.id) {
          return true;
        }
      }
    }

    return false;
  }

  public lessItem(index: number) {

    if (this.order.products[index].quantity > 1) {
      this.order.products[index].quantity -= 1;
      this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
        .subscribe(order => {
          this.order = order;
        });
    }
  }

  public moreItem(index: number) {

    this.order.products[index].quantity += 1;
    this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.order = order;
      });
  }

}
