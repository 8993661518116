<section class="section bg-private" [ngClass]="{'section-padding': !themeService.eventMenu}"
  *ngIf="(training$ | async) as training">
  <div class="container">
    <div class="columns is-multiline" style="display: flex; justify-content: space-between;">
      <div class="column is-8">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div>
              <h1 class="title-default" [style.color]="user.mainColor">
                {{ training.trainingName}}
              </h1>
              <p class="text-default-card text-justify  mt-20">              
                <span *ngIf="training.trainingDescription" [innerHTML]="training.trainingDescription | safeHtml"></span>
                <span *ngIf="!training.trainingDescription && typeId == 1"> Nenhuma descrição cadastrada para o treinamento</span>
                <span *ngIf="!training.trainingDescription && typeId == 2"> Nenhuma descrição cadastrada para a trilha</span>
              </p>
            </div>
          </div>

          <div class="column is-12">
            <h1 class="title-default mb-50" [style.color]="user.mainColor">{{ 'training.modules' | translate }}</h1>
            <div [ngClass]="{'module-disabled': module.released == false}"
              *ngFor="let module of training.modules; let i = index">
              <h1 class="title-default2" [style.color]="user.mainColor">{{module.position}} - {{module.name}}</h1>
              <p class="text-default-card text-justify  mt-20">
                <span *ngIf="module.description" [innerHTML]="module.description | safeHtml"></span>
              </p>
              <p class="module-disabled-msg" *ngIf="!module.released">
                <b>Módulo liberado para acesso a partir de: {{module.dateReleased}} </b>
              </p>
              <div data-pattern="priority-columns">
                <table class="table table-modulo mt-20">              
                  <tbody>
                    <tr [ngClass]="{'table-modulo-active-row': module.released}"
                      (click)="redirect(training, matriculationId,module.id, tool.id,module.released,i)"
                      *ngFor="let tool of module.tools">
                      <td>                  
                          <img *ngIf="tool.toolTypeId == 1"[style.background-color]="user.mainColor" src="/assets/images/tools/video.svg">
                          <img *ngIf="tool.toolTypeId == 2"[style.background-color]="user.mainColor" src="/assets/images/tools/podcast.svg">
                          <img *ngIf="tool.toolTypeId == 3"[style.background-color]="user.mainColor" src="/assets/images/tools/course.svg">
                          <img *ngIf="tool.toolTypeId == 4"[style.background-color]="user.mainColor" src="/assets/images/tools/pdf.svg">
                          <img *ngIf="tool.toolTypeId == 5"[style.background-color]="user.mainColor" src="/assets/images/tools/assessments.svg" >
                          <img *ngIf="tool.toolTypeId == 6"[style.background-color]="user.mainColor" src="/assets/images/tools/conference.svg" >                  
                      </td>
                      <td class="">
                          <p class="title-modulo">{{tool.position}} - {{tool.name}}</p>
                          <p class="sub-title-modulo"></p>                         
                      </td>
                      <td>
                        <img src="/assets/images/icons/check-of.svg" class="icons-modules-status" *ngIf="tool.toolAccessStatusId != 3 && tool.toolAccessStatusId != 4">
                        <img src="/assets/images/icons/check.svg"  class="icons-modules-status" *ngIf="tool.toolAccessStatusId == 3 || tool.toolAccessStatusId == 4">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="column is-12" *ngIf="training.matriculationStatusId == 3">
            <div class="card-concluded">
              <div class="columns center-vertical">
                <div class="column text-center">
                  <div class="background-img">
                    <img class="center-margin-auto" src="/assets/images/diversas/historico.png">
                  </div>
                </div>
                <div class="column is-9 ml-15 text-center">
                  <h1 class="title-card-result">{{ 'training.congratulations' | translate }}</h1>
                  <p class="text-card-result text-center" *ngIf="typeId == 1">{{ 'training.finalized-training' | translate }}</p>
                  <p class="text-card-result text-center" *ngIf="typeId == 2">{{ 'training.finalized-trail' | translate }}</p>
                  <div class="text-center">
                    <a routerLink="/trainings/type/1" class="btn-default-confirmed" *ngIf="typeId == 1"
                    (click)="redirectCertified(training.trainingTypeId, training.trainingId, training.matriculationTrainingId)">{{
                      'training.certified' | translate }}</a>
                    <a routerLink="/trails/type/2" class="btn-default-confirmed" *ngIf="typeId == 2"
                    (click)="redirectCertified(training.trainingTypeId, training.trainingId, training.matriculationTrainingId)">{{
                      'training.certified' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <div class="card-default-big">
          <div class="text-center">
            <img class="img-card" [src]="getImage(training.urlImage)">
          </div>
          <h1 class="title-default text-center" style="font-size: 24px;" [style.color]="user.mainColor" *ngIf="typeId == 1">{{ 'training.training-content' | translate }}</h1>
          <h1 class="title-default text-center" style="font-size: 24px;" [style.color]="user.mainColor" *ngIf="typeId == 2">{{ 'training.trail-content' | translate }}</h1>
          <ul>
            <li *ngFor="let module of  training.modules">
              <strong>
                <i class="fas fa-circle" *ngIf="module.statusId == 3" [style.color]="user.mainColor"></i>
                <i class="far fa-circle" *ngIf="module.statusId != 3" [style.color]="user.mainColor"></i>
              </strong>
              <div class="bar-time-line" [style.background-color]="user.mainColor"></div>
              <p>{{module.position}} - {{module.name}}</p>
            </li>
            <li>
              <strong>
                <i class="fas fa-circle" *ngIf="training.matriculationStatusId == 3" [style.color]="user.mainColor"></i>
                <i class="far fa-circle" *ngIf="training.matriculationStatusId != 3" [style.color]="user.mainColor"></i>
              </strong>
              <p>{{ 'training.completion' | translate }}
              </p>
            </li>
          </ul>
          <div class="text-center">
            <a class="btn-return-timeline" *ngIf="typeId == 1" routerLink="/trainings/type/1"
              [style.background-color]="user.mainColor">{{ 'general.back' | translate }}</a>
            <a class="btn-return-timeline" *ngIf="typeId == 2" routerLink="/trails/type/2"
              [style.background-color]="user.mainColor">{{ 'general.back' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
