<div class="box-modal">
  <h1 class="title-alert alert-delete">
    <img src="/assets/images/icons/alert.png">
    {{ 'mod.important' | translate }}
    <a class="icon-close" (click)="close()"><i class="fas fa-times"></i></a>
  </h1>
  <div class="padding-itens">

    <h1 class="title-box-resume">{{message}}</h1>
    <div class="box-btn">
      <a (click)="close()" class="btn-alert-cancel" >{{ 'general.cancel' | translate }}</a>
      <a (click)="confirm()" class="btn-alert-confirm" >{{ 'mod.confirm' | translate }}</a>
    </div>

  </div>
  <footer class="footer"></footer>
</div>
