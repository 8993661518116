import { BaseView } from './../../shared/BaseView';

export class VirtualStoreBase extends BaseView{
  public readonly ALL_ORDERS = 0;
  public readonly ORDER_CREATED = 1;
  public readonly ORDER_RECEIVED = 2;
  public readonly AWAITING_PAYMENT = 3;
  public readonly PAYMENT_ACCEPT = 4;
  public readonly UNAUTHORIZED_PAYMENT = 5;
  public readonly AVAILABLE_INVOICE = 6;
  public readonly ON_CARRIAGE = 7;
  public readonly ORDER_DELIVERED = 8;


  constructor(){
    super();
  }
}
