import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public hasSession(key: string) {
    if (this.getSessionStorage(key)) { return true; }
    return false;
  }

  public setSessionStorage(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getSessionStorage(key: string): string {
    return sessionStorage.getItem(key);
  }

  public removeSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  public hasSessionStorage(key: string): boolean {
    return sessionStorage.hasOwnProperty(key);
  }

}
