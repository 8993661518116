
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";
import { Matriculation } from "../model/learning/matriculation";

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class MatriculationService {
  

  constructor(private http: HttpClient) { }

  public getTrainingMatriculations(typeId : number,userId: number, name: string,  statusId: number) {
    if (name) {
      name = encodeURIComponent(name);
    } else {
      name = '';
    }
    return this.http.get<Matriculation[]>(`${API_URL}/${userId}/trainings?statusId=${statusId}&name=${name}&typeId=${typeId}`);
  }

  public matriculation(userId: number, trainingId: number, matriculation: Matriculation){
    return this.http.post<Matriculation>(`${API_URL}/${userId}/trainings/${trainingId}/matriculations`, matriculation);
  }

  cleanHistory(userId: number, trainingId: number, matriculation: Matriculation) {
    return this.http.put<Matriculation>(`${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculation.matriculationId}/clean-history`, matriculation);
  }

  public updateMatriculation(userId: number, trainingId: number, matriculationId: number, matriculation: Matriculation){
    const url = `${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}`;
    return this.http.put<Matriculation>(url, matriculation);
  }

  public getTrainingMatriculation(userId: number, trainingId: number , matriculationId: number ) {
    return this.http.get<Matriculation>(`${API_URL}/${userId}/trainings/${trainingId}/matriculations/${matriculationId}`);
  }

  public getTrainingMatriculationCertified(userId: number, matriculationId: number ) {
    return this.http.get<Matriculation>(`${API_URL}/${userId}/trainings/matriculations/${matriculationId}/certified`);
  }

}
