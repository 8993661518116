import { FileService } from './../../../../../core/services/file.service';
import { UploadFileResponse } from './../../../../../core/models/upload-file-response';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './../../../../../core/services/theme.service';
import { BaseView } from './../../../../shared/BaseView';

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Observable } from 'rxjs';
import { UserAssessmentReport } from '../../../model/assessment/user-assessment-report';

import { Competence } from '@app/views/user/model/assessment/competence';
import { UserContext } from '@app/core/models/user-context';
import { UserContextService } from '@app/core/services/user-context.service';
import { UserAssessmentService } from '@app/views/user/service/user-assessments.service';
// declare const generetePdf: any;

import { environment } from '@env/environment';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.sass']
})
export class ReportComponent extends BaseView implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  matriculationId: number;
  assessmentId: number;
  recommendedTrainingId: number;
  userAssessmentReport$: Observable<UserAssessmentReport>;

  userAssessmentReport: UserAssessmentReport;

  userContext: UserContext;
  urlContent: string;
  constructor(
    private userAssessmentService: UserAssessmentService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    public themeService: ThemeService,
    private userContextService: UserContextService,
    private translate: TranslateService,
    private fileService: FileService,
  ) {
    super();
  }

  ngOnInit() {
    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];
    this.assessmentId = this.activatedRoute.snapshot.params['assessmentId'];
    this.recommendedTrainingId = this.activatedRoute.snapshot.params['recommendedTrainingId'];

    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      this.userAssessmentReport$ = this.userAssessmentService
        .getAssessmentReport(user.id, this.assessmentId, this.matriculationId);
        if (environment.development) {
          this.urlContent = `../../../../../../assets/report/index.html?userId=${user.id}&assessmentId=${this.assessmentId}&matriculationId=${this.matriculationId}&userName=${user.fullName}&email=${user.email}&cellphone=${user.cellphone}&development=${environment.development}`;
        } else {
          this.urlContent = `https://skills-user-ui.mirevo.com.br/s3/report/index.html?userId=${user.id}&assessmentId=${this.assessmentId}&matriculationId=${this.matriculationId}&userName=${user.fullName}&email=${user.email}&cellphone=${user.cellphone}&development=${environment.development}`;
        }
    });
  }


  redirectDashboard() {
    this.userContextService.stopExecution();
    this.route.navigate([`/dashboard/${this.assessmentId}/${this.matriculationId}`]);
  }

  redirectTrail() {
    this.userContextService.stopExecution();
    this.route.navigate([`/trails/${this.userAssessmentReport.recommendedTrainingId}/type/2/trail-home/${this.recommendedTrainingId}`]);
  }

}
