import { OrderItem } from './../../../model/products/order-item';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ThemeService } from '@app/core/services/theme.service';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Product } from '@app/views/user/model/products/product';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';

import { VirtualStoreBase } from '../../virtual-store-base';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.sass']
})
export class ProductListComponent extends VirtualStoreBase implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  public show: boolean = false;
  searchTerms: string;
  changeTab: number = 0;

  products: Array<Product>;

  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private route: Router,
    private virtualStoreService: VirtualStoreService,

  ) {
    super();
  }

  ngOnInit(): void {

    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      this.loadItens(0, false);
    });
  }

  public loadItens(moduleId: number, purchased: boolean) {
    this.changeTab = moduleId;
    if (this.user && this.user.id) {

      this.virtualStoreService.getProducts(this.user.id, this.user.customerId, this.searchTerms,moduleId, purchased)
        .subscribe(products => {
          this.products = products;
        });
    }
  }

  public purchase(productId: number) {
    this.route.navigate([`virtual-store/purchases/products/${productId}`]);
  }

  public redirectToModule(product: Product) {
    let url = ``;
    if (product.moduleId == 1) {
      url += `assessments/${product.moduleItemId}/intro/0`;
    } else if (product.moduleId == 8) {
      url += `conferences/${product.moduleItemId}/inscriptions/0`;
    } else if (product.moduleId == 10) {
      url += `trainings/${product.moduleItemId}/type/1/training-home/0/false`;
    } else if (product.moduleId == 11) {
      url += `trails/${product.moduleItemId}/type/2/training-home/0/false`;
    }
    this.route.navigate([url]);
  }
}
