import { OrderItem } from '@app/views/user/model/products/order-item';
import { Product } from '@app/views/user/model/products/product';
import { VirtualStoreBase } from '../../../virtual-store-base';
import { UserContextService } from '@app/core/services/user-context.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { ThemeService } from '@app/core/services/theme.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Component, OnInit } from '@angular/core';
import { Order } from '@app/views/user/model/products/order';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.sass']
})
export class OrderTrackingComponent extends VirtualStoreBase implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  order$: Observable<Order>;
  orders: Array<Order>;
  orderId: number;
  orderStatusId: number = 0;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService,
    private virtualStoreService: VirtualStoreService,
    private route: Router,
  ) {
    super();
  }

  ngOnInit() {

    this.userContextService.stopExecution();
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.searchOrders(this.ALL_ORDERS);
    });

  }

  public searchOrders(status: number) {
    this.orderStatusId = status;
    this.virtualStoreService.getProductOrders(this.user.id, status, '')
      .subscribe(orders => {
        this.orders = orders;
      })
  }

  redirectProductModule(product: OrderItem){
    
    this.redirectToModulePurchase(product);

    //const domain = `${environment.domainUser}/#${product.moduleLink}`;
     //window.location.href= domain;
  }

  getOrderItemName(moduleId: number){
    if (moduleId == 1) {
      return "Acessar o Assessment";
    } else if (moduleId == 11) {
      return "Acessar a Trilha";
    } else if (moduleId == 10) {
      return "Acessar o Treinamento";
    } else if (moduleId == 8) {
      return "Acessar a conferências";
    } else if (moduleId == 13) {
      return "Acessar o Plano de Carreira";
    }
  }

  redirectPayment(order: Order){
    this.route.navigate([`virtual-store/purchases/payment/${order.id}`]);
  }

  public redirectToModulePurchase(product: OrderItem) {
    let url = ``;
    if (product.moduleId == 1) {
      url += `assessments/${product.moduleItemId}/intro/0`;
    } else if (product.moduleId == 8) {
      url += `conferences/${product.moduleItemId}/inscriptions/0`;
    } else if (product.moduleId == 10) {
      url += `trainings/${product.moduleItemId}/type/1/training-home/0`;
    } else if (product.moduleId == 11) {
      url += `trails/${product.moduleItemId}/type/2/training-home/0`;
    }
    this.route.navigate([url]);
  }

  public redirectToModule(product: Product) {
    let url = ``; 
    if (product.moduleId == 1) {
      url += `assessments/${product.moduleItemId}/intro/0`;
    } else if (product.moduleId == 8) {
      url += `conferences/${product.moduleItemId}/inscriptions/0`;
    } else if (product.moduleId == 10) {
      url += `trainings/${product.moduleItemId}/type/1/training-home/0`;
    } else if (product.moduleId == 11) {
      url += `trails/${product.moduleItemId}/type/2/training-home/0`;
    }
    this.route.navigate([url]);
  }

  getTrackingImage(order, trackOrderStatusId, active) {
    if (active && order.orderStatusId != 5) {
      return `/assets/images/icons/purchases/${trackOrderStatusId}-active.svg`;
    }else{
      return `/assets/images/icons/purchases/${trackOrderStatusId}.svg`;
    }

  }
  public getBrand(brand: string) {
    if (brand) {
      if (brand.toLowerCase() == "visa") {
        return "/assets/images/brand_credit_card/visa.png";
      } else if (brand.toLowerCase() == "master") {
        return "/assets/images/brand_credit_card/mastercard.png";
      } else if (brand.toLowerCase() == "amex") {
        return "/assets/images/brand_credit_card/american-express.png";
      } else if (brand.toLowerCase() == "diners") {
        return "/assets/images/brand_credit_card/diners.png";
      }
    }
  }
}
