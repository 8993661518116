import { Note } from './../model/learning/note';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "@env/environment";
import { ChatMessage } from '../model/conference/chat-message';
import { Inscription } from '../model/conference/inscription';
import { ConferenceFile } from '../model/conference/conference-file';
import { Invite } from '../model/conference/invite';


const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class ConferenceInscriptionService {
  

  constructor(private http: HttpClient) { }

  public getConferenceInscriptions(userId: number, name: string, statusId: number) {
    if (name) {
      name = encodeURIComponent(name);
    } else {
      name = '';
    }
    return this.http.get<Inscription[]>(`${API_URL}/${userId}/conferences?statusId=${statusId}&name=${name}`);
  }

  public getConferenceInscription(userId: number,conferenceId: number, inscriptionId: number ) {
    return this.http.get<Inscription>(`${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}`);
  }

  public inscription(userId: number, conferenceId: number,conference: Inscription){
    return this.http.post<Inscription>(`${API_URL}/${userId}/conferences/${conferenceId}/inscriptions`, conference);
  }

  public update(userId: number, conferenceId: number, inscriptionId: number ,conference: Inscription) {
    return this.http.put<Inscription>(`${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}`, conference);
  }

  public getChats(userId: number, conferenceId: number, inscriptionId: number) {
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/chats`;
    return this.http.get<ChatMessage[]>(url);
  }

  public saveChat(userId: number, conferenceId: number, inscriptionId: number, chat: ChatMessage){
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/chats`;
    return this.http.post<ChatMessage>(url, chat);
  }

  public getNotes(userId: number, conferenceId: number, inscriptionId: number) {
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/notes`;
    return this.http.get<Note[]>(url);
  }

  public getFiles(userId: number, conferenceId: number, inscriptionId: number) {
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/files`;
    return this.http.get<ConferenceFile[]>(url);
  }

  public saveNote(userId: number, conferenceId: number, inscriptionId: number, note: Note){
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/notes`;
    return this.http.post<Note>(url, note);
  }

  public deleteNote(userId: number, conferenceId: number, inscriptionId: number,  noteId: number){
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/notes/${noteId}`;
    return this.http.delete<Note>(url);
  }

  public sendInvites(userId: number,conferenceId: number, inscriptionId: number,invite: Invite) {
    return this.http.post<Invite>(`${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/invites`, invite);
  }

  public likeConference(userId: number, conferenceId: number , inscriptionId: number,conference: Inscription){
    const url = `${API_URL}/${userId}/conferences/${conferenceId}/inscriptions/${inscriptionId}/like`;
    return this.http.post<Inscription>(url, conference);
  }
}
