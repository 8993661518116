<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title-default" [style.color]="user.mainColor">{{ 'career-path.career-path' | translate }}</h1>
      </div>
      <div class="column is-12 mt-50 pt-0">
        <a class="text-menu-filter text-menu-filter-active">{{ 'general.tab-all' | translate }}</a>
        <a class="text-menu-filter">{{ 'general.tab-not-started' | translate }}</a>
        <a class="text-menu-filter">{{ 'general.tab-started' | translate }}</a>
        <a class="text-menu-filter">{{ 'general.tab-concluded' | translate }}</a>
      </div>
      <div class="column is-12 mt-30">
        <div class="columns is-multiline">
          <div class="column is-4 card-size">
            <div class="new-card-default">
              <div class="card-title">
                <img class="img-card" src="/assets/images/icons/users.svg">
                <div class="info-title">
                  <h1 class="title limit-text-box" title="Gerencie sua empresa, hoje ainda">Gerencie sua empresa, hoje ainda hoje ainda hoje ainda hoje ainda </h1>
                  <div class="classifications">
                    <i class="fas fa-star icon-classification hover-itens icon-classification-active"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <span class="classification">7 {{ 'general.classifications' | translate }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-itens">
                <p class="card-text"><b>{{ 'career-path.description' | translate }}</b> Lorem Ipsum is simply dummy text
                  of the
                  printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen type specimen type specimen type specimen type specimen type
                  specimen type specimen type specimen type specimen
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="true">{{'general.read-less' | translate}}</span>
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="false">{{'general.read-more' | translate}}</span>
                </p>
              </div>
              <div class="btn-card mt-20 text-center">
                <a class="btn-default-big">{{'general.access' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="column is-4 card-size">
            <div class="new-card-default">
              <div class="card-title">
                <img class="img-card" src="/assets/images/icons/users.svg">
                <div class="info-title">
                  <h1 class="title limit-text-box" title="Gerencie sua empresa, hoje ainda">Gerencie sua empresa</h1>
                  <div class="classifications">
                    <i class="fas fa-star icon-classification hover-itens icon-classification-active"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <span class="classification">7 {{ 'general.classifications' | translate }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-itens">
                <p class="card-text"><b>{{ 'career-path.description' | translate }}</b> Lorem Ipsum is simply dummy text
                  of the
                  printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="false">{{'general.read-less' | translate}}</span>
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="true">{{'general.read-more' | translate}}</span>
                </p>
              </div>
              <div class="btn-card mt-20 text-center">
                <a class="btn-default-big">{{'general.access' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="column is-4 card-size">
            <div class="new-card-default">
              <div class="card-title">
                <img class="img-card" src="/assets/images/icons/users.svg">
                <div class="info-title">
                  <h1 class="title limit-text-box" title="Gerencie sua empresa, hoje ainda">Gerencie sua empresa</h1>
                  <div class="classifications">
                    <i class="fas fa-star icon-classification hover-itens icon-classification-active"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <span class="classification">7 {{ 'general.classifications' | translate }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-itens center-vertical">
                <p class="card-text">
                  {{ 'career-path.not-description' | translate }}
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="false">{{'general.read-less' | translate}}</span>
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="false">{{'general.read-more' | translate}}</span>
                </p>
              </div>
              <div class="btn-card mt-20 text-center">
                <a class="btn-default-big">{{'general.access' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="column is-4 card-size">
            <div class="new-card-default">
              <div class="card-title">
                <img class="img-card" src="/assets/images/icons/users.svg">
                <div class="info-title">
                  <h1 class="title limit-text-box" title="Gerencie sua empresa, hoje ainda">Gerencie sua empresa, hoje ainda hoje ainda hoje ainda hoje ainda </h1>
                  <div class="classifications">
                    <i class="fas fa-star icon-classification hover-itens icon-classification-active"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <i class="fas fa-star icon-classification hover-itens"></i>
                    <span class="classification">7 {{ 'general.classifications' | translate }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-itens">
                <p class="card-text"><b>{{ 'career-path.description' | translate }}</b> Lorem Ipsum is simply dummy text
                  of the
                  printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen type specimen type specimen type specimen type specimen type
                  specimen type specimen type specimen type specimen
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="true">{{'general.read-less' | translate}}</span>
                  <span class="itens-read hover-itens m-0 text-right" *ngIf="false">{{'general.read-more' | translate}}</span>
                </p>
              </div>
              <div class="btn-card mt-20 text-center">
                <a class="btn-default-big">{{'general.access' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</section>
