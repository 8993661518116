import { AlertFieldModule } from './../../../../components/alert-field/alert-field.module';
import { ReportComponent } from './report/report.component';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssessmentRoutingModule } from './assessment-routing.module';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { ComponentsModule } from '@app/components/components.module';
import { AlertModule } from '@app/components/alert/alert.module';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { AnswersComponent } from './answers/answers.component';
import { IntroComponent } from './intro/intro.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import {NgxPrintModule} from 'ngx-print';
import { TranslateLoadersConfig } from '@app/utils';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [AssessmentListComponent, AnswersComponent, IntroComponent, ReportComponent],
  imports: [
    CommonModule,
    AssessmentRoutingModule,
    TranslateModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    NgxPrintModule,
    AlertFieldModule,
  ]
})
export class AssessmentModule { }
