<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <app-alert></app-alert>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-2" *ngIf="isSubItens">
        <div class="columns is-multiline">
          <div class="column is-12">
            <app-conference-products [inscriptionId]="inscriptionId" [conferenceId]="conferenceId"
              [products]="conference?.products"></app-conference-products>
          </div>
          <div class="column is-12">
            <app-conference-files [inscriptionId]="inscriptionId" [conferenceId]="conferenceId"
              [customerId]="customerId">
            </app-conference-files>
          </div>
          <div class="column is-12" *ngIf="false">
            <app-conference-notes [inscriptionId]="inscriptionId" [conferenceId]="conferenceId"
              [customerId]="customerId">
            </app-conference-notes>
          </div>
        </div>
      </div>
      <div class="column " [ngClass]="{'is-7': isSubItens,'is-9': !isSubItens }" >
        <app-conference-player (conferenceChildForm)="setConference($event)" 
                   [inscriptionId]="inscriptionId" [conferenceId]="conferenceId" [customerId]="customerId">
        </app-conference-player>
      </div>
      <div class="column is-3">
        <app-conference-chat [conference]="conference">
        </app-conference-chat>
      </div>
    </div>
  </div>
</section>