import { Component, OnInit } from '@angular/core';

import { ModalService } from './modal.service';


@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.sass']
})
export class ModalContainerComponent implements OnInit {

  constructor(private modal: ModalService) { }

  ngOnInit() {
  }

  closeModal(){
    this.modal.destroy();
  }

}
