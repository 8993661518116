import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NotFoundAccountComponent } from './not-found-account/not-found-account.component';
import { BlockedAccountComponent } from './blocked-account/blocked-account.component';
import { PasswordManageComponent } from './password-manage/password-manage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { SignInComponent } from './signin/signin.component';
import { SignUpComponent } from './signup/signup.component';
import { LoginGuard } from '@app/core/auth/login-guard';
import { PasswordRegisterComponent } from './password-manage/password-register/password-register.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard],
    canLoad: [LoginGuard],
    children: [
      {
        path: '',
        component: SignInComponent,
        data: {
          title: 'Login'
        }
      },      
      {
        path: 'redirect-password',
        component: PasswordRegisterComponent
      },
      {
        path: 'signup',
        component: SignUpComponent,
        data: {
          title: 'Cadastre-se'
        }
      },
      {
        path: 'forgot-password',
        component: PasswordManageComponent,
      },
      {
        path: 'loading-page',
        component: LoadingPageComponent
      },
      {
        path: 'blocked-account',
        component: BlockedAccountComponent
      }
      ,
      {
        path: 'not-found-account',
        component: NotFoundAccountComponent
      }
    ]
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {

}
