
import { BaseView } from '@app/views/shared/BaseView';
import { UserContextService } from '@app/core/services/user-context.service';
import { ThemeService } from '@app/core/services/theme.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Order } from '@app/views/user/model/products/order';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidator } from '@app/components/validations/validation.rules';
import { AddressService } from '@app/views/user/service/address.service';

import { County } from '@app/views/user/model/address/county';
import { State } from '@app/views/user/model/address/state';
import { City } from '@app/views/user/model/address/city';

import { UserService } from '@app/views/user/service/user.service';
import { Payer } from '@app/views/user/model/products/payer';
import { Address } from '@app/views/user/model/products/address';
import { VirtualStoreBase } from '../../../virtual-store-base';
import { User } from '@app/views/user/model/user/user';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.sass']
})
export class PayerComponent extends VirtualStoreBase implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  order$: Observable<Order>;
  order: Order;
  orderId: number;
  useDeliveryAddress: boolean;
  address: Address;
  countries: Array<County>;
  states: Array<State>;
  cities: Array<City>;
  updateUser: boolean;
  public userForm: FormGroup;

  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService,
    private userService: UserService,
    private virtualStoreService: VirtualStoreService,
    private activatedRoute: ActivatedRoute,
    private form: FormBuilder,
    private addressService: AddressService,
    private route: Router,
  ) {
    super();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.orderId = this.activatedRoute.snapshot.params['orderId'];
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.userForm = this.form.group({
        payerId: [''],
        fullname: ['', [Validators.required, Validators.maxLength(100)]],
        cpf: [null, [Validators.required, Validators.minLength(11)]],
        email: ['', [Validators.email, Validators.required]],
        cellphone: ['', [Validators.required]],
        birthday: [''],
        receiveNews: [''],
        receivedBy: [''],
        byMe: [''],
        frmtBirthday: ['', [Validators.required]],
        deliveryZipcode: [''],
        deliveryStreet: [''],
        deliveryNumber: [''],
        deliveryComplement: [''],
        deliveryDistrict: [''],
        deliveryCityId: [''],
        deliveryStateId: [''],
        deliveryCountryId: [''],
        useDeliveryAddress: [1],
        billingZipcode: ['', [Validators.required]],
        billingStreet: ['', [Validators.required]],
        billingNumber: ['', [Validators.required]],
        billingComplement: [''],
        billingDistrict: ['', [Validators.required]],
        billingCityId: ['', [Validators.required]],
        billingStateId: ['', [Validators.required]],
        billingCountryId: ['', [Validators.required]],
        creationDate: [''],
        active: ['']
      }, {
        validator: [
          AppValidator.MatchCPF('cpf')
        ]
      });
      this.loadData(user);
    });
  }

  public loadData(user) {

    this.order$ = this.virtualStoreService.getProductOrderById(user.id, this.orderId);
    this.order$.subscribe(order => {

      this.order = order;

      this.userForm.patchValue(order);

      this.userForm.get('payerId').setValue(this.order.payer.payerId);

      this.userForm.get('receivedBy').setValue(this.order.delivery.receivedBy);

      this.loadLocations();
      this.loadPayer(order);
      this.loadDelivery(order);


    });
  }

  public convertFrmtStringToDate() {

    const date = this.userForm.get('frmtBirthday').value;
    if (!date) {
      return null;
    }
    const day = date.toString().substring(0, 2);
    const month = date.toString().substring(2, 4);
    const year = date.toString().substring(4, date.length);
    const newDate = `${day}/${month}/${year}`;
    var patternValidaData = /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/;
    if (!patternValidaData.test(newDate)) {
      this.userForm.get('frmtBirthday').setErrors({ invalidDateFormat: true });
      return;
    }

    return `${year}/${month}/${day}`;
  }


  private loadLocations() {
    this.loadDeliveryCities();
    this.loadDeliveryStates();
    this.loadDeliveryCountries();

    this.loadBillingCities();
    this.loadBillingStates();
    this.loadBillingCountries();
  }

  public loadPayer(order: Order) {
    this.userForm.get('cpf').setValue(order.payer.cpf);
    this.userForm.get('fullname').setValue(order.payer.fullname);
    this.userForm.get('email').setValue(order.payer.email);
    this.userForm.get('cellphone').setValue(order.payer.cellphone);
    this.userForm.get('frmtBirthday').setValue(order.payer.frmtBirthday);
    this.userForm.get('receiveNews').setValue(order.payer.receiveNews);
    this.userForm.get('billingZipcode').setValue(order.payer.billingAddress.zipcode);
    if (!order.payer.billingAddress.zipcode) {
      this.updateUser = true;
    }
    this.userForm.get('billingStreet').setValue(order.payer.billingAddress.street);
    this.userForm.get('billingNumber').setValue(order.payer.billingAddress.number);
    this.userForm.get('billingDistrict').setValue(order.payer.billingAddress.district);
    this.userForm.get('billingCityId').setValue(order.payer.billingAddress.cityId);
    this.loadBillingAddress();
  }


  public loadDelivery(order: Order) {

    this.userForm.get('byMe').setValue(order.delivery.byMe);
    this.userForm.get('deliveryZipcode').setValue(order.delivery.address.zipcode);
    this.userForm.get('deliveryStreet').setValue(order.delivery.address.street);
    this.userForm.get('deliveryComplement').setValue(order.delivery.address.complement);
    this.userForm.get('deliveryDistrict').setValue(order.delivery.address.district);
    this.userForm.get('deliveryNumber').setValue(order.delivery.address.number);
    this.userForm.get('deliveryCityId').setValue(order.delivery.address.cityId);

    this.loadDeliveryCities();
    this.loadDeliveryStates();
    this.loadDeliveryCountries();

    this.loadDeliveryAddress();
  }

  public changeReceivedBy() {
    this.userForm.get('receivedBy').setValue(this.userForm.get('fullname').value);
  }

  public changeDeliveryAddress(useDelivery: boolean) {

    this.useDeliveryAddress = useDelivery

    this.userForm.get('useDeliveryAddress').setValue(useDelivery);

    this.disableValidatorsBillingAdrress();

    if (this.useDeliveryAddress) {

      this.userForm.get('billingZipcode').setValue(this.userForm.get('deliveryZipcode').value);
      this.loadBillingAddress();
      this.userForm.get('billingNumber').setValue(this.userForm.get('deliveryNumber').value);

    } else {
      this.enableValidatorsBillingAdrress();
    }
  }

  private disableValidatorsBillingAdrress() {
    this.disableFieldValidation(this.userForm, 'billingZipcode');
    this.disableFieldValidation(this.userForm, 'billingStreet');
    this.disableFieldValidation(this.userForm, 'billingNumber');
    this.disableFieldValidation(this.userForm, 'billingDistrict');
    this.disableFieldValidation(this.userForm, 'billingCityId');
    this.disableFieldValidation(this.userForm, 'billingStateId');
    this.disableFieldValidation(this.userForm, 'billingCountryId');
  }

  private enableValidatorsBillingAdrress() {
    const validators = Validators.compose([Validators.required]);
    this.enableFieldValidation(this.userForm, 'billingZipcode', validators);
    this.enableFieldValidation(this.userForm, 'billingStreet', validators);
    this.enableFieldValidation(this.userForm, 'billingNumber', validators);
    this.enableFieldValidation(this.userForm, 'billingDistrict', validators);
    this.enableFieldValidation(this.userForm, 'billingCityId', validators);
    this.enableFieldValidation(this.userForm, 'billingStateId', validators);
    this.enableFieldValidation(this.userForm, 'billingCountryId', validators);
  }

  loadBillingAddress() {

    const zipcode = this.userForm.get('billingZipcode').value;
    if (zipcode) {
      this.addressService.getAddressByCEP(zipcode)
        .subscribe(address => {
          this.userForm.get('billingStreet').setValue(address.logradouro);
          this.userForm.get('billingDistrict').setValue(address.bairro);
          this.userForm.get('billingCountryId').setValue(address.countryId);
          this.userForm.get('billingStateId').setValue(address.stateId);
          this.userForm.get('billingCityId').setValue(address.cityId);
          this.loadBillingCountries();
          this.loadBillingStates();
          this.loadBillingCities();
        });
    }
  }

  loadBillingCountries() {
    this.addressService.getCountries()
      .subscribe(countries => {
        this.countries = countries;
      });
  }

  loadBillingStates() {
    const countryId = this.userForm.get('billingCountryId').value;
    if (countryId) {
      this.addressService.getStatesByCountryId(countryId)
        .subscribe(states => {
          this.states = states;
        });
    }
  }

  loadBillingCities() {
    const countryId = this.userForm.get('billingCountryId').value;
    const stateId = this.userForm.get('billingStateId').value;
    if (countryId && stateId) {
      this.addressService.getCitiesByStateId(countryId, stateId)
        .subscribe(cities => {
          this.cities = cities;
        });
    }
  }

  loadDeliveryAddress() {
    const zipcode = this.userForm.get('deliveryZipcode').value;
    if (zipcode) {

      this.addressService.getAddressByCEP(zipcode)
        .subscribe(address => {
          this.userForm.get('deliveryStreet').setValue(address.logradouro);
          this.userForm.get('deliveryDistrict').setValue(address.bairro);
          this.userForm.get('deliveryCountryId').setValue(address.countryId);
          this.userForm.get('deliveryStateId').setValue(address.stateId);
          this.userForm.get('deliveryCityId').setValue(address.cityId);

          this.loadDeliveryCountries();
          this.loadDeliveryStates();
          this.loadDeliveryCities();
        });
    }
  }

  loadDeliveryCountries() {
    this.addressService.getCountries()
      .subscribe(countries => {
        this.countries = countries;
      });
  }

  loadDeliveryStates() {
    const countryId = this.userForm.get('deliveryCountryId').value;
    if (countryId) {
      this.addressService.getStatesByCountryId(countryId)
        .subscribe(states => {
          this.states = states;
        });
    }
  }

  loadDeliveryCities() {
    const countryId = this.userForm.get('deliveryCountryId').value;
    const stateId = this.userForm.get('deliveryStateId').value;
    if (countryId && stateId) {
      this.addressService.getCitiesByStateId(countryId, stateId)
        .subscribe(cities => {
          this.cities = cities;
        });
    }
  }

  public lessItem(index: number) {

    if (this.order.products[index].quantity > 1) {
      this.order.products[index].quantity -= 1;
      this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
        .subscribe(order => {
          this.order = order;
        });
    }
  }

  public moreItem(index: number) {

    this.order.products[index].quantity += 1;
    this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.order = order;
      });
  }


  public redirect() {
    this.route.navigate([`virtual-store/purchases/order/${this.order.id}`]);
  }

  private loadPayerBillingAddress(payer: Payer) {

    if (!payer.billingAddress) {
      payer.billingAddress = new Address();
    }

    payer.billingAddress.zipcode = this.userForm.get('billingZipcode').value;
    payer.billingAddress.street = this.userForm.get('billingStreet').value;
    payer.billingAddress.number = this.userForm.get('billingNumber').value;
    payer.billingAddress.complement = this.userForm.get('billingComplement').value;
    payer.billingAddress.district = this.userForm.get('billingDistrict').value;
    payer.billingAddress.cityId = this.userForm.get('billingCityId').value;
  }

  private loadOrderDelivery() {

    this.order.useDeliveryAddress = this.userForm.get('useDeliveryAddress').value;
    this.order.delivery.receivedBy = this.userForm.get('receivedBy').value;
    this.order.delivery.byMe = this.userForm.get('byMe').value;
    this.order.delivery.address.zipcode = this.userForm.get('deliveryZipcode').value;
    this.order.delivery.address.street = this.userForm.get('deliveryStreet').value;
    this.order.delivery.address.number = this.userForm.get('deliveryNumber').value;
    this.order.delivery.address.complement = this.userForm.get('deliveryComplement').value;
    this.order.delivery.address.district = this.userForm.get('deliveryDistrict').value;
    this.order.delivery.address.cityId = this.userForm.get('deliveryCityId').value;
  }

  public save() {

    if (this.userForm.valid) {

      const payer = this.userForm.getRawValue() as Payer;

      payer.frmtBirthday = this.convertFrmtStringToDate();

      console.log(payer);

      this.order.payer = payer;

      this.loadPayerBillingAddress(this.order.payer);

      this.order.orderStatusId = this.ORDER_RECEIVED;

      this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
        .subscribe(order => {
          this.order = order;
          if (this.updateUser) {

            const user = new User();
            user.id = this.user.id;
            user.cellphone = this.order.payer.cellphone;
            user.birthday = this.order.payer.birthday;
            user.zipcode = this.order.payer.billingAddress.zipcode;
            user.street = this.order.payer.billingAddress.street;
            user.number = this.order.payer.billingAddress.number;
            user.complement = this.order.payer.billingAddress.complement;
            user.district = this.order.payer.billingAddress.district;
            user.cityId = this.order.payer.billingAddress.cityId;

            this.userService.update(this.user.id, user).
              subscribe(data => {
                this.route.navigate([`virtual-store/purchases/payment/${this.order.id}`]);
              }, error => {
                this.route.navigate([`virtual-store/purchases/order`]);
              });
          }else{
            this.route.navigate([`virtual-store/purchases/payment/${this.order.id}`]);
          }
        });
    }
  }
}
