import { Injectable } from "@angular/core";
import { Router } from '@angular/router'
import { Effect, Actions, ofType } from "@ngrx/effects";
import { mergeMap, map, catchError, switchMap, tap } from "rxjs/operators";
import { of, Observable } from "rxjs";

import { AuthService } from '@app/core/services/auth.service';
import * as authActions from '../actions/auth.actions';

@Injectable()
export class AuthEffects {

  constructor(
    private route: Router,
    private actions: Actions,
    private authService: AuthService) { } 

}
