<div class="box-video">
  <div class="video-responsive img-video-conference">
    <div class="columns is-multiline center-vertical width-100" *ngIf="!execution">
      <div class="column is-6">
        <div class="box-titles">
          <h1 class="title-video-conference title-font-48">Bem vindo</h1>
          <h2 class="title-video-conference title-font-36">a Sala Virtual da</h2>
        </div>
      </div>
      <div class="column is-6">
        <img class="img-logo-conference" src="{{getLogo(user.logo)}}">
      </div>
      <div class="column is-12">
        <p class="text-conference">Fique à vontade e em breve iniciaremos a programação.</p>
      </div>
    </div>
    <div class="jitsi-styling jitsi-box-area" id="meet" #meet></div>
  </div>
  <div class="evaluation mt-20">
    <div class="columns is-multiline" style="flex-basis: unset !important">
      <div *ngIf="!conference?.finished" class="column border-right">
        <p>Convidar amigos</p>
        <span><b><i class="fa fa-envelope" style="cursor: pointer;margin-right: 20px;font-size: 15px;" (click)="openShareUsers()" title="Convidar amigos"></i></b>
          <i *ngIf="!conference?.finished" class="fas fa-share-alt"
            style="cursor: pointer;font-size: 15px;" (click)="copy()" ngxClipboard  [cbContent]="getUserLink(conference)" title="Compatilhar link"></i>
        </span>
      </div>
      <div class="column border-right">
        <p>Arquivos para dowloads</p>
        <p><b>
            <i class="fas fa-file-download" style="cursor: pointer;" (click)="openFilesDownload()" title="Baixar arquivos"></i></b></p>
      </div>
      <div class="column border-right" *ngIf="">
        <p>{{ 'conference.transmition-time' | translate }}</p>
        <p><b>{{conference?.transmissionTime}}</b></p>
      </div>
      <div class="column border-right">
        <p>{{ 'conference.watching' | translate }}</p>
        <p><b>{{conference?.onlineUsers}}</b></p>
      </div>
      <div class="column">
        <p>{{ 'conference.feedback' | translate }}</p>
        <i class="far fa-thumbs-down" (click)="likeConference(false)"></i>
        <i class="far fa-thumbs-up" (click)="likeConference(true)"></i>
      </div>
      <div class="column center-vertical" style="justify-content: center" *ngIf="false">
        <a class="btn-to-share">{{ 'conference.share' | translate }} <i class="fas fa-share-alt"></i></a>
      </div>
    </div>
  </div>
</div>