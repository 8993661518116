<div class="box-modal">
  <h1 class="title-alert alert-delete">
    <i class="fas fa-file-download"></i>
    Arquivos para download
    <a class="icon-close" (click)="close()"><i class="fas fa-times"></i></a>
  </h1>
  <div class="padding-itens">
    <p class="title-box-resume"><b>Arquivos da conferência</b> - baixe aqui os arquivos disponibilizados para download </p>
    <div class="columns is-multiline">
      <div class="column is-12 mt-20">
        <p class="alert-inline alert-form-inline-danger mt-10" *nIf="false" style="min-height: 44px !important">
          <i class="fas fa-exclamation-circle"></i>
          {{ 'mod.trail-alert-position' | translate }}
        </p>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12"  *ngIf="(files$ | async) as files; else noFile"> 
        <div class="box-default-register file-box column is-2" *ngFor="let file of files" (click)="downloadPdf(file)">
          <p class="sub-title-register">{{file.title}}</p>
          <div class="form-download">
            <i class="fas fa-file-download icon-download" title='Clique aqui para baixar o arquivo'></i>        
          </div>
        </div>        
      </div>   
      <ng-template #noFile>
        <h1 class='no-files'>Nenhum arquivo cadastrado!</h1>
      </ng-template>
    </div>
    <div class="box-btn">
      <a (click)="close()" class="btn-alert-cancel">{{ 'general.cancel' | translate }}</a>
      
    </div>
  </div>
  <footer class="footer"></footer>
</div>