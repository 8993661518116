
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


import portugueseLocale from '@angular/common/locales/pt';
import spanishLocale from '@angular/common/locales/es';
import englishLocale from '@angular/common/locales/es-US';
import { CredentialsService } from './credentials.service';
import { Language } from '../models/language.model';



@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public availableLanguages: Language[] = [
    { userLanguageId: null, userLanguageInitials: 'pt', code: 'pt', label: 'Português', id: 56, atsLocationId: 1 },
    { userLanguageId: null, userLanguageInitials: 'es', code: 'es', label: 'Español', id: 25, atsLocationId: 2 },
    { userLanguageId: null, userLanguageInitials: 'en', code: 'en', label: 'English', id: 38, atsLocationId: 2 }
  ];

  public activeLanguage: Language = this.availableLanguages[0];

  public applicantId: number;

  constructor(private translate: TranslateService,
    private credentialsService: CredentialsService
  ) { }

  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }

  get locale(): string {
    return this._locale || 'pt';
  }


  setLocale() {
    this.registerLocale(this.getSessionLanguage());
  }

  getSessionLanguage() {
    return JSON.parse(sessionStorage.getItem('lang')) || this.activeLanguage;
  }

  getLanguageId() {
    return this.activeLanguage.id;
  }

  updateLocale(language: Language) {
    this.registerLocale(language);
    window.location.reload();
  }

  registerLocale(language: Language) {
    if (!language) {
      return;
    }

    this.locale = language.code;

    switch (language.code) {
      case 'pt':
      default:
        registerLocaleData(portugueseLocale);
        break;

      case 'es':
        registerLocaleData(spanishLocale);
        break;

      case 'en':
        registerLocaleData(englishLocale);
        break;
    }
    sessionStorage.setItem('lang', JSON.stringify(language));
    this.translate.use(language.code);
  }
}
