import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Credentials } from '../models/credentials';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoverService {

  credentials: Credentials;
  initialToken = false;

  constructor(
    private http: HttpClient,
    private session: SessionService) {
    this.credentials =
      JSON.parse(this.session.getSessionStorage('credentials')) || undefined;
  }

  find(recoverPassForm) {
    return this.http.get<any>(`${environment.baseUrl}/v0/password-recovery-token/${recoverPassForm['token']}`, {});
  }

  create(recoverPassForm) {
    return this.http.post<any>(`${environment.baseUrl}/v0/password-recovery-token`, recoverPassForm);
  }

  update(recoverPassForm) {
    return this.http.put<any>(`${environment.baseUrl}/v0/password-recovery-token`, recoverPassForm);
  }

}
