import { ConferenceFile } from "./conference-file";
import { ConferenceProduct } from "./conference-product";

export class Inscription {

  inscriptionId: number
  userId: number;
  urlImage:string;
  inscriptionStatusId: number;
  conferenceId: number;
  description: string;
  name: string;
  conferenceStatusId: number;
  transmissionTime: string;
  totalSubscribers: number;
  onlineUsers: number;
  like: boolean;
  date: Date;
  free:boolean;
  conferenceLink:string;
  frmtDate:string;
  startTime:string;    
  endTime: string;
  products: Array<ConferenceProduct>;
  files : Array<ConferenceFile>;
  finished: boolean
}
