<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default" [style.color]="user.mainColor">{{ 'conference.title-conference' |
          translate }}</h1>

      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control has-icons-left has-icons-right">
            <input class="input input-search" type="text" placeholder="{{ 'placeholder.general' | translate }}"
              [(ngModel)]="searchTerms">
            <span class="icon is-small is-right show-icon-search" style="height: 3.7em">
              <img src="/assets/images/icons/lupa.png" (click)="loadItens(STATUS_ALL)">
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <a class="text-menu-filter" (click)="loadItens(STATUS_ALL)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_ALL}">
          <span>{{ 'conference.tab-all' | translate }}</span>

        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_NOT_INITIATED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_NOT_INITIATED}">
          <span>{{ 'conference.tab-not-started' |translate }}</span>

        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_INITIATES)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_INITIATES}">
          <span>{{ 'conference.tab-started' | translate}}</span>

        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_COMPLETED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_COMPLETED}">
          <span>{{ 'conference.tab-concluded' |translate }}</span>

        </a>
      </div>

      <div class="column is-12 mt-30">
        <div class="columns is-multiline">
          <div class="cards">
            <div class="card" *ngFor="let conference of conferences">
              <picture class="card__picture">
                <img class="card__imagem" [src]="getImage(conference.urlImage)">
              </picture>
              <div class="card__info">
                <h2 class="card__title">
                  {{conference.name}}
                </h2>
                <p class="card__description" [innerHTML]="limitDescription(conference.description, 150) | safeHtml">
                </p>
                <p class="card__description" *ngIf="!conference.description">{{ 'conference.not-description | translate
                  }}</p>
              </div>
              <div class="card__buttons">
                <a class="card__button" *ngIf="conference.inscriptionStatusId != 3"
                  (click)="redirect(conference.conferenceId,conference.inscriptionId)">{{'conference.realize'
                  | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <p class="info-default-list" *ngIf="(!conferences || conferences?.length == 0)">
          {{ 'conference.none-conference' |translate }}
        </p>
      </div>
    </div>
  </div>
</section>