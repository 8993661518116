import { BaseView } from '@app/views/shared/BaseView';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@app/components/alert/alert.service';
import { EmailNotTakenValidatorService } from '@app/components/validations/email-not-taken.validator';
import { AppValidator } from '@app/components/validations/validation.rules';
import { Credentials } from '@app/core/models/credentials';
import { AuthService } from '@app/core/services/auth.service';
import { CustomerPreference } from '@app/views/account/model/customer-preference';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConferenceUserInviteService } from '@app/views/user/service/conference-user-invite.service';
import { UserPunctuationService } from '@app/views/user/service/user-punctuartion.service';
import { UserInvite } from '@app/views/user/model/conference/user-invite';
import { CredentialsService } from '@app/core/services/credentials.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignUpComponent extends BaseView implements OnInit {


  registerForm: FormGroup;
  error: object;
  user: any;
  customerPreference$: Observable<CustomerPreference>;
  customerPreference: CustomerPreference;
  constructor(
    private form: FormBuilder,
    private auth: AuthService,
    private route: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private emailNotTakenValidatorService: EmailNotTakenValidatorService,
    private conferenceUserInviteService: ConferenceUserInviteService,
    private customerPreferenceService: CustomerPreferenceService,
    private credentialsService: CredentialsService,
    private userPunctuationService: UserPunctuationService) {
    super();
  }

  ngOnInit() {

    this.customerPreference$ = this.customerPreferenceService.getPreference();
    this.customerPreference$.subscribe(preference => {
      this.customerPreference = preference;
    });

    this.registerForm = this.form.group(
      {
        fullName: [null, [Validators.required, Validators.minLength(3)]],
        email: [
          null,
          [Validators.required, Validators.email],
          this.emailNotTakenValidatorService.checkUserEmailTaken(),
        ],
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(6),
            //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([a-zA-Z0-9$@$!%*?&]{6,})')
          ],
        ],
        passwordConfirmation: [
          null,
          [
            Validators.required,
            Validators.minLength(6),
            // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([a-zA-Z0-9$@$!%*?&]{6,})')
          ],
        ],
      },
      {
        validator: [
          AppValidator.MatchPassword("password", "passwordConfirmation"),
          AppValidator.MatchSurname("fullName"),
        ],
      }
    );
  }


  public save() {

    if (this.registerForm.valid) {

      const credentials = this.registerForm.getRawValue() as Credentials;

      credentials.groupId = this.customerPreference.groupId;
      credentials.customerId = this.customerPreference.customerId;
      console.log(credentials);
      this.auth.save(credentials).subscribe(
        data => {
          this.user = data;
          credentials.login = this.user.email;
          this.auth.login(credentials)
            .subscribe(
              data => {
                this.onLoginSuccess();
                this.registerForm.reset();
              },
            );
        },
        error => this.onRegisterFail(error)
      );
    }
  }

  onRegisterFail(errorResponse) {
    this.error = errorResponse.error;
    this.alertService.danger(this.translate.instant('register.register-error'));
  }

  private onLoginSuccess() {
    if (!this.customerPreference || !this.customerPreference.redirect) {
      this.route.navigate(['/dashboard']);
    } else {
      const redirect = this.customerPreference.redirect;
      if (redirect == 'redirect-assessment') {
        if (this.customerPreference.refValue) {
          this.customerPreferenceService.deleteCustomerPreference();
          this.route.navigate([`assessments/${this.customerPreference.refValue}/intro/0`]);
        }
      } else if (redirect == 'redirect-training') {
        this.customerPreferenceService.deleteCustomerPreference();
        this.route.navigate([`trainings/${this.customerPreference.refValue}/type/1/training-home/0/${this.customerPreference.publisher}`]);
      } else if (redirect == 'redirect-conference') {
        if (this.customerPreference.invite) {
          this.route.navigate(['/dashboard']);
          this.conferenceUserInviteService.getUserInvite(0, this.customerPreference.refValue, this.customerPreference.invite)
            .subscribe(invite => {
              invite.newRegistration = true;
              this.conferenceUserInviteService.updateUserInvite(0, this.customerPreference.refValue, this.customerPreference.invite, invite)
                .subscribe(data => {
                  this.userPunctuationService.savePunctuation(data.userId, this.user.customerId, 8, this.customerPreference.refValue, 100)
                    .subscribe(result => {
                      this.customerPreferenceService.deleteCustomerPreference();
                      this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
                    });
                });
            });
        } else if (this.customerPreference.senderId) {
          const userInvite = new UserInvite()
          userInvite.conferenceId = this.customerPreference.refValue;
          this.credentialsService.getUserCredentials()
            .subscribe((user) => {
              userInvite.userId = this.customerPreference.senderId;
              userInvite.guestName = user.fullName;
              userInvite.guestEmail = user.email;
              userInvite.newRegistration = true;
              userInvite.alreadyRegistered = false;
              this.conferenceUserInviteService.saveUserInvite(0, this.customerPreference.refValue, userInvite).subscribe(data => {
                this.customerPreferenceService.deleteCustomerPreference();
                this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
              });
            });

        } else {
          this.customerPreferenceService.deleteCustomerPreference();
          this.route.navigate([`conferences/${this.customerPreference.refValue}/inscriptions/0`]);
        }
      } else if (redirect == 'redirect-product') {
        this.customerPreferenceService.deleteCustomerPreference();
        this.route.navigate([`/virtual-store`]);
      }
    }

  }

}
