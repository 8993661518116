import { Component, OnInit, Input } from '@angular/core';
import { Alert, AlertType } from './alert';
import { AlertService } from './alert.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {


  @Input() timeout = 3000;
  alerts: Alert[] = [];

  constructor(private alertService: AlertService) {

    this.alertService
      .getAlert()
      .subscribe(alert => {
        if (!alert) {
          this.alerts = [];
          return;
        }
        this.alerts.push(alert);
        setTimeout(() => {
          this.removeAlert(alert);
        }, this.timeout);
      });

  }

  removeAlert(alertToRemove: Alert) {
    this.alerts = this.alerts.filter(alert => alert != alertToRemove);
  }

  getAlertClass(alert: Alert) {

    if (!alert) {
      return '';
    }

    switch (alert.alertType) {

      case AlertType.DANGER:
        return 'alert-inline alert-inline-danger';
      case AlertType.INFO:
        return 'alert-inline alert-inline-info';
      case AlertType.SUCCESS:
        return 'alert-inline alert-inline-success';
      case AlertType.WARNING:
        return 'alert-inline alert-inline-warning';

    }
  }
  ngOnInit() {
  }
}
