  <div class="box-modal">
    <h1 class="title-alert alert-delete">
      <i class="fas fa-share-alt"></i>
      Convite para amigos
      <a class="icon-close" (click)="close()"><i class="fas fa-times"></i></a>
    </h1>
    <div class="padding-itens">
      <p class="title-box-resume"><b>Convidar amigos</b> - adicione os e-mails que serão convidados para participar da conferência.<b>Somente 20 usuários por envio</b></p>

      <div class="columns is-multiline">
        <div class="column is-12 mt-20">
          <p class="alert-inline alert-form-inline-danger mt-10" *nIf="false" style="min-height: 44px !important">
            <i class="fas fa-exclamation-circle"></i>
            {{ 'mod.trail-alert-position' | translate }}
          </p>
        </div>

        <div class="column is-12">
          <div class="toolbar" *ngIf="!showFormEmail">
            <a class="" (click)="showFormEmail = true"><i class="fas fa-plus"></i><span>Adicionar amigo</span></a>
          </div>
          <div class="box-default-register box-register" *ngIf="showFormEmail">
            <p class="sub-title-register">Adicionar amigo</p>
            <p class="text-question">Nome:</p>
            <input class="input" [ngClass]="{'input-error': !name && submit}"
              [(ngModel)]="name" maxlength="100">
            <p class="text-question">E-mail:</p>
            <input class="input" [ngClass]="{'input-error': !email && submit}"
              [(ngModel)]="email" maxlength="100"
              (blur)="validateEmail()"
              (focus)="clearEmail()">
            <span class="sub-title-info">
              {{email ? email.length:0}} / 100
            </span>
            <app-alert-field alertType=3 alertTextTranslate="share.invalid-format-email"
                 *ngIf="errorEmail">
                </app-alert-field>
            <div class="form-file-actions">
              <a class="btn-alert-cancel" (click)="cancel()" >Fechar</a>
              <a class="btn-alert-confirm" (click)="addEmail()" >Adicionar</a>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline">
        <div class="column is-12 ">
          <p class="text-questions"></p>
        </div>
        <div class="column is-12 text-left mb-30">
          <p class="text-default-register">Lista de e-mails:</p>
          <ul class="emails-list">
            <li *ngFor="let guest of guests; let i = index">{{guest.name}} ({{guest.email}})
              <i class="fas fa-times"  style="margin-left: 5px;" (click)="deleteEmail(i)"></i></li>
          </ul>
          <app-alert-field alertType=3 alertTextTranslate="share.emails-required"
          *ngIf="guests.length == 0 && submit">
         </app-alert-field>
          <p class="text-default-register">Envie uma mensagem para o(s) usuário(s):</p>
          <textarea class="input rows-area" [(ngModel)]="message"></textarea>
          <span class="sub-title-info">
            {{message ? message.length:0}}
          </span>
        </div>
      </div>
      <div class="box-btn">
        <a (click)="close()" class="btn-alert-cancel">{{ 'general.cancel' | translate }}</a>
        <a (click)="sendInvite()" class="btn-alert-confirm">Enviar</a>
       </div>

    </div>
    <footer class="footer"></footer>
  </div>
