import { Component, Input,  OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseView } from '@app/views/shared/BaseView';
import { ConferenceProduct } from '@app/views/user/model/conference/conference-product';

@Component({
  selector: 'app-conference-products',
  templateUrl: './conference-products.component.html',
  styleUrls: ['./conference-products.component.sass']
})
export class ConferenceProductsComponent extends BaseView implements OnInit {


  @Input() inscriptionId: number;
  @Input() conferenceId: number;

  @Input() products: Array<ConferenceProduct>;

  constructor(private route: Router) { 
    super();
  }

  ngOnInit() {
    console.log(this.products);
  }

  public redirectToModule(product: ConferenceProduct) {
    let url = ``;
    if (product.moduleId == 1) {
      url += `assessments/${product.moduleItemId}/intro/0`;
    } else if (product.moduleId == 8) {
      url += `conferences/${product.moduleItemId}/inscriptions/0`;
    } else if (product.moduleId == 10) {
      url += `trainings/${product.moduleItemId}/type/1/training-home/0/false`;
    } else if (product.moduleId == 11) {
      url += `trails/${product.moduleItemId}/type/2/training-home/0/false`;
    }
    this.route.navigate([url]);
  }
  
  public redirectToPurchase(product: ConferenceProduct) {
    this.route.navigate([`virtual-store/purchases/products/${product.productId}`]);
  }

}
