<form #form='ngForm' [formGroup]="userForm" (submit)="save()">

  <app-alert></app-alert>
  <div class="columns">
    <div class="column is-12 center-margin-auto">
      <div class="box-profile">

        <div class="columns is-multiline">
          <div class="column is-12 text-center">
            <img *ngIf="false">
            <h1 class="photo-name center-vertical" [style.background]="user.mainColor" *ngIf="!preview">
              {{user.nameInitials}}
              <img class="img-camera" src="assets/images/icons/camera.svg" alt="" title="Alterar imagem"
                (click)="fileInput.click()">
            </h1>
            <div class="box-img-profile" *ngIf="preview">
              <img src="{{preview}}" title="Preview" class="nav-img-user" width="150" height="100" />
              <img class="img-camera" src="assets/images/icons/camera.svg" alt="" title="Alterar imagem"
                (click)="fileInput.click()">
            </div>
            <input #fileInput hidden formControlName="document" type="file" accept="img/*"
              (change)="handleFile(file = $event.target.files[0])">

            <p class="text-photo">{{ 'profile.photo' | translate }}</p>
          </div>
          <div class="column is-12 mt-50">
            <h1 class="title-profile m-0">{{ 'profile.data' | translate }}</h1>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.document-number' | translate }}</p>
            <input type="text" class="input column is-5"
              [ngClass]="{'input-error':userForm.get('cpf').errors?.required && form.submitted }" maxlength="14"
              mask="000.000.000-00" formControlName="cpf" ngModel>
            <app-alert-field alertType=2 alertTextTranslate="profile.cpf-minlength"
              *ngIf="(userForm.get('cpf').errors?.minlength) ">
            </app-alert-field>
            <app-alert-field alertType=2 alertTextTranslate="profile.cpf-invalid"
              *ngIf="(userForm.get('cpf').errors?.cpfInvalid && (form.submitted || userForm.get('cpf').touched)) ">
            </app-alert-field>
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('cpf').errors?.required && (form.submitted || userForm.get('cpf').touched)) ">
            </app-alert-field>

          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.name' | translate }}</p>
            <input type="text" class="input"
              [ngClass]="{'input-error':userForm.get('fullname').errors?.required && form.submitted }" maxlength="100"
              formControlName="fullname">
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('fullname').errors?.required && (form.submitted || userForm.get('fullname').touched))">
            </app-alert-field>

          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.birthdate' | translate }}</p>
            <input type="input" class="input column is-6" mask="00/00/0000" (blur)="convertFrmtStringToDate()"
              [ngClass]="{'input-error':userForm.get('frmtBirthday').errors?.invalidDateFomat}" maxlength="100"
              formControlName="frmtBirthday">
            <app-alert-field alertType=3 alertTextTranslate="general.invalid-date"
              *ngIf="(userForm.get('frmtBirthday').errors?.invalidDateFormat && (form.submitted || userForm.get('frmtBirthday').touched))">
            </app-alert-field>
          </div>
          <div class="column is-6">
            <p class="text-default-register">{{ 'profile.cellphone' | translate }}
              <span>-</span> <span style="color: #66CC79;">{{'general.optional' | translate }}</span>
            </p>
            <input type="text" class="input" maxlength="100" mask="(00)00000-0000" formControlName="cellphone" />
          </div>
          <div class="column is-12 mt-50">
            <h1 class="title-profile m-0">{{ 'profile.address' | translate }}</h1>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.zip-code' | translate }}</p>
            <input type="text" class="input column is-5" maxlength="9" mask="00000-000"
              [ngClass]="{'input-error':userForm.get('zipcode').errors?.required && form.submitted }"
              (blur)="loadAddress()" formControlName="zipcode" ngModel pattern='(?!(\d)\1{7})\d{8}'>
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('zipcode').errors?.required && (form.submitted || userForm.get('zipcode').touched))">
            </app-alert-field>
          </div>
          <div class="column is-10 mt-10">
            <p class="text-info-profile">{{ 'profile.public-place' | translate }}</p>
            <input type="text" class="input"
              [ngClass]="{'input-error':userForm.get('street').errors?.required && form.submitted }" maxlength="100"
              formControlName="street">
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('street').errors?.required && (form.submitted || userForm.get('street').touched))">
            </app-alert-field>
          </div>
          <div class="column is-2 mt-10">
            <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.number' |
              translate}}:</p>
            <input class="input" [ngClass]="{'input-error':userForm.get('number').errors?.required && form.submitted }"
              maxlength="5" formControlName="number">
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('number').errors?.required && (form.submitted || userForm.get('number').touched))">
            </app-alert-field>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.complement' | translate }}</p>
            <input type="text" class="input" maxlength="100" formControlName="complement">
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.district' | translate }}</p>
            <input type="text" class="input"
              [ngClass]="{'input-error':userForm.get('district').errors?.required && form.submitted }" maxlength="100"
              formControlName="district">
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('district').errors?.required && (form.submitted || userForm.get('district').touched))">
            </app-alert-field>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.country' | translate }}</p>
            <select class="select"
              [ngClass]="{'input-error':userForm.get('countryId').errors?.required && form.submitted }"
              (change)="loadStates()" formControlName="countryId">
              <option value="">{{ 'profile.select' | translate }}</option>
              <option *ngFor="let country of countries" [ngValue]="country.id">{{country.name}}</option>
            </select>
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('countryId').errors?.required && (form.submitted || userForm.get('countryId').touched))">
            </app-alert-field>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.state' | translate }}</p>
            <select class="select"
              [ngClass]="{'input-error':userForm.get('stateId').errors?.required && form.submitted }"
              formControlName="stateId" (change)="loadCities()">
              <option value="">{{ 'profile.select' | translate }}</option>
              <option *ngFor="let state of states" [ngValue]="state.id">{{state.name}}</option>
            </select>
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('stateId').errors?.required && (form.submitted || userForm.get('stateId').touched))">
            </app-alert-field>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.city' | translate }}</p>
            <select class="select"
              [ngClass]="{'input-error':userForm.get('cityId').errors?.required && form.submitted }"
              formControlName="cityId">
              <option value="">{{ 'profile.select' | translate }}</option>
              <option *ngFor="let city of cities" [ngValue]="city.id">{{city.name}}</option>
            </select>
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('cityId').errors?.required && (form.submitted || userForm.get('cityId').touched))">
            </app-alert-field>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-12 mt-50">
            <h1 class="title-profile m-0">{{ 'profile.access-data' | translate }}</h1>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.login' | translate }}</p>
            <input type="email" class="input"
              [ngClass]="{'input-error':userForm.get('login').errors?.required && form.submitted }" maxlength="100"
              formControlName="login" disabled>
          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.new-password' | translate }}</p>
            <input type="password" class="input" maxlength="100" formControlName="password"
              (blur)="validPassword(userForm)">
            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="(userForm.get('password').errors?.required && (form.submitted || userForm.get('password').touched))">
            </app-alert-field>

          </div>
          <div class="column is-12 mt-10">
            <p class="text-info-profile">{{ 'profile.new-password-confirm' | translate }}</p>
            <input type="password" class="input" maxlength="100" formControlName="confirmPassword">
            <app-alert-field alertType=3 alertTextTranslate="profile.diferent-password-confirm"
              *ngIf="(userForm.get('confirmPassword').errors?.matchPassword && userForm.get('confirmPassword').touched)">
            </app-alert-field>

          </div>
          <div class="column is-12 mt-20 mb-50 text-center">
            <button class="btn-save-profile" type="submit">{{ 'profile.save' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>