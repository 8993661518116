import { Action } from "@ngrx/store";

export enum RefdataActionTypes {
  RF_GRADTYPE_REQUEST = '[Ref Data] Graduation Type Request',
  RF_GRADTYPE_SUCCESS = '[Ref Data] Graduation Type Success',
  RF_EDUCATIONTYPE_REQUEST = '[Ref Data] Education Type Request',
  RF_EDUCATIONTYPE_SUCCESS = '[Ref Data] Education Type Success',
  INSTITUTION_REQUEST = '[Ref Data] Institution Name Request',
  INSTITUTION_SUCCESS = '[Ref Data] Institution Name Success',
  GRADUATION_COURSE_REQUEST = '[Ref Data] Graduation Course Request',
  GRADUATION_COURSE_SUCCESS = '[Ref Data] Graduation Course Success',
  COUNTRIES_REQUEST = '[Ref Data] Countries Request',
  COUNTRIES_SUCCESS = '[Ref Data] Countries Success',
  LANGUAGES_REQUEST = '[Ref Data] Languages Request',
  LAGUAGES_SUCCESS = '[Ref Data] Languages Success',
  TECH_SKILLS_REQUEST = '[Ref Data] Technical Skills Request',
  TECH_SKILLS_SUCCESS = '[Ref Data] Technical Skills Success',
  BIZ_SECTOR_REQUEST = '[Ref Data] Business Sector Request',
  BIZ_SECTOR_SUCCESS = '[Ref Data] Business Sector Success',
  REFDATA_FAIL = '[Auth] Auth Error',
}

export class RFGraduationTypeRequest implements Action {
  readonly type = RefdataActionTypes.RF_GRADTYPE_REQUEST
  constructor(public payload: any) { }
}

export class RFGraduationTypeSuccess implements Action {
  readonly type = RefdataActionTypes.RF_GRADTYPE_SUCCESS
  constructor(public payload: any) { }
}

export class RFEducationTypeRequest implements Action {
  readonly type = RefdataActionTypes.RF_EDUCATIONTYPE_REQUEST
  constructor(public payload: any) { }
}

export class RFEducationTypeSuccess implements Action {
  readonly type = RefdataActionTypes.RF_EDUCATIONTYPE_SUCCESS
  constructor(public payload: any) { }
}

export class InstitutionNameRequest implements Action {
  readonly type = RefdataActionTypes.INSTITUTION_REQUEST
  constructor(public payload: any) { }
}

export class InstitutionNameSuccess implements Action {
  readonly type = RefdataActionTypes.INSTITUTION_SUCCESS
  constructor(public payload: any) { }
}

export class GraduationCourseRequest implements Action {
  readonly type = RefdataActionTypes.GRADUATION_COURSE_REQUEST
  constructor(public payload: any) { }
}

export class GraduationCourseSuccess implements Action {
  readonly type = RefdataActionTypes.GRADUATION_COURSE_SUCCESS
  constructor(public payload: any) { }
}

export class CountriesRequest implements Action {
  readonly type = RefdataActionTypes.COUNTRIES_REQUEST
  constructor(public payload: any) { }
}

export class CountriesSuccess implements Action {
  readonly type = RefdataActionTypes.COUNTRIES_SUCCESS
  constructor(public payload: any) { }
}

export class LanguageRequest implements Action {
  readonly type = RefdataActionTypes.LANGUAGES_REQUEST
  constructor(public payload: any) { }
}

export class LanguageSuccess implements Action {
  readonly type = RefdataActionTypes.LAGUAGES_SUCCESS
  constructor(public payload: any) { }
}

export class TechSkillsRequest implements Action {
  readonly type = RefdataActionTypes.TECH_SKILLS_REQUEST
  constructor(public payload: any) { }
}

export class TechSkillsSuccess implements Action {
  readonly type = RefdataActionTypes.TECH_SKILLS_SUCCESS
  constructor(public payload: any) { }
}

export class BusinessSectorRequest implements Action {
  readonly type = RefdataActionTypes.BIZ_SECTOR_REQUEST
  constructor(public payload: any) { }
}

export class BusinessSectorSuccess implements Action {
  readonly type = RefdataActionTypes.BIZ_SECTOR_SUCCESS
  constructor(public payload: any) { }
}

export class RefDataFail implements Action {
  readonly type = RefdataActionTypes.REFDATA_FAIL
  constructor(public payload: any) { }
}

export type RefDataActions = 
  RFGraduationTypeRequest | 
  RFGraduationTypeSuccess | 
  RFEducationTypeRequest  | 
  RFEducationTypeSuccess  |
  InstitutionNameRequest  |
  InstitutionNameSuccess  |
  GraduationCourseRequest |
  GraduationCourseSuccess |
  CountriesRequest        |
  CountriesSuccess        |
  LanguageRequest         |
  LanguageSuccess         |
  TechSkillsRequest       |
  TechSkillsSuccess       |
  BusinessSectorRequest   |
  BusinessSectorSuccess   |
  RefDataFail 