<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}" *ngIf="(user$ | async) as user">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default" [style.color]="user.mainColor">{{ 'profile.my-profile' | translate }}</h1>
      </div>
    </div>
    <app-alert></app-alert>
    <div class="columns is-multiline">
      <div class="column is-2">
        <div class="columns is-multiline">
          <div class="column is-12">
            <a class="center-vertical m-0 hover-itens" routerLink="/order-tracking">
              <img *ngIf="showRouter == '/my-purchases'" src="assets/images/icons/requests-active.svg">
              <img *ngIf="showRouter != '/my-purchases'" src="assets/images/icons/sacola.svg">
              <span class="text-default-card m-0 pl-10" [ngClass]="{'color-active' : showRouter == '/my-purchases' }">{{'my-account.purchases' | translate}}</span>
            </a>
          </div>
          <div class="column is-12">
            <a class="center-vertical m-0 hover-itens" routerLink="/profile">
              <img *ngIf="showRouter == '/my-profile'" src="assets/images/icons/register-active.svg">
              <img *ngIf="showRouter != '/my-profile'" src="assets/images/icons/register.svg">
              <span class="text-default-card m-0 pl-10" [ngClass]="{'color-active' : showRouter == '/my-profile' }">{{'my-account.register' | translate}}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="column is-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
