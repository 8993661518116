import { Action } from "@ngrx/store";
import { Theme } from "@app/core/models/theme.model";

export enum ThemeActionsTypes {
  THEME_LOAD = '[Theme] Theme Load',
  THEME_SUCCESS = '[Theme] Theme Success',
  THEME_FAIL = '[Theme] Theme Fail',
  THEME_SET = '[Theme] Theme set',
}

export class ThemeLoad implements Action {
  readonly type = ThemeActionsTypes.THEME_LOAD
  constructor(public payload: any) {
    
  }
}

export class ThemeSuccess implements Action {
  readonly type = ThemeActionsTypes.THEME_SUCCESS
  constructor(public payload: any) {
    
  }
}

export class ThemeFail implements Action {
  readonly type = ThemeActionsTypes.THEME_FAIL
  constructor(public payload: any) { }
}

export class ThemeSet implements Action {
  readonly type = ThemeActionsTypes.THEME_SET
  constructor(public payload: any) { }
}

export type ThemeActions = ThemeLoad | ThemeSuccess | ThemeFail | ThemeSet