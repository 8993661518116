import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';
import { PasswordRecoverService } from '../services/password-recover.service';

@Injectable()
export class RecoverPasswordGuard implements CanActivate {
  constructor(private router: Router,
    private recoverPasswordService: PasswordRecoverService,
    private formBuilder: FormBuilder) {}

    recoverPassForm = this.formBuilder.group({
      token: ['', [Validators.required]]
    });

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(!next.params || !next.params.token) {
            this.router.navigate(['/']);
            return of(false);
        }

        this.recoverPassForm.controls['token'].setValue(next.params.token);
        return this.recoverPasswordService.find(this.recoverPassForm.value).pipe(
          map((response: any): any => {
          return true;
        }),
        catchError( (err: any): any => {
          this.router.navigate(['/']);
          return of(false);
        }));
  }
}
