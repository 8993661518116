import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal-container/modal.service';



@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.sass']
})
export class ErrorComponent implements OnInit {

  data: any

  constructor(private modal: ModalService) { }

  ngOnInit() {
    this.data = this.modal.content
  }

}
