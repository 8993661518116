import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/components/alert/alert.service';
import { ModalService } from '@app/components/modal-container/modal.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { BaseView } from '@app/views/shared/BaseView';
import { Guest } from '@app/views/user/model/conference/guest';
import { Inscription } from '@app/views/user/model/conference/inscription';
import { Invite } from '@app/views/user/model/conference/invite';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { TranslateService } from '@ngx-translate/core';

declare var tinymce: any;
@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.sass']
})
export class InviteUsersComponent extends BaseView implements OnInit {


  showFormEmail: boolean = false;
  submit = false;
  errorEmail: boolean;
  name: string;
  email: string;
  guests = new Array<Guest>();
  message: string;
  inscription: Inscription;
  public initEditorValues = {
    height: 200,
    menubar: false,
    skin: 'oxide',
    content_css: 'default',
    plugins: [
      'advlist autolink lists link  charmap print ',
      'preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media table paste',
      'help wordcount charmap',
      'image'
    ],
    toolbar:
      'code | undo redo | formatselect | bold italic | forecolor backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent |  charmap ',
    image_advtab: true,
  };

  constructor(private modal: ModalService,
    private translate: TranslateService,
    private conferenceInscriptionService: ConferenceInscriptionService,
    private credentialsService: CredentialsService,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

  }


  clearEmail() {
    this.errorEmail = false;
  }

  cancel() {
    this.showFormEmail = false
    this.email = null;
    this.errorEmail = false;

  }

  addEmail() {
    if (this.email && !this.errorEmail) {
      const guest = new Guest();
      guest.name = this.name;
      guest.email = this.email;
      this.guests.push(guest);
      this.name = null;
      this.email = null;
      this.showFormEmail = false;
    }
  }

  deleteEmail(index) {
    this.guests.splice(index, 1);
  }

  validateEmail() {
    this.errorEmail = false;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(this.email);
    if (!valid) {
      this.errorEmail = true;
    }
  }

  sendInvite() {
    this.submit = true;
    this.inscription = this.modal.ref;
    const invite = new Invite();
    invite.conferenceId = this.inscription.conferenceId;
    invite.guests = this.guests;

    if (invite.guests.length == 0) {
      this.alertService.danger('Nenhum amigo adicionado');
      return;
    } else if (invite.guests.length > 20) {
      this.alertService.danger('Adcione no máximo 20 amigos por envio');
      return;
    }

    invite.message = this.message;
    invite.conferenceLink = this.inscription.conferenceLink;
    invite.conferenceName = this.inscription.name;
    invite.frmtDate = this.inscription.frmtDate;
    invite.frmtStartHour = this.inscription.startTime;
    invite.frmtEndHour = this.inscription.endTime;
    this.credentialsService.getUserCredentials().subscribe(user => {
      invite.subDomain = user.subDomainName;
      invite.invitedBy = user.fullName;
      invite.userId = user.id;
      this.showFormEmail = false;
      this.conferenceInscriptionService.sendInvites(user.id, this.inscription.conferenceId, this.inscription.inscriptionId, invite)
      .subscribe(data => {
        this.alertService.success(this.translate.instant('mod.invite-send-success'));
        this.modal.destroy();
      }, error => this.alertService.danger(this.translate.instant('mod.invite-send-error')));
    });
  }

  close() {
    this.modal.destroy();
  }



}
