import { State } from '../model/address/state';
import { Address } from '../model/address/address';
import { City } from '../model/address/city';
import { County } from '../model/address/county';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const API_URL = `${environment.baseUrl}/v1/address`;

@Injectable({
  providedIn: 'root'
})
export class AddressService {


  constructor(private http: HttpClient) { }

  public getCountries() {
    return this.http.get<County[]>(`${API_URL}/countries`);
  }

  public getStatesByCountryId(countryId: number) {
    return this.http.get<State[]>(`${API_URL}/countries/${countryId}/states`);
  }

  public getCitiesByStateId(countryId: number, stateId: number) {
    return this.http.get<City[]>(`${API_URL}/countries/${countryId}/states/${stateId}/cities`);
  }

  public getAddressByCEP(cep: string) {
    return this.http.get<Address>(`${API_URL}/cep/${cep}`);
  }

}
