import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserInvite } from '../model/conference/user-invite';

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class ConferenceUserInviteService {

  constructor(private http: HttpClient) { }

  public getUserInvite(userId: number, conferenceId: number, invitId: number) {
    return this.http.get<UserInvite>(`${API_URL}/${userId}/conferences/${conferenceId}/invites/${invitId}`);
  }

  public saveUserInvite(userId: number, conferenceId: number, userInvite:UserInvite) {
    return this.http.post<UserInvite>(`${API_URL}/${userId}/conferences/${conferenceId}/invites`, userInvite);
  }

  public updateUserInvite(userId: number, conferenceId: number, invitId: number, userInvite:UserInvite){
    return this.http.put<UserInvite>(`${API_URL}/${userId}/conferences/${conferenceId}/invites/${invitId}`,userInvite);
  }

}
