import { Injectable } from '@angular/core';

import { DomService } from './dom.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {


  constructor(private domService: DomService) { }

  public content: any;
  public divSize: any;
  public terminfo: any;
  public ref: any;
  public infoTemp: any;
  public idsSeleceted: Array<number>;
  private modalElementId = 'modal-container';
  private overlayElementId = 'overlay';

  private params = new Array();

  private data: Array<any>;

  public message: string;

  public myCallback: () => void;


  public openConfirm(component: any, message: string, callback: any, inputs?: any, sizeDiv?: any, outputs?: object) {
    this.myCallback = callback;
    this.message = message;
    this.open(component, inputs, sizeDiv, outputs);
  }


  public openParam(component: any, ref: any, inputs?: any, outputs?: object, sizeDiv?: string) {
    this.ref = ref;
    this.open(component, inputs, sizeDiv, outputs);
  }

  public open(component: any, inputs?: any, sizeDiv?: any, outputs?: object) {
    this.domService.removeComponent();
    const componentConfig = {
      inputs: inputs,
      outputs: outputs
    }
    this.content = inputs;
    this.divSize = sizeDiv;
    if (componentConfig.inputs.mode && componentConfig.inputs.mode === 'sidebar') {
      this.modalElementId = 'sidenav-container';
    }
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'show';
    document.getElementById(this.overlayElementId).className = 'show';
  }

  public openModalAlert(component: any, inputs?: any, valueDate?: any, outputs?: object) {
    this.domService.removeComponent();
    const componentConfig = {
      inputs: inputs,
      outputs: outputs
    };
    this.infoTemp = valueDate;
    this.content = inputs;
    if (componentConfig.inputs.mode && componentConfig.inputs.mode === 'sidebar') {
      this.modalElementId = 'sidenav-conresetParamstainer';
    }
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'show';
    document.getElementById(this.overlayElementId).className = 'show';
  }

  public destroy() {
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'hidden';
    document.getElementById(this.overlayElementId).className = 'hidden';
  }

  public getRef(): number {
    return this.ref;
  }
  public getSizeDiv(): number {
    return this.divSize;
  }

  public showTerm(event) {
    this.terminfo = event;
  }

  public addObject(obj: any) {
    if (!this.data) {
      this.data = [];
    }
    this.data.push(obj);
  }

  public cleanData() {
    this.data = [];
    this.idsSeleceted = [];
  }

  public getObjects() {
    return this.data;
  }
  public deleteObject(idRemove: number) {
    for (let i = 0; i < this.data.length; i++) {
      const id = this.data[i].id;
      if (idRemove == id) {
        this.data.splice(i, 1);
        break;
      }
    }
  }

  public deleteProfileTrainingObject(idRemove: number) {
    for (let i = 0; i < this.data.length; i++) {
      const id = this.data[i].trainingId;
      if (idRemove == id) {
        this.data.splice(i, 1);
        break;
      }
    }
  }

  public addId(id: number) {
    if (!this.idsSeleceted) {
      this.idsSeleceted = [];
    }
    this.idsSeleceted.push(id);
  }

  public getIdsSelected() {
    return this.idsSeleceted;
  }

  public addParam(key, value) {
    this.params.push({
      key: key,
      value: value
    })
  }

  public getParam(query) {
    const array = this.params.filter(function (el) {
      return el.key.toLowerCase().indexOf(query.toLowerCase()) > -1;
    })
    return array[0]['value'];
  }

  public resetParams() {
    this.params = [];
  }
}
