<section class="section-test"  *ngIf="(assessment$ | async) as assessment">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title-test">{{ assessment?.assessmentName }}</h1>
      </div>
      <div class="column is-6" style="margin: 0 auto;" [innerHTML]="assessment?.initialGuidelines | safeHtml">                
      </div>      
      <div class="column is-12 mt-50">
        <a class="btn-cancel mr-10" (click)="cancel()" >{{ 'general.cancel' | translate }}</a>
        <a class="btn-open" (click)="redirect()" [style.background-color]="user.mainColor">{{ 'home-test.do-test' | translate }}</a>
      </div>
    </div>
  </div>
</section>
