import { BaseView } from '@app/views/shared/BaseView';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Note } from '@app/views/user/model/learning/note';
import { switchMap, tap } from 'rxjs/operators';
import { AlertService } from '@app/components/alert/alert.service';
import { Inscription } from '../../model/conference/inscription';
import { UserContextService } from '@app/core/services/user-context.service';
import { ConferenceChatComponent } from './conference-chat/conference-chat.component';
import { ConferencePlayerComponent } from './conference-player/conference-player.component';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';


@Component({
  selector: 'app-conference-home',
  templateUrl: './conference-home.component.html',
  styleUrls: ['./conference-home.component.sass']
})
export class ConferenceHomeComponent extends BaseView implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  conferenceId: number;
  conferenceName: string;
  inscriptionId: number;
  customerId: number;
  conference: Inscription;
  isSubItens: boolean;
  @ViewChild(ConferenceChatComponent) chatComponent: ConferenceChatComponent;
  @ViewChild(ConferencePlayerComponent) conferenceComponent: ConferencePlayerComponent;

  constructor(
    public themeService: ThemeService,
    public conferenceInscriptionService: ConferenceInscriptionService,
    private credentialsService: CredentialsService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private userContextService: UserContextService,
    private customerPreferenceService:CustomerPreferenceService
  ) {
    super();
  }

  ngOnInit() {

    this.inscriptionId = this.activatedRoute.snapshot.params['inscriptionId'];
    this.conferenceId = this.activatedRoute.snapshot.params['conferenceId'];
    this.customerPreferenceService.deleteCustomerPreference();
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.userContextService.inExecution(this.userContextService.CONFERENCE);
      this.user = user;

      if (this.inscriptionId == 0) {
        const matriculation = new Inscription();
        matriculation.conferenceId = this.conferenceId;
        this.conferenceInscriptionService.inscription(this.user.id, this.conferenceId, matriculation)
          .subscribe(data => {
            this.loadData();
          }, error => {
            this.route.navigate([`conferences`]);
          });
      } else {
        this.loadData();
      }

    });
  }

  public loadData() {

    this.conferenceInscriptionService.getConferenceInscription(this.user.id, this.conferenceId, this.inscriptionId)
      .subscribe(conference => {
        this.conference = conference;
        this.loadChat(conference);
        this.loadConference(conference)       
      });
  }

  setConference(conference) {
    if (conference.conferenceStatusId == 2) {
      this.chatComponent.connect();
    }
  }

  loadConference(conference) {
    this.conferenceComponent.conference = conference;
    this.conferenceComponent.loadData(conference.conferenceId);
  }

  loadChat(conference) {
    this.chatComponent.conference = conference;
    this.chatComponent.loadChat(this.customerId, this.inscriptionId, conference.conferenceId);
  }
}
