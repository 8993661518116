import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Credentials } from "@app/core/models/credentials";
import { CredentialsService } from "@app/core/services/credentials.service";
import { ThemeService } from "@app/core/services/theme.service";
import { BaseView } from "@app/views/shared/BaseView";
import { Assessment } from "@app/views/user/model/assessment/assessment";
import { Matriculation } from "@app/views/user/model/learning/matriculation";
import { UserAssessmentService } from "@app/views/user/service/user-assessments.service";
import { UserPunctuationService } from "@app/views/user/service/user-punctuartion.service";
import { Observable } from "rxjs";
import { MatriculationService } from "../../../service/matriculation.service";

@Component({
  selector: 'app-training-home',
  templateUrl: './training-home.component.html',
  styleUrls: ['./training-home.component.sass']
})
export class TrainingHomeComponent extends BaseView implements OnInit {
  
  publisher: boolean;
  trainingId: number;
  matriculationId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  training: Matriculation;
  training$: Observable<Matriculation>;
  public typeId: number;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private matriculationService: MatriculationService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private userAssessmentService: UserAssessmentService,
    private userPunctuationService: UserPunctuationService

  ) { 
    super();
  }

  ngOnInit(): void {
    this.typeId = this.activatedRoute.snapshot.params['typeId'];
    this.trainingId = this.activatedRoute.snapshot.params['trainingId'];
    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];
    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];
    this.publisher =  this.activatedRoute.snapshot.params['publisher'];
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      if (this.matriculationId == 0) {
        const matriculation = new Matriculation();
        matriculation.trainingId = this.trainingId;
        matriculation.publisher = this.publisher;
        this.matriculationService.matriculation(this.user.id, this.trainingId, matriculation)
          .subscribe(data => {
            this.matriculationId = data.matriculationId;
            this.training$ = this.matriculationService
              .getTrainingMatriculation(this.user.id, this.trainingId, data.matriculationId);
            this.training$.subscribe(training => {
              this.training = training;                          
            });
          }, error => {
            if (this.typeId == 1) {
              this.route.navigate([`trainings/type/1`]);
            } else {
              this.route.navigate([`trails/type/2`]);
            }
          });
      } else {
        this.training$ = this.matriculationService.getTrainingMatriculation(this.user.id, this.trainingId, this.matriculationId);
        this.training$.subscribe(training => {       
          this.training = training;
          console.log(this.training);     
          if(training.matriculationStatusId == 3){            
            this.userPunctuationService.savePunctuation(user.id, user.customerId,(this.typeId == 1 ?  10 : 11), training.trainingId, (this.typeId == 1 ?  200 : 70))
            .subscribe(result => {          
              console.log('FINALIZOU');
            });
          }    
        });
      }
    });

  }

  redirectAssessment() {
    const assessment = new Assessment();
    assessment.assessmentId = this.training.recommendedAssessmentId;

    this.userAssessmentService.matriculate(this.user.id, this.training.recommendedAssessmentId, assessment).
      subscribe(data => {
        this.route.navigate([`assessments/${data.assessmentId}/intro/${data.matriculationId}`]);
      })

  }

  redirect(matriculation: Matriculation, matriculationId: number,moduleId: number, toolId: number, released: boolean, index: number) {
    if (index == 0 || released) {
      if (matriculation.trainingTypeId == 1) {
        this.route.navigate([`trainings/${matriculation.trainingId}/type/${matriculation.trainingTypeId}/training-tool/${matriculationId}/module/${moduleId}/tool/${toolId}`]);
      } else {
        this.route.navigate([`trails/${matriculation.trainingId}/type/${matriculation.trainingTypeId}/trail-tool/${matriculationId}/module/${moduleId}/tool/${toolId}`]);
      }
    }
  }  

  public redirectCertified(trainingTypeId: number, trainingId: number, matriculationTrainingId: number){
    this.route.navigate([`trails/${trainingId}/type/${trainingTypeId}/trail-certified/${matriculationTrainingId}`]);
  }

}
