import { Question } from "./question";

export class Assessment {

  assessmentId: number;
  urlImage:string;
  assessmentName: string;
  description: string;
  initialGuidelines:string;
  introMessage: string;
  assessmentStatusId: number;
  assessmentTypeId: number;
  fmtFinishDate: string;
  recommendedTrainingId: number;
  userId: number;
  customerId: number;
  matriculationId: number;
  questions: Array<Question>;
}
