import { Product } from './../model/products/product';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "@env/environment";
import { Order } from '../model/products/order';

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class VirtualStoreService {

  constructor(private http: HttpClient) { }

  public getProducts(userId: number, customerId: number, name: string, moduleId:number, purchased: boolean) {
    if (name) {
      name = encodeURIComponent(name);
    } else {
      name = '';
    }
    return this.http.get<Product[]>(`${API_URL}/${userId}/customer/${customerId}/products?purchased=${purchased}&name=${name}&moduleId=${moduleId}`);
  }

  public getProduct(userId: number, customerId: number, productId: number) {

    return this.http.get<Product>(`${API_URL}/${userId}/customer/${customerId}/products/${productId}`);
  }

  public createProductsOrder(userId: number,  order: Order) {
    return this.http.post<Order>(`${API_URL}/${userId}/orders`, order);
  }

  public updateProductsOrder(userId: number, orderId: number, order: Order) {
    return this.http.put<Order>(`${API_URL}/${userId}/orders/${orderId}`, order);
  }

  public getProductOrderById(userId: number, orderId: number) {
    return this.http.get<Order>(`${API_URL}/${userId}/orders/${orderId}`);
  }

  public getProductOrders(userId: number, orderStatus: number, name: string) {
    return this.http.get<Order[]>(`${API_URL}/${userId}/orders/trackings?statusId=${orderStatus}&name=${name}`);
  }

  public getProductOrder(userId: number, orderStatus: number) {
    return this.http.get<Order>(`${API_URL}/${userId}/orders?statusId=${orderStatus}`);
  }

  public deleteProductsOrder(userId: number, orderId: number) {
    return this.http.get<Order[]>(`${API_URL}/${userId}/orders/${orderId}/products`);
  }

  public createInvoice(userId: number, orderId: number,order: Order) {
    return this.http.post<Order>(`${API_URL}/${userId}/orders/${orderId}/invoices`, order);
  }

}
