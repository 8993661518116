<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container white-area-mobile">
    <div class="columns is-multiline">
      <div class="column is-12 pb-0">
        <h1 class="title-default mb-50" [style.color]="user.mainColor">{{'products-information.information' |
          translate}}</h1>
      </div>
      <form class="column is-8" #form='ngForm' [formGroup]="userForm" (submit)="save()">
        <div class="box-default p-50">
          <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-information.information' |
            translate}}</h1>
          <div class="columns is-multiline mt-20">
            <div class="column is-12">
              <p class="text-default-questions" [style.color]="user.mainColor">{{'general.fullname' | translate}}:</p>
              <input class="input"
                [ngClass]="{'input-error':userForm.get('fullname').errors?.required && form.submitted }" maxlength="100"
                formControlName="fullname">
              <app-alert-field alertType=3 alertTextTranslate="general.required"
                *ngIf="(userForm.get('fullname').errors?.required && (form.submitted || userForm.get('fullname').touched))">
              </app-alert-field>
            </div>
            <div class="column is-6">
              <p class="text-default-questions" [style.color]="user.mainColor">{{'general.document-number' |
                translate}}:</p>
              <input class="input" [ngClass]="{'input-error': userForm.get('cpf').errors?.required && form.submitted }"
                maxlength="14" mask="000.000.000-00" formControlName="cpf" ngModel>
              <app-alert-field alertType=2 alertTextTranslate="profile.cpf-minlength"
                *ngIf="(userForm.get('cpf').errors?.minlength) ">
              </app-alert-field>
              <app-alert-field alertType=2 alertTextTranslate="profile.cpf-invalid"
                *ngIf="(userForm.get('cpf').errors?.cpfInvalid && (form.submitted || userForm.get('cpf').touched)) ">
              </app-alert-field>
              <app-alert-field alertType=3 alertTextTranslate="general.required"
                *ngIf="(userForm.get('cpf').errors?.required && (form.submitted || userForm.get('cpf').touched)) ">
              </app-alert-field>
            </div>
            <div class="column is-6">
              <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.birthdate' |
                translate}}:</p>
              <input class="input" type="input" mask="00/00/0000" (blur)="convertFrmtStringToDate()"
                [ngClass]="{'input-error':userForm.get('frmtBirthday').errors?.invalidDateFormat }" maxlength="100"
                formControlName="frmtBirthday">
              <app-alert-field alertType=3 alertTextTranslate="general.invalid-date"
                *ngIf="(userForm.get('frmtBirthday').errors?.invalidDateFormat)">
              </app-alert-field>
            </div>
            <div class="column is-12 mt-50">
              <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-information.contact' |
                translate}}</h1>
            </div>
            <div class="column is-12">
              <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.email' |
                translate}}:</p>
              <input class="input" formControlName="email" maxlength="100"
                [ngClass]="{'input-error': userForm.get('email').errors?.required  && form.submitted }">
              <app-alert-field alertType=2 alertTextTranslate="general.email-format" *ngIf="userForm.get('email').errors?.email
              && (form.submitted || userForm.get('email').touched)"></app-alert-field>
              <app-alert-field alertType=3 alertTextTranslate="general.required"
                *ngIf="(userForm.get('email').errors?.required && (form.submitted || userForm.get('email').touched))">
              </app-alert-field>
            </div>
            <div class="column is-6">
              <p class="text-default-questions" [style.color]="user.mainColor">{{ 'profile.cellphone' | translate }}:
              </p>
              <input class="input" mask="(00)00000-0000"
                [ngClass]="{'input-error':userForm.get('cellphone').errors?.required && form.submitted }"
                maxlength="100" formControlName="cellphone">
              <app-alert-field alertType=3 alertTextTranslate="share.required-field" *ngIf="userForm.get('cellphone').errors?.required
                  && (form.submitted || userForm.get('cellphone').touched)"></app-alert-field>
            </div>
            <div class="column is-12">
              <div class="field">
                <div class="center-vertical">
                  <input type="checkbox" class="checkbox m-0" value="None" id="alertCheck" name="check"
                    formControlName="receiveNews" />
                  <label for="alertCheck" class="text-default-card m-0">{{'products-information.receive-news' |
                    translate}}</label>
                </div>
              </div>
            </div>
            <div *ngIf="">
              <div class="column is-12 mt-50">
                <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-information.delivery-address'
                  | translate}}</h1>
              </div>
              <div class="column is-10">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.received-by' |
                  translate}}</p>
                <input class="input" formControlName="receivedBy"
                  [ngClass]="{'input-error':userForm.get('receivedBy').errors?.required && form.submitted }">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('receivedBy').errors?.required && (form.submitted || userForm.get('receivedBy').touched))">
                </app-alert-field>
              </div>
              <div class="column is-2 center-vertical">
                <div class="field mt-10">
                  <div class="center-vertical">
                    <input type="checkbox" class="checkbox m-0" id="byMe" name="check" formControlName="byMe"
                      (click)="changeReceivedBy()" />
                    <label for="mim" class="text-default-card m-0">{{'products-information.by-me' | translate}}</label>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.zip-code' |
                  translate}}:</p>
                <input class="input" maxlength="9" mask="00000-000"
                  [ngClass]="{'input-error':userForm.get('deliveryZipcode').errors?.required && form.submitted }"
                  (blur)="loadDeliveryAddress()" formControlName="deliveryZipcode">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryZipcode').errors?.required && (form.submitted || userForm.get('deliveryZipcode').touched))">
                </app-alert-field>
              </div>
              <div class="column is-10">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.adress' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error':userForm.get('deliveryStreet').errors?.required && form.submitted }"
                  maxlength="100" formControlName="deliveryStreet">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryStreet').errors?.required && (form.submitted || userForm.get('deliveryStreet').touched))">
                </app-alert-field>
              </div>
              <div class="column is-2">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.number' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error':userForm.get('deliveryNumber').errors?.required && form.submitted }"
                  maxlength="5" formControlName="deliveryNumber">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryNumber').errors?.required && (form.submitted || userForm.get('deliveryNumber').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.complement' |
                  translate}}:</p>
                <input class="input" maxlength="100" formControlName="deliveryComplement">
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.district' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error':userForm.get('deliveryDistrict').errors?.required && form.submitted }"
                  maxlength="100" formControlName="deliveryDistrict">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryDistrict').errors?.required && (form.submitted || userForm.get('deliveryDistrict').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.country' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('deliveryCountryId').errors?.required && form.submitted }"
                  (change)="loadDeliveryStates()" formControlName="deliveryCountryId">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let country of countries" [ngValue]="country.id">{{country.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryCountryId').errors?.required && (form.submitted || userForm.get('deliveryCountryId').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.state' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('deliveryStateId').errors?.required && form.submitted }"
                  formControlName="deliveryStateId" (change)="loadDeliveryCities()">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let state of states" [ngValue]="state.id">{{state.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryStateId').errors?.required && (form.submitted || userForm.get('deliveryStateId').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.city' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('deliveryCityId').errors?.required && form.submitted }"
                  formControlName="deliveryCityId">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let city of cities" [ngValue]="city.id">{{city.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('deliveryCityId').errors?.required && (form.submitted || userForm.get('deliveryCityId').touched))">
                </app-alert-field>
              </div>
            </div>
            <div class="column is-12 mt-50">
              <h1 class="title-default2 m-0" [style.color]="user.mainColor">{{'products-information.billing-address' |
                translate}}</h1>
            </div>

            <div class="column is-12" *ngIf="">
              <div class="control">
                <div>
                  <label class="radio text-left text-default-black mb-10">
                    <input type="radio" [value]="true" name="useDeliveryAddress" (click)="changeDeliveryAddress(true)"
                      formControlName="useDeliveryAddress">
                    <span [style.color]="user.mainColor"></span>
                    {{'products-information.use-delivery-address' | translate}}
                  </label>
                </div>
                <div class="is-inactive">
                  <label class="radio text-left text-default-black mb-10">
                    <input type="radio" [value]="false" name="useDeliveryAddress" (click)="changeDeliveryAddress(false)"
                      formControlName="useDeliveryAddress">
                    <span></span>
                    {{'products-information.use-different-billing-address' | translate}}
                  </label>
                </div>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('useDeliveryAddress').errors?.required && (form.submitted || userForm.get('useDeliveryAddress').touched))">
                </app-alert-field>
              </div>
            </div>


            <div class="columns is-multiline" style="padding: 10px;">
              <div class="column is-6">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.zip-code' |
                  translate}}:</p>
                <input class="input" maxlength="9" mask="00000-000"
                  [ngClass]="{'input-error':userForm.get('billingZipcode').errors?.required && form.submitted }"
                  (blur)="loadBillingAddress()" formControlName="billingZipcode">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingZipcode').errors?.required && (form.submitted || userForm.get('billingZipcode').touched))">
                </app-alert-field>
              </div>
              <div class="column is-9">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.adress' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error': userForm.get('billingStreet').errors?.required && form.submitted }"
                  maxlength="100" formControlName="billingStreet">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingStreet').errors?.required && (form.submitted || userForm.get('billingStreet').touched))">
                </app-alert-field>
              </div>
              <div class="column is-2">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.number' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error':userForm.get('billingNumber').errors?.required && form.submitted }"
                  maxlength="5" formControlName="billingNumber">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingNumber').errors?.required && (form.submitted || userForm.get('billingNumber').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.complement' |
                  translate}}:</p>
                <input class="input" maxlength="100" formControlName="billingComplement">
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.district' |
                  translate}}:</p>
                <input class="input"
                  [ngClass]="{'input-error':userForm.get('billingDistrict').errors?.required && form.submitted }"
                  maxlength="100" formControlName="billingDistrict">
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingDistrict').errors?.required && (form.submitted || userForm.get('billingDistrict').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.country' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('billingCountryId').errors?.required && form.submitted }"
                  (change)="loadBillingStates()" formControlName="billingCountryId">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let country of countries" [ngValue]="country.id">{{country.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingCountryId').errors?.required && (form.submitted || userForm.get('billingCountryId').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.state' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('billingStateId').errors?.required && form.submitted }"
                  formControlName="billingStateId" (change)="loadBillingCities()">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let state of states" [ngValue]="state.id">{{state.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingStateId').errors?.required && (form.submitted || userForm.get('billingStateId').touched))">
                </app-alert-field>
              </div>
              <div class="column is-12">
                <p class="text-default-questions" [style.color]="user.mainColor">{{'products-information.city' |
                  translate}}:</p>
                <select class="select"
                  [ngClass]="{'input-error':userForm.get('billingCityId').errors?.required && form.submitted }"
                  formControlName="billingCityId">
                  <option value="">{{ 'profile.select' | translate }}</option>
                  <option *ngFor="let city of cities" [ngValue]="city.id">{{city.name}}</option>
                </select>
                <app-alert-field alertType=3 alertTextTranslate="general.required"
                  *ngIf="(userForm.get('billingCityId').errors?.required && (form.submitted || userForm.get('billingCityId').touched))">
                </app-alert-field>
              </div>
            </div>


            <div class="column is-12 mt-50">
              <div class="columns">
                <div class="column">
                  <a class="link-default hover-itens" style="font-size: 16px;" (click)="redirect()">
                    {{'products-information.back-to-cart' | translate}}</a>
                </div>
                <div class="column text-right">
                  <button class="btn-default-confirmed" type="submit">
                    {{'general.continue' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="column is-4" *ngIf="order?.products?.length > 0">
        <div class="box-default">
          <div class="colums is-multiline">

            <div class="column is-12" *ngFor="let product of order?.products; let i = index">
              <div class="columns is-multiline">
                <div class="pr-10 center-img-responsive">
                  <img class="img-info product-img-item" [src]="getProductImage(product.urlImage)">
                </div>
                <div class="">
                  <p class="text-default-black m-0">{{product.name}}</p>
                  <p class="text-default-black m-0 mt-10" style="font-size: 22px;"><b>{{product.fmtPrice}}</b></p>
                </div>
                <div class="column is-12 center-vertical" style="justify-content: center;">
                  <span class="text-default-black m-0 text-quantity">{{ 'store.quantity' | translate }}</span>
                  <span class="sub-pagination hover-itens" (click)="lessItem(i)">-</span>
                  <input class="input quantity-pagination" type="text" value="{{product.quantity}}">
                  <span class="sub-pagination hover-itens" (click)="moreItem(i)">+</span>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.sub-total' | translate}}
                      <span *ngIf="order.totalItens > 1">({{order.totalItens}} itens)</span>
                      <span *ngIf="order.totalItens  == 1">({{order.totalItens}} item)</span>
                    </b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtSubTotal}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.discount' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtTotalDiscounted}}</b></p>
                </div>
              </div>
            </div>
            <div class="column is-12">
              <hr class="m-0">
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <div class="column">
                  <p class="text-default-card m-0"><b>{{'products-basket.amount' | translate}}</b></p>
                </div>
                <div class="column">
                  <p class="text-default-card text-right m-0"><b>{{order.fmtTotal}}</b></p>
                  <p class="text-default-card text-right m-0" style="font-size: 14px;">{{'products-basket.in-until' |
                    translate}} <b> {{order.totalInstallments}}x</b> {{'general.of' | translate}}
                    <b>{{order.fmtInstallmentValue}} </b> {{'products-basket.interest-free' | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
