import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials } from '@app/core/models/credentials';
import { UserContext } from '@app/core/models/user-context';
import { CredentialsService } from '@app/core/services/credentials.service';
import { UserContextService } from '@app/core/services/user-context.service';
import { Assessment } from '@app/views/user/model/assessment/assessment';
import { UserAssessmentService } from '@app/views/user/service/user-assessments.service';
import { UserPunctuationService } from '@app/views/user/service/user-punctuartion.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-intro',
  encapsulation: ViewEncapsulation.None, 
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.sass'],
})
export class IntroComponent implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  matriculationId: number;
  assessmentId: number;
  assessment: Assessment;
  assessment$: Observable<Assessment>;
  userContext: UserContext;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userAssessmentService: UserAssessmentService,
    private credentialsService: CredentialsService,
    private route: Router,
    private userContextService: UserContextService,
    private userPunctuationService: UserPunctuationService
  ) { }

  ngOnInit(): void {
    this.matriculationId = this.activatedRoute.snapshot.params['matriculationId'];
    this.assessmentId = this.activatedRoute.snapshot.params['assessmentId'];
    this.userContextService.userSource.subscribe(userContext => {
      this.userContext = userContext;
    });
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      if (this.matriculationId == 0) {
        const assesment = new Assessment();
        assesment.assessmentId = this.assessmentId;
        assesment.userId = this.user.id;
        this.userAssessmentService.matriculate(this.user.id, this.assessmentId, assesment)
          .subscribe(matriculation => {
            this.matriculationId = matriculation.matriculationId;
            this.loadData();
            this.userPunctuationService.savePunctuation(this.user.id, this.user.customerId,1, this.assessmentId, 10)
            .subscribe(result => {          
              console.log('INICIOU O ASSESSMENT - PONTOS');
            });

          },error =>{
            this.route.navigate([`/assessments`]);
          });
      } else {
        this.loadData();
      }
    });
  }


  public loadData() {
    this.assessment$ = this.userAssessmentService.getAssessment(this.user.id, this.assessmentId, this.matriculationId);

    this.assessment$.subscribe(assessment => {        
      if(assessment.assessmentStatusId == 1){
        assessment.assessmentStatusId = 2;
      }
      this.userAssessmentService.update(this.user.id, this.assessmentId,this.matriculationId, assessment)
        .subscribe(updateAssessment =>{
          this.userContextService.inExecution(this.userContextService.ASSESSMENT);
          this.assessment = updateAssessment;        
          this.assessmentId = updateAssessment.assessmentId;
          this.matriculationId = updateAssessment.matriculationId;
      });        
    });
  }

  public cancel() {
    this.route.navigate([`/assessments`]);
    this.userContextService.stopExecution();
  }

  public redirect() {
    this.route.navigate([`assessments/${this.assessmentId}/answers/${this.matriculationId}`]);
  }

}
