<div *ngIf="(files$ | async) as files">
  <h1 class="title-default text-left" style="font-size: 14px; color: #999; font-weight: bold">Arquivos para download
  </h1>
  <div class="box-alerts">
    <div class="alerts" *ngFor="let file of files; let i = index"> 
      <a href="{{getResourceUri(file.url)}}" target="_blank">
        <p class="text-alerts">
          <i class="far fa-file-pdf dowload-file"></i>{{file.title}}
        </p>
      </a>
    </div>
  </div>
</div>