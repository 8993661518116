import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CredentialsService } from '@app/core/services/credentials.service';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { BaseView } from '@app/views/shared/BaseView';
import { Inscription } from '../../model/conference/inscription';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';



@Component({
  selector: 'app-conference-list',
  templateUrl: './conference-list.component.html',
  styleUrls: ['./conference-list.component.sass']
})
export class ConferenceListComponent extends BaseView implements OnInit {

  inscriptionId: number;
  conferenceId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  searchTerms: string;
  changeTab: number = 0;
  public show: boolean = false;
  public typeId: number;
  conferences: Array<Inscription>;
  constructor(
    public themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    private route: Router,
    public conferenceInscriptionService: ConferenceInscriptionService,
    private customerPreferenceService:CustomerPreferenceService
  ) {
    super();
  }

  ngOnInit(): void {

    this.inscriptionId = this.activatedRoute.snapshot.params['inscriptionId'];
    this.conferenceId = this.activatedRoute.snapshot.params['conferenceId'];
    
    this.customerPreferenceService.deleteCustomerPreference();

    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      const inscription = new Inscription();
      inscription.userId = this.user.id;
      inscription.conferenceId = this.conferenceId;
      if (this.inscriptionId == 0) {
        this.conferenceInscriptionService.inscription(this.user.id, this.conferenceId, inscription)
          .subscribe(data=>{
            this.loadItens(this.STATUS_ALL);
          });
      } else {
        this.loadItens(this.STATUS_ALL);
      }
    });

  }

  public loadItens(statusId: number) {

    this.changeTab = statusId;
    if (this.user && this.user.id) {
      this.conferenceInscriptionService.getConferenceInscriptions(this.user.id, this.searchTerms, statusId)
        .subscribe(conferences => {
          this.conferences = conferences;
        });
    }
  }

  public redirect(conferenceId: number, inscriptionId: number) {
    this.route.navigate([`conferences/${conferenceId}/conference-home/${inscriptionId}`]);
  }

  public redirectCertified(conferenceTypeId: number, conferenceId: number, matriculationConferenceId: number) {
    this.route.navigate([`trails/${conferenceId}/type/${conferenceTypeId}/trail-certified/${matriculationConferenceId}`]);
  }

}
