import { Delivery } from './delivery';
import { Invoice } from './invoice';
import { OrderItem } from './order-item';
import { Payer } from './payer';
import { Plots } from './plots';
import { Tracking } from './tracking';

export class Order {

  id: number;
  userId: number;
  orderStatusId: number;

  paymentTypeId: number;

  paymentDate: Date;
	fmtPaymentDate: string;
	receivedDate: Date;
  fmtReceivedDate: string;
	onCarriageDate: Date;
	deliveredDate: Date;
	availableInvoiceDate: Date;

  subTotal: number;

  fmtInstallmentValue: string;
  fmtTotalDiscounted: string;
  fmtTotal: string;
  fmtSubTotal: string;

  total: number;
  totalDiscounted: number;
  totalInstallments: number;
	interestFrom: number;
  totalItens: number;
  useDeliveryAddress: boolean;
  tokenCreditCard: string;
  quantityPlots: number;
  payer: Payer;
  invoice: Invoice;
  delivery: Delivery;
  products: Array<OrderItem>;
  plots: Array<Plots>;
  openDetails: boolean;
}
