import { Action } from "@ngrx/store";

export enum AuthActionTypes {
  AUTH_REQUEST = '[Auth] Auth Request',
  AUTH_FAIL = '[Auth] Auth Error',
  AUTH_SUCCESS = '[Auth] Auth Success',
  USER_REQUEST = '[User] User Request',
  USER_FAIL = '[User] User Error',
  USER_SUCCESS = '[User] User Success',
}

export class AuthLoad implements Action {
  readonly type = AuthActionTypes.AUTH_REQUEST;
  constructor(public payload: any) { }
}

export class AuthFail implements Action {
  readonly type = AuthActionTypes.AUTH_FAIL;
  constructor(public payload: any) { }
}

export class AuthSuccess implements Action {
  readonly type = AuthActionTypes.AUTH_SUCCESS;
  constructor(public payload: any) { }
}

export class UserRequest implements Action {
  readonly type = AuthActionTypes.USER_REQUEST;
  constructor(public payload: any) { }
}

export class UserFail implements Action {
  readonly type = AuthActionTypes.USER_FAIL;
  constructor(public payload: any) { }
}

export class UserSuccess implements Action {
  readonly type = AuthActionTypes.USER_SUCCESS;
  constructor(public payload: any) { }
}

export type AuthActions = AuthLoad | AuthFail | AuthSuccess | UserRequest | UserFail | UserSuccess;