import { User } from '../../model/user/user';
import { UploadFileResponse } from '../../../../core/models/upload-file-response';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { AppValidator } from '../../../../components/validations/validation.rules';
import { AuthService } from '../../../../core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../../components/alert/alert.service';
import { FileService } from '../../../../core/services/file.service';
import { AddressService } from '../../service/address.service';
import { UserService } from '../../service/user.service';
import { CredentialsService } from '../../../../core/services/credentials.service';
import { ThemeService } from '../../../../core/services/theme.service';
import { Observable } from 'rxjs';
import { Credentials } from '../../../../core/models/credentials';
import { City } from '../../model/address/city';
import { State } from '../../model/address/state';
import { County } from '../../model/address/county';
import { Address } from '../../model/address/address';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseView } from '../../../shared/BaseView';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent extends BaseView implements OnInit {


  public userForm: FormGroup;

  public preview: string;
  public file: File;
  public customerId: number;
  public groupId: number;
  percentDone = 0;
  address: Address;
  countries: Array<County>;
  states: Array<State>;
  cities: Array<City>;
  user$: Observable<Credentials>;
  user: Credentials;

  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userService: UserService,
    private form: FormBuilder,
    private addressService: AddressService,
    private fileService: FileService,
    private alertService: AlertService,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {

    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.customerId = this.user.customerId;
      this.groupId = this.user.groupId;

      this.userForm = this.form.group({
        id: [''],
        fullname: ['', [Validators.required, Validators.maxLength(100)]],
        cpf: [null, [Validators.required, Validators.minLength(11)]],
        email: ['', [Validators.email, Validators.required]],
        cellphone: [''],
        zipcode: ['', [Validators.required]],
        street: ['', [Validators.required]],
        number: ['', [Validators.required]],
        complement: [''],
        district: ['', [Validators.required]],
        cityId: ['', [Validators.required]],
        stateId: ['', [Validators.required]],
        countryId: ['', [Validators.required]],
        creationDate: [''],
        competenceName: [''],
        active: [''],
        photo: [null],
        document: [''],
        frmtBirthday: [''],
        customerId: [this.customerId, [Validators.required]],
        groupId: [this.groupId, [Validators.required]],
        login: [user.userName, Validators.required],
        password: [''],
        newPassword: [''],
        confirmPassword: ['']
      }, {
        validator: [
          AppValidator.MatchCPF('cpf'),
          AppValidator.MatchPassword('password', 'confirmPassword'),
        ]
      });
      this.loadData();
    });
  }

  public loadData() {

    if (this.user.id) {
      this.userService.getUser(this.user.id)
        .subscribe(user => {

          this.preview = this.getResourceUri(user.photo);
          this.userForm.patchValue(user);
          this.loadCities();
          this.loadStates();
          this.loadCountries();
        });
    }
  }

  loadAddress() {
    const zipcode = this.userForm.get('zipcode').value;
    if (zipcode) {

      this.addressService.getAddressByCEP(zipcode)
        .subscribe(address => {
          this.userForm.get('street').setValue(address.logradouro);
          this.userForm.get('district').setValue(address.bairro);
          this.userForm.get('countryId').setValue(address.countryId);
          this.userForm.get('stateId').setValue(address.stateId);
          this.userForm.get('cityId').setValue(address.cityId);
          this.loadCountries();
          this.loadStates();
          this.loadCities();
        });
    }
  }

  loadCountries() {
    this.addressService.getCountries()
      .subscribe(countries => {
        this.countries = countries;
      });
  }

  loadStates() {
    const countryId = this.userForm.get('countryId').value;
    if (countryId) {
      this.addressService.getStatesByCountryId(countryId)
        .subscribe(states => {
          this.states = states;
        });
    }
  }

  loadCities() {
    const countryId = this.userForm.get('countryId').value;
    const stateId = this.userForm.get('stateId').value;
    if (countryId && stateId) {
      this.addressService.getCitiesByStateId(countryId, stateId)
        .subscribe(cities => {
          this.cities = cities;
        });
    }
  }



  handleFile(file) {
    this.file = file;
    this.userForm.get('photo').setValue(file.name);
    const reader = new FileReader();
    reader.onload = (event: any) => this.preview = event.target.result;
    reader.readAsDataURL(file);
  }

  public save() {
    if (this.file) {
      this.uploadAfterSave();
    } else {
      this.register();
    }
  }

  private uploadAfterSave() {

    this.fileService.uploadFile("customer/images", this.file)
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            const response = event.body as UploadFileResponse;
            this.userForm.get('photo').setValue(response.fileDownloadUri);
            this.register();
          }
        },
        err => {
          this.onError(null, 'Upload error!', this.alertService);
        });
  }


  public clearFieldDate(){
    this.userForm.get('frmtBirthday').setErrors(null);
  }

  public convertFrmtStringToDate() {

    const date =  this.userForm.get('frmtBirthday').value;
    if (!date) {
      return null;
    }
    const day = date.toString().substring(0, 2);
    const month = date.toString().substring(2, 4);
    const year = date.toString().substring(4, date.length);
    const newDate = `${day}/${month}/${year}`;
    var patternValidaData = /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/;
    if (!patternValidaData.test(newDate)) {
      this.userForm.get('frmtBirthday').setErrors({ invalidDateFormat: true });
      return;
    }
    return `${year}/${month}/${day}`;


  }

  public register() {

    if (this.userForm.valid) {
      const user = this.userForm.getRawValue() as User;

      user.frmtBirthday = this.convertFrmtStringToDate();

      const credentials = this.userForm.getRawValue() as Credentials;

      this.userService.update(user.id, user).
        subscribe(data => {
          if (this.userForm.get('photo').value) {
            this.user.photo = this.userForm.get('photo').value;
            this.credentialsService.update(this.user);
          }

          const existsNewPassword = credentials.password;
          if (existsNewPassword) {
            this.updatePassword(credentials);
          } else {
            this.onSucess(data, this.translate.instant('profile.success-update'), this.alertService);
          }
        },
          error => {
            this.onError(error, this.translate.instant('profile.error-update'), this.alertService)
          }
        );

    } else {
      window.scroll(0, 0);
    }
  }

  updatePassword(credentials) {
    if (credentials) {
      this.authService.savePassword(credentials).subscribe(data => {
        data = data;
        this.onSucess(data, this.translate.instant('profile.success-update'), this.alertService);
      });
    }
  }

  validPassword(userForm: FormGroup) {

    if (userForm.get('password').value.length > 0) {
      const validators = Validators.compose([Validators.required]);
      this.enableFieldValidation(userForm, 'password', validators);
      this.enableFieldValidation(userForm, 'confirmPassword', validators);
    } else {
      this.disableFieldValidation(userForm, 'password');
      this.disableFieldValidation(userForm, 'confirmPassword');
    }
  }

}
