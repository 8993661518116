import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NotFoundAccountComponent } from './not-found-account/not-found-account.component';
import { BlockedAccountComponent } from './blocked-account/blocked-account.component';
import { AlertFieldModule } from './../../components/alert-field/alert-field.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login.routing.module';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { ComponentsModule } from '@app/components/components.module';
import { LoginComponent } from './login.component';
import { SignInComponent } from './signin/signin.component';
import { SignUpComponent } from './signup/signup.component';
import { AlertModule } from '@app/components/alert/alert.module';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordManageComponent } from './password-manage/password-manage.component';
import { PasswordRegisterComponent } from './password-manage/password-register/password-register.component';
import { TranslateLoadersConfig } from '@app/utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    AlertFieldModule,
    TranslateModule.forChild(TranslateLoadersConfig),
  ],
  declarations: [
    LoginComponent,
    SignInComponent,
    SignUpComponent,
    PasswordManageComponent,
    PasswordRegisterComponent,
    BlockedAccountComponent,
    NotFoundAccountComponent,
    LoadingPageComponent
  ]
})
export class LoginModule { }
