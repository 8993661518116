import { Address } from './address';


export class Payer {

  payerId: number;
  userId: number;
  fullname: string;
  cpf: string;
  email: string;
  cellphone: string;
  birthday: Date;
  frmtBirthday: string;
  strBirthday: string;
  receiveNews: boolean;
  billingAddress: Address;
  creationDate:Date;
  active:boolean;
}
