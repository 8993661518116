import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/views/user/model/user/user';


import { environment } from '@env/environment';
import { UserPending } from '../model/user/user-pending';

const API_URL = `${environment.baseUrl}/v1/users`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public getUserPending(userId: number) {
    return this.http.get<UserPending>(`${API_URL}/${userId}/pendings`);
  }

  public getUser(userId: number) {
    return this.http.get<User>(`${API_URL}/${userId}`);
  }

  public update(userId: number, user: User) {
    return this.http.put<User>(`${API_URL}/${userId}`, user);
  }
}
