import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/components/alert/alert.service';
import { ModalService } from '@app/components/modal-container/modal.service';
import { Credentials } from '@app/core/models/credentials';
import { UploadFileResponse } from '@app/core/models/upload-file-response';
import { CredentialsService } from '@app/core/services/credentials.service';
import { FileService } from '@app/core/services/file.service';
import { BaseView } from '@app/views/shared/BaseView';
import { ConferenceFile } from '@app/views/user/model/conference/conference-file';
import { Inscription } from '@app/views/user/model/conference/inscription';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.sass']
})
export class FileDownloadComponent extends BaseView implements OnInit {

  showFormFile: boolean = false;

  conference = new Inscription();
  submitConferenceFile = false;

  public file: File;
  public preview: string;
  percentDone = 0;
  conferenceFile = new ConferenceFile();
  files$: Observable<ConferenceFile[]>;
  user: Credentials;

  constructor(private modal: ModalService,
    private conferenceInscriptionService: ConferenceInscriptionService,
    private credentialsService: CredentialsService,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {

    this.credentialsService.getUserCredentials().subscribe(user => {
      this.user = user;
      this.conference = this.modal.ref;
      if (this.conference) {
        this.files$ = this.conferenceInscriptionService.getFiles(this.user.id, this.conference.conferenceId, this.conference.inscriptionId);
      }
    });
  }

  handleFile(file: File) {
    this.conferenceFile.url = file.name;
  }

  editFile(conferenceFile: ConferenceFile) {
    this.submitConferenceFile = false;
    this.showFormFile = true;
    this.conferenceFile = conferenceFile;
  }

  clearForm() {
    this.conferenceFile = new ConferenceFile();
    this.submitConferenceFile = false;
    this.showFormFile = false;
  }

  cancelFile() {
    this.showFormFile = false;
    this.conferenceFile = new ConferenceFile();
    this.conferenceFile.url = null;
  }

  downloadPdf(conferenceFile: ConferenceFile) {
    const docUrl = this.getResourceUri(conferenceFile.url);   
    if(docUrl.includes(".pdf")){
      FileSaver.saveAs(docUrl, 'download');
    } else {
      window.open(docUrl, '_self', '');
    }
  }
  
  openDoc(pdfUrl: string, startPage: number) {
    window.open(pdfUrl + '#page=' + startPage, '_blank', '');
  }

  close() {
    this.modal.destroy();
  }


}
