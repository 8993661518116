<div *ngFor="let alert of alerts" class="{{ getAlertClass(alert) }} alert-format">

  <p class="center-vertical m-0">
    <i class="fas fa-times icons-alert" *ngIf="alert.alertType == 2"></i>
    <i class="fas fa-exclamation icons-alert" *ngIf="alert.alertType == 1"></i>
    <i class="fas fa-info icons-alert" *ngIf="alert.alertType == 3"></i>
    <i class="fas fa-check icons-alert" *ngIf="alert.alertType == 0"></i>
    <i class="fas fa-question icons-alert" *ngIf="alert.alertType == 4"></i>

    <span class="alert-message">{{ alert.message }}</span>
    <a type="button" class="icon-close-alert" data-dismiss="alert" aria-label="Close" (click)="removeAlert(alert)">
      <span aria-hidden="true"><b>&times;</b> </span>
    </a>
  </p>
</div>
