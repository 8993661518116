import { BaseView } from '@app/views/shared/BaseView';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidator } from '@components/validations/validation.rules';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials } from '@app/core/models/credentials';
import { AuthService } from '@app/core/services/auth.service';
import { Observable } from 'rxjs';
import { CustomerPreference } from '@app/views/account/model/customer-preference';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';

@Component({
  selector: 'app-password-register',
  templateUrl: './password-register.component.html',
  styleUrls: ['./password-register.component.sass']
})
export class PasswordRegisterComponent extends BaseView implements OnInit {

  public registerPassForm: FormGroup;
  public countsubmit = 0;
  registerPasswordForm: FormGroup;
  userId: any;
  userEmail: any;
  showMessageError: boolean;
  customerPreference$: Observable<CustomerPreference>;
  customerPreference: CustomerPreference;
  public firstAccess: number;

  constructor(
    private auth: AuthService,
    private form: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private customerPreferenceService: CustomerPreferenceService
  ) {
    super();
  }

  ngOnInit() {
    this.customerPreference$ = this.customerPreferenceService.getPreference();
    this.customerPreference$.subscribe(preference => {
      this.customerPreference = preference;
    });

    this.userId = this.route.snapshot.queryParams['id'];
    this.firstAccess = this.route.snapshot.queryParams['firstAccess'];

    this.buildfForm();
  }


  public buildfForm() {
    this.registerPasswordForm = this.form.group({
      id: [this.userId,
      Validators.required
      ],
      email: [null],
      login: [null],
      password: [null,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([a-zA-Z0-9$@$!%*?&]{6,})')
        ]
      ],
      passwordConfirm: [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([a-zA-Z0-9$@$!%*?&]{6,})')
      ]
      ]
    },
      {
        validator: [
          AppValidator.MatchPassword('password', 'passwordConfirm'),
        ]
      })
  }

  public save() {

    if (this.registerPasswordForm.valid) {

      const credentials = this.registerPasswordForm.getRawValue() as Credentials;
  
      this.auth.savePassword(credentials).subscribe(credential => {
        credentials.login = credential.userName;        
        credentials.customerId = this.customerPreference.customerId;
        credentials.groupId = this.customerPreference.groupId;
        this.onRedirect(credentials);
      });
    }
  }

  private onRedirect(credentials: Credentials) {
    if (this.firstAccess == 0) {

      this.router.navigate(['/']);

    } else {

      this.auth.login(credentials).subscribe(data =>

        this.router.navigate(['/dashboard'])

      );
    }
  }
}
