import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Router } from '@angular/router';
import { BaseView } from '@app/views/shared/BaseView';
import { Observable } from 'rxjs/internal/Observable';
import { Credentials } from '@app/core/models/credentials';
import { ThemeService } from '@app/core/services/theme.service';
import { Assessment } from '@app/views/user/model/assessment/assessment';
import { UserAssessmentService } from '@app/views/user/service/user-assessments.service';


@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.sass']
})
export class AssessmentListComponent extends BaseView implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  searchTerms: string;
  changeTab: number = 0;
  assessments: Array<Assessment>;
  public show: boolean = false;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private route: Router,
    public userAssessmentService: UserAssessmentService
  ) {
    super();
  }

  ngOnInit(): void {

    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.loadItens(this.STATUS_ALL);
    });

  }

  public loadItens(statusId: number) {
    this.changeTab = statusId;
    if (this.user.id) {
      this.userAssessmentService.getAssessments(this.user.id, this.searchTerms, statusId)
        .subscribe(assessments => {
          this.assessments = assessments;
        })
    }
  }

  public redirect(assessmentId: number, matriculationId: number) {
    this.route.navigate([`assessments/${assessmentId}/intro/${matriculationId}`]);
  }

  public redirectToReport(assessmentId: number, matriculationId: number){
    this.route.navigate([`assessments/${assessmentId}/report/${matriculationId}`]);
  }
}
