import { BaseView } from '@app/views/shared/BaseView';
import { Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@app/core/services/auth.service';
import { Credentials } from '@app/core/models/credentials';
import { Observable } from 'rxjs';
import { CustomerPreference } from '@app/views/account/model/customer-preference';
import { CustomerPreferenceService } from '@app/views/account/service/customer-preference.service';
import { AlertService } from '@app/components/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-password-manage',
  templateUrl: './password-manage.component.html',
  styleUrls: ['./password-manage.component.sass']
})
export class PasswordManageComponent extends BaseView implements OnInit {
  resetPasswordForm: FormGroup;
  public sucessSend: boolean = false;
  public errorSend: boolean = false;
  customerPreference$: Observable<CustomerPreference>;
  customerPreference: CustomerPreference;

  constructor(
    private router: Router,
    private form: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private translate: TranslateService,
    private customerPreferenceService: CustomerPreferenceService
  ) {
    super();
  }

  ngOnInit() {

    this.customerPreference$ = this.customerPreferenceService.getPreference();
    this.customerPreference$.subscribe(preference => {
      this.customerPreference = preference;
    });

    this.resetPasswordForm = this.form.group({
      login: [null, [Validators.required, Validators.email]],
    })
  }

  close() {
    this.router.navigate(['']);
  }

  private onRegisterError(error) {
    this.alertService.danger(this.translate.instant('recover.forgot-email-error'));
  }

  public sendReset() {
    const credentials = this.resetPasswordForm.getRawValue() as Credentials;
 
    if (this.resetPasswordForm.valid) {
      this.authService.resetPasswordByEmail(credentials.login)
        .subscribe(
          data => {
            data = data;
            this.alertService.success(this.translate.instant('recover.forgot-success'));
            this.sucessSend = true;
            this.errorSend = false;
          },
          error => this.onRegisterError(error)
        );
      this.errorSend = true;
      this.sucessSend = false;
    }
  }

}
