import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as state from "../reducers";

const selectCoreState = createFeatureSelector<state.CoreState>('core');

const getAppState = createSelector(
  selectCoreState,
  (state: state.CoreState) => state.auth
);

export const getAuth = createSelector(
  getAppState,
  state.selectAuth
);

export const getUser = createSelector(
  getAppState,
  state.selectUser
)