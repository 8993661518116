<div class="box-notes">
  <p class="title-notes">{{ 'conference.notes' | translate }}</p>
  <p class="text-input">{{ 'conference.title' | translate }}</p>
  <input class="input" [ngClass]="{'input-error': !note.title && submit}" [(ngModel)]="note.title" maxlength="100">
  <span class="sub-title-info">
    {{note.title ? note.title.length:0}} / 100
  </span>
  <p class="text-input">{{ 'conference.description' | translate }}</p>
  <textarea type="text" class="textarea" maxlength="500" placeholder="••••••"
    [ngClass]="{'input-error': !note.note && submit}" [(ngModel)]="note.note"></textarea>
  <span class="sub-title-info">
    {{note.note ? note.note.length:0}} / 500
  </span>
  <div class="text-right mt-20">
    <a class="btn-notes" (click)="saveNote()">{{ 'conference.save' | translate }}</a>
  </div>
</div>
<div class="box-alerts" *ngIf="(note$ | async) as notes">
  <div class="alerts" *ngFor="let note of notes; let i = index">
    <p class="title-alerts">{{note.title}} <i class="fas fa-times text-right" (click)="deleteNote(note)"></i></p>
    <p class="text-alerts">{{note.note}}</p>
  </div>
</div>