<div class="alert-field" [ngClass]="{'box-alert-sucess': alertType == SUCCESS,
  'box-alert-alert': alertType == ALERT,
  'box-alert-error': alertType == ERROR,
  'box-alert-info': alertType == INFO}">
  <img src="assets/images/icons/check-alert.svg" class="icon-alert-field" *ngIf="alertType == SUCCESS">
  <img src="assets/images/icons/alert.svg" class="icon-alert-field" *ngIf="alertType == ALERT">
  <img src="assets/images/icons/error.svg" class="icon-alert-field" *ngIf="alertType == ERROR">
  <img src="assets/images/icons/info.svg" class="icon-alert-field" *ngIf="alertType == INFO">
  <p class="text-field" [ngClass]="{'text-alert-success': alertType == SUCCESS,
  'text-alert-alert': alertType == ALERT,
  'text-alert-error': alertType == ERROR,
  'text-alert-info': alertType == INFO}">
    <span *ngIf="alertText">{{  alertText  }}</span>
    <span *ngIf="alertTextTranslate">{{  alertTextTranslate | translate }}</span>
  </p>
</div>
