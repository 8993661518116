import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-field',
  templateUrl: './alert-field.component.html',
  styleUrls: ['./alert-field.component.scss']
})
export class AlertFieldComponent implements OnInit {

  @Input() alertType: number;
  @Input() alertText: String;
  @Input() alertTextTranslate: String;

  public SUCCESS = 1;
  public ALERT = 2;
  public ERROR = 3;
  public INFO = 4;

  constructor() { }

  ngOnInit() {
  }

}
