import { Invoice } from './../../../../model/products/invoice';
import { UserContextService } from '@app/core/services/user-context.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ThemeService } from '@app/core/services/theme.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { BaseView } from '@app/views/shared/BaseView';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Order } from '@app/views/user/model/products/order';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/components/alert/alert.service';
import { environment } from '@env/environment';
import { VirtualStoreBase } from '../../../virtual-store-base';
import { CreditCard } from '@app/views/user/model/products/credit-card';
declare const myFun: any;

declare const iugu: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent extends VirtualStoreBase implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  order$: Observable<Order>;
  order: Order;
  orderId: number;
  submitCreditCard: boolean;
  submitted: boolean;
  urlImageBrand: string;
  cvvAmex: boolean;
  creditCardData: any;
  public paymentForm: FormGroup;
  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService,
    private route: Router,
    private form: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private virtualStoreService: VirtualStoreService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.orderId = this.activatedRoute.snapshot.params['orderId'];
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
      this.paymentForm = this.form.group({
        paymentTypeId: [''],
        quantityPlots: [null, Validators.required],
        creditCardNumber: ['', Validators.required],
        creditCardHolderName: ['', Validators.required],
        expirationCard: ['', Validators.required],
        cvvCard: ['', Validators.required],
        errorCreditCardNumber: [''],
        errorExpirationCard: [''],
        errorCvvCard: ['']
      });
      this.loadData();
    });
  }

  public loadData() {
    this.order$ = this.virtualStoreService.getProductOrderById(this.user.id, this.orderId);
    this.order$.subscribe(order => {
      this.order = order;
      console.log(this.order);
    });
  }


  public getBrandByCreditCardNumber() {
    const number = this.paymentForm.get('creditCardNumber').value;
  }

  public getBrand(brand: string) {
    if (brand) {
      if (brand.toLowerCase() == "visa") {
        this.urlImageBrand = "/assets/images/brand_credit_card/visa.png";
      } else if (brand.toLowerCase() == "mastercard") {
        this.urlImageBrand = "/assets/images/brand_credit_card/mastercard.png";
      } else if (brand.toLowerCase() == "amex") {
        this.urlImageBrand = "/assets/images/brand_credit_card/american-express.png";
      } else if (brand.toLowerCase() == "diners") {
        this.urlImageBrand = "/assets/images/brand_credit_card/diners.png";
      }
    }
  }

  public validateCVV() {

    const number = this.paymentForm.get('creditCardNumber').value;
    const cvvCard = this.paymentForm.get('cvvCard').value;
    this.disableFieldValidation(this.paymentForm, 'errorCvvCard');

    if (number && cvvCard) {
      const brand = iugu().utils.getBrandByCreditCardNumber(number);

      const valid = iugu().utils.validateCVV(cvvCard, brand);
      if (!valid) {
        let validators = Validators.compose([Validators.required]);
        this.enableFieldValidation(this.paymentForm, 'errorCvvCard', validators);
        this.paymentForm.get('errorCvvCard').setErrors({ errorCvvCardInvalid: true });
      }
    }
  }

  public validateCreditCardHolderName(){
    this.order.tokenCreditCard = null;
    this.paymentForm.get('quantityPlots').setValue(null);
  }

  public validateCreditCardNumber() {

    this.order.tokenCreditCard = null;
    this.paymentForm.get('quantityPlots').setValue(null);

    const number = this.paymentForm.get('creditCardNumber').value;
    if (number) {
      const brand = iugu().utils.getBrandByCreditCardNumber(number);
      this.getBrand(brand);
      this.cvvAmex = false;
      if (brand.toLowerCase() == "amex") {
        this.cvvAmex = true;
      }
      const valid = iugu().utils.validateCreditCardNumber(number);
      this.disableFieldValidation(this.paymentForm, 'errorCreditCardNumber');
      if (!valid) {
        let validators = Validators.compose([Validators.required]);
        this.enableFieldValidation(this.paymentForm, 'errorCreditCardNumber', validators);
        this.paymentForm.get('errorCreditCardNumber').setErrors({ creditCardNumberInvalid: true });
      }
    }
  }

  public validateExpirationString() {

    const validadeCard = this.paymentForm.get('expirationCard').value;

    this.disableFieldValidation(this.paymentForm, 'errorExpirationCard');

    if (validadeCard && validadeCard.length == 4) {
      const mes = validadeCard.substring(0, 2);
      const ano = validadeCard.substring(2, 4);
      const valid = iugu().utils.validateExpirationString(`${mes}/${ano}`);

      if (!valid) {
        let validators = Validators.compose([Validators.required]);
        this.enableFieldValidation(this.paymentForm, 'errorExpirationCard', validators);
        this.paymentForm.get('errorExpirationCard').setErrors({ expirationCardInvalid: true });
      }
    }

  }

  public lessItem(index: number) {

    if (this.order.products[index].quantity > 1) {
      this.order.products[index].quantity -= 1;
      this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
        .subscribe(order => {
          this.order = order;
        });
    }
  }

  public moreItem(index: number) {

    this.order.products[index].quantity += 1;
    this.virtualStoreService.updateProductsOrder(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.order = order;
      });
  }

  public redirect() {
    this.route.navigate([`virtual-store/purchases/summary/${this.order.id}`]);
  }

  public redirectPayer() {
    this.route.navigate([`virtual-store/purchases/payer/${this.order.id}`]);
  }


  public payBankSlip() {

    this.order.paymentTypeId = this.paymentForm.get('paymentTypeId').value;

    this.virtualStoreService.createInvoice(this.user.id, this.order.id, this.order)
      .subscribe(order => {
        this.route.navigate([`virtual-store/purchases/summary/${this.order.id}`]);
      });
  }

  public createCreditCardToken(){

    if(!this.order.tokenCreditCard && (this.paymentForm.get('creditCardNumber').value
            && this.paymentForm.get('creditCardHolderName').value
            && this.paymentForm.get('cvvCard').value
            && this.paymentForm.get('expirationCard').value)){
      iugu().setAccountID(environment.IUGU_AccountID);
      iugu().setTestMode(true);

      const firstName = this.getFirstName();
      const lastName = this.getLastName();

      const number = this.paymentForm.get('creditCardNumber').value;
      const cvvCard = this.paymentForm.get('cvvCard').value;

      const mounth = this.getMounth();
      const year = this.getYaer();

      const cc = iugu().CreditCard(number, year, mounth, firstName, lastName, cvvCard);

      iugu().createPaymentToken(cc,(data)=>{

        if (data.errors) {
          const errors = JSON.stringify(data.errors);
          this.alertService.danger('Os dados do cartão são invalidos, favor verifique.');
        } else {
          this.order.paymentTypeId = this.paymentForm.get('paymentTypeId').value;

          this.order.invoice = new Invoice();
          this.order.invoice.creditCard = new CreditCard();
          this.order.invoice.creditCard.brand = data.extra_info.brand;
          this.order.invoice.creditCard.displayNumber = data.extra_info.display_number;
          this.order.quantityPlots = this.paymentForm.get('quantityPlots').value;
          this.order.invoice.creditCard.quantityPlots = this.order.quantityPlots;
          this.order.tokenCreditCard = data.id;
        }
      });
    }
  }

  public payCreditCard() {

    this.submitted = true;

    if (!this.paymentForm.invalid) {

      this.submitCreditCard = true;

      if (this.order.tokenCreditCard) {
        this.virtualStoreService.createInvoice(this.user.id, this.order.id, this.order)
          .subscribe(order => {
            if (order.orderStatusId == this.PAYMENT_ACCEPT || order.orderStatusId == this.AWAITING_PAYMENT) {
              this.route.navigate([`virtual-store/purchases/summary/${this.order.id}`]);
            } else if (order.orderStatusId == this.UNAUTHORIZED_PAYMENT) {
              this.alertService.danger('Pagamento não autorizado pela operadora do cartão do cartão de crédito');
            }
            this.submitCreditCard = false;
          }, error => {
            this.alertService.danger('Erro ao pagar com o cartão credito');
            this.submitCreditCard = false;
          });
      } else {
        this.alertService.danger('Dados inválidos, favor verificar');
        this.submitCreditCard = false;
      }

    }
  }

  private payCreditCardCreateInvoice(data) {


  }

  private getFirstName() {

    const fullname = this.paymentForm.get('creditCardHolderName').value;
    if (fullname) {
      const firstName = fullname.split(' ')[0];
      return firstName;
    }
  }

  private getLastName() {

    const fullname = this.paymentForm.get('creditCardHolderName').value;
    if (fullname) {
      const lastName = fullname.split(' ')[1];
      if (lastName) {
        return lastName;
      }
    }
  }

  private getMounth() {
    const validadeCard = this.paymentForm.get('expirationCard').value;
    if (validadeCard) {
      return validadeCard.substring(2, 4);
    }
  }

  private getYaer() {
    const validadeCard = this.paymentForm.get('expirationCard').value;
    if (validadeCard) {
      return validadeCard.substring(0, 2);
    }
  }

}
