<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default" [style.color]="user.mainColor">{{ 'products.title' | translate }}</h1>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control has-icons-left has-icons-right">
            <input class="input input-search" type="text" placeholder="{{ 'placeholder.general' | translate }}">
            <span class="icon is-small is-right show-icon-search" style="height: 3.7em">
              <img src="/assets/images/icons/lupa.png">
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12 mb-50">
        <a class="text-menu-filter text-menu-filter-active" [ngClass]="{'text-menu-filter-active': changeTab == 0}"
          (click)="loadItens(0, true)">{{'products.all' | translate}}</a>
        <a class="text-menu-filter" [ngClass]="{'text-menu-filter-active': changeTab == 1}"
          (click)="loadItens(1,true)">Assessments</a>
        <a class="text-menu-filter" [ngClass]="{'text-menu-filter-active': changeTab == 8}"
          (click)="loadItens(8, true)">Conferências</a>
        <a class="text-menu-filter" [ngClass]="{'text-menu-filter-active': changeTab == 10}"
          (click)="loadItens(10, true)">Treinamentos</a>
      </div>
      <div class="cards">
        <div class="card" *ngFor="let product of products">
          <picture class="card__picture">
            <img class="card__imagem" [src]="getImage(product.urlImage)">
          </picture>
          <div class="card__info">
            <h2 class="card__title">
              {{product.name}}
            </h2>
            <p class="sub-text mb-7 limit-text-box">{{product.briefDescription}}</p>
            <p class="card__description" [innerHTML]="limitDescription(product.description, 150) | safeHtml">
            </p>
            <p class="card__description" *ngIf="!product.description">{{'products.not-description' | translate}}
            </p>
          </div>
          <div class="card__buttons">
            <div>
              <p class="text-default-card mr-10 mb-0 discount" *ngIf="product.discount">{{product.fmtPrice}}</p>
              <p class="text-default-card mb-0 price" *ngIf="product.discount"><b>{{product.fmtDiscountPrice}}</b></p>
              <p class="text-default-card mb-0 price" *ngIf="!product.discount"><b>{{product.fmtPrice}}</b></p>
              <p class="text-default-card mb-0 price" *ngIf="product.free"><b>Free</b></p>
            </div>
            <div>
              <a class="card__button" *ngIf="!product.free" (click)="purchase(product.id)">{{'products.purchase' | translate }}</a>
              <a class="card__button" *ngIf="product.free" (click)="redirectToModule(product)">Acessar</a>
            </div>
          </div>
            </div>
            </div>
    </div>
  </div>
</section>