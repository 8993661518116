<section class="section" [ngClass]="{'section-padding': !themeService.eventMenu}">
  <div class="container">
    <div class="columns is-multiline">
      <div class="column">
        <h1 class="title-default" [style.color]="user.mainColor" *ngIf="typeId == 1">{{ 'training.title-training' |
          translate }}</h1>
        <h1 class="title-default" [style.color]="user.mainColor" *ngIf="typeId == 2">{{ 'trail.title-trail' | translate
          }}</h1>
      </div>
      <div class="column is-4">
        <div class="field">
          <div class="control has-icons-left has-icons-right">
            <input class="input input-search" type="text" placeholder="{{ 'placeholder.general' | translate }}"
              [(ngModel)]="searchTerms">
            <span class="icon is-small is-right show-icon-search" style="height: 3.7em">
              <img src="/assets/images/icons/lupa.png" (click)="loadItens(STATUS_ALL)">
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <a class="text-menu-filter" (click)="loadItens(STATUS_ALL)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_ALL}">
          <span *ngIf="typeId == 1">{{ 'training.tab-all' | translate }}</span>
          <span *ngIf="typeId == 2">{{ 'trail.tab-all' | translate }}</span>
        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_NOT_INITIATED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_NOT_INITIATED}">
          <span *ngIf="typeId == 1">{{ 'training.tab-not-started' |translate }}</span>
          <span *ngIf="typeId == 2">{{ 'trail.tab-not-started' |translate }}</span>
        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_INITIATES)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_INITIATES}">
          <span *ngIf="typeId == 1">{{ 'training.tab-started' | translate}}</span>
          <span *ngIf="typeId == 2">{{ 'trail.tab-started' | translate}}</span>
        </a>
        <a class="text-menu-filter" (click)="loadItens(STATUS_COMPLETED)"
          [ngClass]="{'text-menu-filter-active': changeTab == STATUS_COMPLETED}">
          <span *ngIf="typeId == 1">{{ 'training.tab-concluded' |translate }}</span>
          <span *ngIf="typeId == 2">{{ 'trail.tab-concluded' |translate }}</span>
        </a>
      </div>

      <div class="column is-12 mt-30">
        <div class="columns is-multiline">
          <div class="cards">
            <div class="card" *ngFor="let training of trainings">
              <picture class="card__picture">
                <img class="card__imagem" [src]="getImage(training.urlImage)">
              </picture>
              <div class="card__info">
                <h2 class="card__title">
                  {{training.name}}
                </h2>
                <p class="card__description" [innerHTML]="limitDescription(training.description, 150) | safeHtml">
                </p>
                <p class="card__description" *ngIf="!training.description && typeId == 1">{{ 'training.not-description' |
                  translate }}</p>
                <p class="card__description" *ngIf="!training.description && typeId == 2">{{ 'trail.not-description' |
                  translate }}
                </p>
              </div>
              <div class="card__buttons">
                <a class="card__button" (click)="redirect(training.trainingTypeId,training.trainingTypeId,training.matriculationId)"
                  *ngIf="typeId == 1">{{'training.realize'
                  | translate }}</a>
                <a class="card__button" (click)="redirect(training.trainingTypeId,training.trainingTypeId,training.matriculationId)"
                  *ngIf="typeId == 2">{{'trail.realize'
                  | translate }}</a>
                <a class="card__button" *ngIf="typeId == 1 && training.publisher" (click)="cleanHistory(training.trainingId, training)">Limpar Histórico</a>
                <a class="card__button" *ngIf="training.matriculationStatusId == 3"
                  (click)="redirectCertified(training.trainingTypeId, training.trainingId, training.matriculationTrainingId)">{{
                  'training.certified' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <p class="info-default-list" *ngIf="(!trainings || trainings?.length == 0) && typeId == 1">
          {{ 'trail.none-training' |translate }}
        </p>
        <p class="info-default-list" *ngIf="(!trainings || trainings?.length == 0) && typeId ==2">
          {{ 'trail.none-trail' |translate }}
        </p>
      </div>
    </div>
  </div>
</section>