<form #form='ngForm' [formGroup]="assessmentForm" (submit)="save()">
  <section class="section">
    <div class="progress-test">
      <div class="progress">
        <progress class="progress-bar progress-blue"
          value="100" max="100" style="width: {{progressBar}}%;"></progress>
        <span class="number-progress">{{progressBar}}%</span>
      </div>
    </div>
    <div class="container">
      <div class="columns is-multiline">
        <div class="container mt-50" formArrayName="questions">
          <div class="columns is-multiline  mb-50">
            <div class="column is-12" *ngFor="let question of questionsFormGroup.controls; let i= index">
              <div [formGroupName]="i" [ngClass]="{'is-inactive': itensIndex < i}">
                <p class="text-question">{{question.get('questionTitle').value}}</p>
                <div formArrayName="alternatives" id="{{i}}">
                  <div class="control"
                    *ngFor="let alternative of getAlternativesFormGroup(question).controls; let j= index">
                    <div *ngIf="itensIndex >= i">
                      <label class="radio text-left" (click)="addIndexItens(i + 1)">
                        <input type="radio" [formControl]="question.get('answerId')"
                          [value]="alternative.get('alternativeId').value"
                          name="question_{{question.get('questionId').value}}"
                          (click)="setQuestionScore(question, alternative)">
                        <span [style.color]="user.mainColor"></span>
                        {{alternative.get('alternativeTitle').value}} 
                      </label>
                    </div>
                    <div *ngIf="itensIndex < i" class="is-inactive">
                      <label class="radio text-left" style="cursor: no-drop">
                        <input type="radio" [formControl]="question.get('answerId')"
                          [value]="alternative.get('alternativeId').value"
                          name="question_{{question.get('questionId').value}}"
                          (click)="setQuestionScore(question, alternative)" disabled>
                        <span></span>
                        {{alternative.get('alternativeTitle').value}} 
                      </label>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
            <p *ngIf="assessmentForm.status == 'INVALID' && form.submitted">
              {{ 'error.all-answers' | translate }}
            </p>
            <div class="column is-12 text-center">
              <a class="btn-default-cancel mr-10" (click)="cancel()">{{ 'general.cancel' | translate }}</a>
              <button type="submit" class="btn-default-confirmed"
                [style.background-color]="user.mainColor"
                [ngClass]="{'btn-save-disable':assessmentForm.pending}" [disabled]="assessmentForm.pending">
                {{ 'error.finalize-assessment' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
