import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/core/auth/auth.guard';
import { ConferenceHomeComponent } from './conference-home/conference-home.component';
import { ConferenceListComponent } from './conference-list/conference-list.component';


const routes: Routes = [
  {
    path: '', component: ConferenceListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'conferences',
    component: ConferenceListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'conferences/:conferenceId/inscriptions/:inscriptionId',
    component: ConferenceListComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'conferences/:conferenceId/conference-home/:inscriptionId',
    component: ConferenceHomeComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConferenceRoutingModule { }
