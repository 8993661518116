import { NgModule } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { CommonModule } from "@angular/common";

import { ThemeModule } from "./theme/theme.module";

import { reducers, effects } from "./store";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ThemeModule,
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature(effects)
  ]
})
export class CoreModule { }