import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@views/login/login.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  }, 
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'my-account',
    loadChildren: () => import('./views/user/account/account.module').then(m => m.AccountModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'virtual-store',
    loadChildren: () => import('./views/user/virtual-store/virtual-store.module').then(m => m.VirtualStoreModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'assessments',
    loadChildren: () => import('./views/user/evaluation/assessments/assessment.module').then(m => m.AssessmentModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'trails',
    loadChildren: () => import('./views/user/learning/trainings/training.module').then(m => m.TrainingModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'trainings',
    loadChildren: () => import('./views/user/learning/trainings/training.module').then(m => m.TrainingModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'career-path',
    loadChildren: () => import('./views/user/learning/career-path/career-path.module').then(m => m.CareerPathModule),
    data: {
      title: 'MCS'
    }
  },
  {
    path: 'conference',
    loadChildren: () => import('./views/user/conference/conference.module').then(m => m.ConferenceModule),
    data: {
      title: 'MCS'
    }
  },
   { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
