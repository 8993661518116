<form class="login-form form" #form='ngForm' [formGroup]="registerForm" (submit)="save()">

  <div class="column is-12" style="padding-bottom: 60px;">
    <ats-language-switch login="show"></ats-language-switch>
  </div>
  <div class="field text-center logo-login" *ngIf="(customerPreference$ | async) as customerPreference">
    <img src="{{getLogo(customerPreference?.logo)}}">
  </div>
  <h1 class="title-login">{{ 'register.title' | translate }}</h1>
  <p class="sub-title-login">
    {{ 'register.personal-data' | translate }}
  </p>
  <div class="notification is-danger" *ngIf="error" [innerHTML]="error.error_description | safeHtml"></div>

  <div class="margin-login">
    <div class="field p-0">
      <p class="itens-login">{{ 'register.input-fullname' | translate }}</p>
      <input type="text" class="input" formControlName="fullName" name=""
        placeholder="{{ 'register.fullname' | translate }}" />
      <app-alert-field alertType=3 alertTextTranslate="general.required"
        *ngIf="registerForm.get('fullName').errors?.required && form.submitted"></app-alert-field>

      <app-alert-field alertType=2 alertTextTranslate="register.full-name-required"
        *ngIf="registerForm.get('fullName').errors?.surnameInvalid && form.submitted"></app-alert-field>

    </div>
    <div class="field p-0">
      <p class="itens-login">{{ 'register.input-email' | translate }}</p>
      <div style="width: 100%; display: inline-block;">
        <input type="text" class="input" formControlName="email"
          placeholder="{{ 'register.type-email' | translate }}" />
        <app-alert-field alertType=2 alertTextTranslate="register.invalid-email-format"
          *ngIf="registerForm.get('email').errors?.email && form.submitted"></app-alert-field>

        <app-alert-field alertType=3 alertTextTranslate="general.required"
          *ngIf="registerForm.get('email').errors?.required && form.submitted"></app-alert-field>

        <app-alert-field alertType=2 alertTextTranslate="register.already-email"
          *ngIf="registerForm.get('email').errors?.userEmailTaken"></app-alert-field>

      </div>
    </div>
    <div class="field p-0 m-0">
      <div class="columns">
        <div class="column is-6">
          <p class="itens-login">{{ 'register.input-pass' | translate }}</p>
          <div style="width: 100%; display: inline-block;">
            <input type="password" class="input" formControlName="password"
              placeholder="{{ 'register.type-password' | translate }}" />

            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="registerForm.get('password').errors?.required && form.submitted"></app-alert-field>

          </div>
        </div>
        <div class="column is-6">
          <p class="itens-login">{{ 'register.input-cfm-pass' | translate }}</p>
          <div style="width: 100%; display: inline-block;">
            <input type="password" class="input" formControlName="passwordConfirmation"
              placeholder="{{ 'register.password-confirm' | translate }}" />

            <app-alert-field alertType=3 alertTextTranslate="general.required"
              *ngIf="registerForm.get('passwordConfirmation').errors?.required && form.submitted"></app-alert-field>

            <app-alert-field alertType=2 alertTextTranslate="register.dont-match"
              *ngIf="registerForm.get('passwordConfirmation').errors?.matchPassword && form.submitted">
            </app-alert-field>

          </div>
        </div>
      </div>
    </div>
    <div class="field p-0">
      <div class="control txt-center">
        <button class="btn button-login mt-50" type="submit">
          {{ 'register.register' | translate }}
        </button>
      </div>
      <p class="itens-login txt-center reset-password-login">{{ 'register.already' | translate }}</p>
      <p class="txt-center"><a class="itens-login" [routerLink]="['']"><b>{{ 'register.login' | translate }}</b></a></p>

    </div>
  </div>
</form>
