import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {


  constructor(private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.auth.isLoggedIn()) {
        let refValue = 0;
        let publisher = false;
        if (route.queryParams["redirect-assessment"]) {
          refValue = parseInt(route.queryParams["redirect-assessment"]);
          this.router.navigate([`assessments/${refValue}/intro/0`]);
        } else if (route.queryParams["redirect-training"]) {
          refValue = parseInt(route.queryParams["redirect-training"]);
          const p  = route.queryParams["publisher"];
          publisher =  Boolean(p);
          this.router.navigate([`trainings/${refValue}/type/1/training-home/0/${publisher}`]);
        }else if (route.queryParams["redirect-conference"]) {
          refValue = parseInt(route.queryParams["redirect-conference"]);
          this.router.navigate([`conferences/${refValue}/inscriptions/0`]);
        }
        else{
          this.router.navigate(['dashboard']);
        }

      return false;
    }

    return true;
  }

}
