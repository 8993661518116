import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { defaultTheme } from "@app/core/theme/theme.default"
import { Observable, of } from "rxjs"
import { catchError } from 'rxjs/operators';
import { Theme } from '../models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themeTarget: HTMLBodyElement;
  private defaultTheme: Theme;
  public eventMenu = false;
  public dontShowNav: number ;

  constructor(private http: HttpClient) {
    this.themeTarget = document.querySelector('body');
    this.defaultTheme = defaultTheme;
  }

  public getTheme(name: string): Observable<Theme>{
    return this.http.get<Theme>(`https://roliveira-contacts-server.herokuapp.com/api/theme/${name}`).pipe(
      catchError(err => of(this.defaultTheme))
    )
  }

  public setTheme(theme: Theme){
    if (theme.primary) {
      this.themeTarget.style.setProperty('--primary', theme.primary);
    }

    if (theme.onPrimary) {
      this.themeTarget.style.setProperty('--onPrimary', theme.onPrimary);
    }

    if (theme.secondary) {
      this.themeTarget.style.setProperty('--secondary', theme.secondary);
    }

    if (theme.onSecondary) {
      this.themeTarget.style.setProperty('--onSecondary', theme.onSecondary);
    }

    if (theme.accent) {
      this.themeTarget.style.setProperty('--accent', theme.accent);
    }

    sessionStorage.setItem('theme', JSON.stringify(theme));
  }

  public showMenuOpen() {
    this.defaultTheme.showMenu = !this.defaultTheme.showMenu;
    this.eventMenu = this.defaultTheme.showMenu;
  }
  public showNav(){
    this.dontShowNav = 1;
  }

}
