import { ActionReducerMap } from "@ngrx/store";
import * as App from "./app.reducer"
import * as Auth from "./auth.reducer";
import * as Theme from "./theme.reducer";
import * as Refdata from "./refdata.reducers";

export interface CoreState {
  app: App.AppState,
  theme: Theme.ThemeState,
  auth: Auth.AuthState,
  refdata: Refdata.RefDataState
}

export const reducers: ActionReducerMap<CoreState> = {
  app: App.reducer,
  theme: Theme.reducer,
  auth: Auth.reducer,
  refdata: Refdata.reducer
}

export const selectTheme = (state: Theme.ThemeState) => state.data;

export const selectAuth = (state: Auth.AuthState) => state;
export const selectUser = (state: Auth.AuthState) => state.user;

export const selectRefdata = (state: Refdata.RefDataState) => state;
export const selectRFGraduationTypes = (state: Refdata.RefDataState) => state.graduationTypes;
export const selectRFEducationalLevel = (state: Refdata.RefDataState) => state.educationalLevel;
export const selectInstitutionNames = (state: Refdata.RefDataState) => state.institutionName;
export const selectGraduationCourses = (state: Refdata.RefDataState) => state.graduationCourse;
export const selectCountries = (state: Refdata.RefDataState) => state.countries;
export const selectLanguages = (state: Refdata.RefDataState) => state.languages;
export const selectComputerTools = (state: Refdata.RefDataState) => state.computerTool;
export const selectBusinessSector = (state: Refdata.RefDataState) => state.businessSector;

export const selectAppState = (state: App.AppState) => state;
export const getAppLoading = (state: App.AppState) => state.loading;
export const getAppLoaded = (state: App.AppState) => state.loaded;
export const selectAppData = (state: App.AppState) => state.data;
export const selectAppError = (state: App.AppState) => state.error;
