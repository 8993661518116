
import { AuthGuard } from '@app/core/auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SummaryComponent } from './products/purchases/sumary/summary.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { PayerComponent } from './products/purchases/payer/payer.component';
import { PaymentComponent } from './products/purchases/payment/payment.component';
import { OrderComponent } from './products/purchases/order/order.component';
import { OrderTrackingComponent } from './products/purchases/ order-tracking/order-tracking.component';
import { VirtualStoreComponent } from './virtual-store.component';

const routes: Routes = [
  {
    path: '', component: VirtualStoreComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductListComponent,
        data: {
          title: 'Loja Virtual'
        }
      },
      {
        path: 'virtual-store/purchases/products/:productId',
        component: OrderComponent,
        data: {
          title: 'Sacola de Produdos'
        }
      },
      {
        path: 'virtual-store/purchases/order',
        component: OrderComponent,
        data: {
          title: 'Sacola de Produdos'
        }
      },
      {
        path: 'virtual-store/purchases/order/:orderId',
        component: OrderComponent,
        data: {
          title: 'Sacola de Produdos'
        }
      },
      {
        path: 'virtual-store/purchases/payer/:orderId',
        component: PayerComponent,
        data: {
          title: 'Informações de Entrega'
        }
      },
       {
        path: 'virtual-store/purchases/payment/:orderId',
        component: PaymentComponent,
        data: {
          title: 'Informações de Pagamanto'
        }
      },
      {
        path: 'virtual-store/purchases/summary/:orderId',
        component: SummaryComponent,
        data: {
          title: 'Finalização de Pagamanto'
        }
      },
      {
        path: 'virtual-store/purchases/order-tracking',
        component: OrderTrackingComponent,
        data: {
          title: 'Acompanhamento de compras'
        }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VirtualStoreRoutingModule { };
