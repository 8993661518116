
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"  *ngIf="products?.length > 0">
    <ol class="carousel-indicators carousel-indicators-advertising">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item " [ngClass]="{'active': i === 0 }"
        *ngFor="let product of  products; let i = index">
        <div class="card-advertising">
          <p class="item-discount" *ngIf="!product.free && product.discount">{{product.discount}}% Off</p>
          <p class="item-discount" *ngIf="product.free">Free</p>
          <div class="columns">
            <div class="column is-8">
              <p class="title-advertising">{{product.name}}</p>
              <p class="sub-title-advertising" *ngIf="!product.briefDescription"></p>
              <p class="sub-title-advertising" *ngIf="product.briefDescription">{{product.briefDescription}}
              </p>
              <p class="promotion-advertising" *ngIf="!product.free && product.discount">{{product.fmtPrice}}
              </p>
              <p class="value-advertising" *ngIf="!product.free">{{product.fmtDiscountPrice}}</p>
            </div>
            <div class="column is-4">
              <img class="img-info" [src]="getProductImage(product.urlImage)">
              <a class="know-more" *ngIf="!product.free" (click)="redirectToPurchase(product)">{{
                'conference.show-more' | translate }}</a>
              <a class="know-more" *ngIf="product.free" (click)="redirectToModule(product)">Acessar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
