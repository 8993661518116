import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, first } from 'rxjs/operators';

import { environment } from '@env/environment';

import { TokenService } from './token.service';
import { AuthToken } from '../models/auth-token';
import { CredentialsService } from './credentials.service';

import { Credentials } from '../models/credentials';
import { User } from '@app/views/user/model/user/user';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_URL = environment.baseUrl + '/v1/users/authentications';

  authToken: AuthToken;
  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService
  ) { }

  isLoggedIn(): boolean {
     return this.credentialsService.isLogged();
  }

  logout() {
    this.authToken = undefined;
    this.credentialsService.logout();
  }

  public autoLogin() {

    return this.http.get<AuthToken>(`${environment.baseUrl}/v1/users/authentications/token/${this.authToken.token}`)
      .pipe(tap((authToken: AuthToken) => {
        this.credentialsService.setToken(authToken.token);
        this.authToken = { ...authToken };
      }));
  }

  public login(credentials) {

    return this.http.post<AuthToken>(`${environment.baseUrl}/v1/users/authentications`, credentials)
      .pipe(tap((authToken: AuthToken) => {
        this.credentialsService.setToken(authToken.token);
        this.authToken = { ...authToken };
      }));

  }
  public checkDocumentNumberTaken(cpf: string) {
    return this.http.get<User>(`${environment.baseUrl}/v1/users/authentications/cpf/${cpf}`);
  }
  checkEmailTaken(email: string) {
    return this.http.get<User>(`${environment.baseUrl}/v1/users/authentications/email/${email}`);
  }
  public save(credentials: Credentials) {
    return this.http.post<Credential>(`${environment.baseUrl}/v1/users/authentications/save`, credentials);
  }

  resetPasswordByEmail(login: string) {
    return this.http.patch(`${environment.baseUrl}/v1/users/authentications/reset-password`, login);
  }

  savePassword(credentials: Credentials) {
    return this.http.post<Credentials>(this.API_URL + `/password`, credentials);
  }
}
