import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { TranslateLoadersConfig } from "@app/utils"

import { NavbarComponent } from "@components/navbar/navbar.component";

import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { ErrorComponent } from './error/error.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { FooterComponent } from './footer/footer.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LocalNumberPipe } from './pipes/local-number.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlResourcePipe } from './pipes/safe-url-resource.pipe';
import { LoadingModule } from './loading/loading.module';
import { ConfirmModalComponent } from './modal-container/confirm-modal/confirm-modalcomponent';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(TranslateLoadersConfig),  
    LoadingModule,
    ImageCropperModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    NavbarComponent,
    LocalDatePipe,
    LocalNumberPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeUrlResourcePipe,
    LanguageSwitchComponent,
    ErrorComponent,
    ModalContainerComponent,    
    FooterComponent,
    ConfirmModalComponent,
  ],
  exports: [
    NavbarComponent,
    TranslateModule,
    TranslatePipe,
    RouterModule,
    LocalDatePipe,
    LocalNumberPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeUrlResourcePipe,
    ModalContainerComponent,
    ConfirmModalComponent,
    FooterComponent,
    LoadingModule,
    LanguageSwitchComponent,
  ],
  entryComponents: [
    ErrorComponent,
  ]
})
export class ComponentsModule { }
