import { TranslateLoadersConfig } from './../../utils/httpLoaderFactory';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertFieldComponent } from './alert-field.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(TranslateLoadersConfig)
  ],
  declarations: [AlertFieldComponent],
  exports: [
    AlertFieldComponent
  ]
})
export class AlertFieldModule { }
