import { UserContextService } from '@app/core/services/user-context.service';
import { Router, NavigationEnd } from '@angular/router';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Observable } from 'rxjs';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { BaseView } from '../../shared/BaseView';
import { Credentials } from '@app/core/models/credentials';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent extends BaseView implements OnInit {


  user$: Observable<Credentials>;
  user: Credentials;
  public showRouter: any;

  public routerItem = 1;

  constructor(
    private router: Router,
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService
  ) {
    super();
  }

  ngOnInit() {
    window.scroll(0, 0);

    this.userContextService.stopExecution();
    this.user$ = this.credentialsService.getUserCredentials();

    this.user$.subscribe(user => {
      this.user = user;
    });
    this.router.events.subscribe((data: NavigationEnd) => {
      if (data.url) {
        this.showRouter = data.url;
      }
    });
  }

}
