<div class="column is-12">
  <img class="icon-alert" src="../../../../assets/images/login/icon-alert-error.png">
</div>

<div class="column is-12">
  <h1 class="title">
    Algo deu errado
  </h1>
</div>

<div class="column is-12">
  <p class="text">
    Entre em contato com o fornecedor da sua conta.
  </p>
</div>

<div class="column is-12">
  <img class="img-error" src="../../../../assets/images/login/img-error.png">
</div>

<div class="field" *ngIf="">
  <button type="submit" class="btn btn-back" routerLink="/" >
    Voltar
  </button>
</div>
