import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '@app/components/alert/alert.service';
import { Credentials } from '@app/core/models/credentials';
import { CredentialsService } from '@app/core/services/credentials.service';
import { UserContextService } from '@app/core/services/user-context.service';
import { Note } from '@app/views/user/model/learning/note';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-conference-notes',
  templateUrl: './conference-notes.component.html',
  styleUrls: ['./conference-notes.component.sass']
})
export class ConferenceNotesComponent implements OnInit {

  @Input() inscriptionId: number;
  @Input() conferenceId: number;
  @Input() customerId: number;
  user$: Observable<Credentials>;
  user: Credentials;
  submit: boolean;
  note = new Note();
  note$: Observable<Note[]>;

  constructor(private conferenceInscriptionService: ConferenceInscriptionService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService,
    private alertService: AlertService) { }

  ngOnInit() {

    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
    });

  }


  public deleteNote(note: Note) {
    this.note$ = this.conferenceInscriptionService
      .deleteNote(this.user.id, this.conferenceId, this.inscriptionId, note.id)
      .pipe(switchMap(() => this.conferenceInscriptionService.getNotes(this.user.id, this.conferenceId, this.inscriptionId)));

  }
  public saveNote() {
    this.submit = true;
    this.note.inscriptionId = this.inscriptionId;
    this.note.conferenceId = this.conferenceId;

    if (!this.note.title || !this.note.note) {
      return;
    }

    this.note$ = this.conferenceInscriptionService
      .saveNote(this.user.id, this.conferenceId, this.inscriptionId, this.note)
      .pipe(switchMap(() => this.conferenceInscriptionService.getNotes(this.user.id, this.conferenceId, this.inscriptionId)))
      .pipe(tap(() => {
        this.submit = false;
        this.note = new Note();
        this.alertService.success("Roteiro cadastrado!");
      }));
  }

}
