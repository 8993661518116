import * as Actions from "../actions";
import { objectNormalizer } from '@app/utils/dataNormalizer';


export interface RefDataState {
  graduationTypes: any;
  educationalLevel: any;
  institutionName: any;
  graduationCourse: any;
  languages: any;
  countries: any;
  computerTool: any;
  businessSector: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: RefDataState = {
  graduationTypes: null,
  educationalLevel: null,
  institutionName: null,
  graduationCourse: null,
  countries: null,
  languages: null,
  computerTool: null,
  businessSector: null,
  error: null,
  loaded: false,
  loading: false,
}

export function reducer(state = initialState, action: Actions.RefDataActions): RefDataState {

  switch (action.type) {

    case Actions.RefdataActionTypes.RF_GRADTYPE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case Actions.RefdataActionTypes.RF_GRADTYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        graduationTypes: action.payload
      }
    }

    case Actions.RefdataActionTypes.RF_EDUCATIONTYPE_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    case Actions.RefdataActionTypes.RF_EDUCATIONTYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        educationalLevel: action.payload
      }
    }

    case Actions.RefdataActionTypes.INSTITUTION_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.INSTITUTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        institutionName: objectNormalizer(action.payload, ['educationalInstitutionId', 'educationalInstitutionName'])
      }
    }

    case Actions.RefdataActionTypes.GRADUATION_COURSE_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.GRADUATION_COURSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        graduationCourse: objectNormalizer(action.payload, ['regularGraduationCourseId', 'regularGraduationCourseName'])
      }      
    }

    case Actions.RefdataActionTypes.COUNTRIES_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.COUNTRIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        countries: action.payload
      }
    }

    case Actions.RefdataActionTypes.LANGUAGES_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.LAGUAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        languages: objectNormalizer(action.payload, ['languageId', 'languageName'])
      }
    }

    case Actions.RefdataActionTypes.TECH_SKILLS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.TECH_SKILLS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        computerTool: objectNormalizer(action.payload, ['computerToolId', 'computerToolName'])
      }
    }

    case Actions.RefdataActionTypes.BIZ_SECTOR_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false
      }
    }

    case Actions.RefdataActionTypes.BIZ_SECTOR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        businessSector: objectNormalizer(action.payload, ['businessSectorId', 'businessSectorName'])
      }
    }

    case Actions.RefdataActionTypes.REFDATA_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
  }

  return state;
}
