import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'

@Component({
  selector: 'input-messages',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.sass']
})
export class ValidationComponent implements OnChanges {

  public errorMessage: string | null = null;

  @Input() validationErrors: object | null = null;

  ngOnChanges(): void {
    this.errorMessage = this.getErrorMessage();
  }

  getErrorMessage(): string | null {
    const errors = this.validationErrors;
    if (!errors) {
      return null;
    } else if (errors['required']) {
      return 'Campo Obrigatório';
    } else if (errors['email']) {
      return 'Digite um e-mail válido';
    } else if (errors['matchPassword']) {
      return 'As senhas devem ser iguais';
    } else if (errors['matchEmail']) {
      return 'Os emails devem ser iguais';
    } else if (errors['minlength']) {
      return `Digite pelo menos ${errors['minlength'].requiredLength} caracteres`;
    } else if (errors['maxlength']) {
      return `Digite no máximo ${errors['maxlength'].requiredLength} caracteres`;
    } else if (errors['formatInvalid']) {
      return `Valor digitado não é válido para esse campo.`;
    } else if (errors['alreadyExists']) {
      return `Já existe esse valor cadastrado na base.`;
    }
  }
}
