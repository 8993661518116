import { AlertFieldModule } from '@app/components/alert-field/alert-field.module';
import { CareerPathRoutes } from './career-path-routing.module';
import { TranslateLoadersConfig } from '@app/utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AlertModule } from '@app/components/alert/alert.module';
import { ComponentsModule } from '@app/components/components.module';
import { ValidationsModule } from '@app/components/validations/validations.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { CareerPathListComponent } from './career-path-list/career-path-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationsModule,
    ComponentsModule,
    AlertModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forChild(TranslateLoadersConfig),
    CareerPathRoutes,
    TranslateModule,
    AlertFieldModule,
  ],
  declarations: [CareerPathListComponent]
})
export class CareerPathModule { }
