import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as state from "../reducers";

const selectCoreState = createFeatureSelector<state.CoreState>('core');

const getRefDataState = createSelector(
  selectCoreState,
  (state: state.CoreState) => state.refdata
);

export const getRefData = createSelector(
  getRefDataState,
  state.selectRefdata
);

export const getRFGraduationTypes = createSelector(
  getRefDataState,
  state.selectRFGraduationTypes
)

export const getEducationalLevels = createSelector(
  getRefDataState,
  state.selectRFEducationalLevel
)

export const getInstitutionNames = createSelector(
  getRefDataState,
  state.selectInstitutionNames
)

export const getGraduationCourses = createSelector(
  getRefDataState,
  state.selectGraduationCourses
)

export const getCountries = createSelector(
  getRefDataState,
  state.selectCountries
)

export const getLanguages = createSelector(
  getRefDataState,
  state.selectLanguages
)

export const getComputerTools = createSelector(
  getRefDataState,
  state.selectComputerTools
)

export const getBusinessSector = createSelector(
  getRefDataState,
  state.selectBusinessSector
)
