import { UserContextService } from '@app/core/services/user-context.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { ThemeService } from '@app/core/services/theme.service';
import { Observable } from 'rxjs';
import { Credentials } from '@app/core/models/credentials';
import { BaseView } from '@app/views/shared/BaseView';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VirtualStoreService } from '@app/views/user/service/virtual-store.service';
import { Order } from '@app/views/user/model/products/order';
import { AlertService } from '@app/components/alert/alert.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass']
})
export class SummaryComponent extends BaseView implements OnInit {

  user$: Observable<Credentials>;
  user: Credentials;
  order$: Observable<Order>;
  order: Order;
  orderId: number;

  constructor(
    public themeService: ThemeService,
    private credentialsService: CredentialsService,
    private userContextService: UserContextService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private virtualStoreService: VirtualStoreService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.orderId = this.activatedRoute.snapshot.params['orderId'];
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(user => {
      this.user = user;
      this.loadData();
    });
  }

  public loadData() {
    this.order$ = this.virtualStoreService.getProductOrderById(this.user.id, this.orderId);
    this.order$.subscribe(order => {
      this.order = order;
    })
  }

  public redirect(){
    this.route.navigate([`/order-tracking`]);
  }
}
