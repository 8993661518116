

import { Matriculation } from '../model/learning/matriculation';
import { Tool } from '../model/learning/tool';
import { MatriculationToolService } from './matriculation-tool.service';
import { MatriculationService } from './matriculation.service';


export class ScormService {

  errorNumber = 0;
  apiInitialized = false;
  commited = false;
  finish = false;
  loading: boolean;
  scormData: Tool;
  matriculation: Matriculation;
  toolService: MatriculationToolService;
  matriculationService: MatriculationService

  constructor(matriculation: Matriculation,
    matriculationService: MatriculationService,
    scormData: Tool, toolService: MatriculationToolService
  ) {
    this.toolService = toolService;
    this.scormData = scormData;
    this.matriculation = matriculation;
    this.matriculationService = matriculationService
  }

  public LMSInitialize(field: string) {

    this.apiInitialized = true;
    if (this.scormData) {
      this.apiInitialized = true;
      return true;
    } else {
      this.apiInitialized = false;
      this.errorNumber = 301;
      return false;
    }
  }

  public LMSCommit(field, callback?: any) {

    if (this.apiInitialized) {
      this.toolService.updateToolAccess(this.matriculation.userId,
        this.matriculation.trainingId,
        this.matriculation.matriculationId, this.scormData.id, this.scormData)
        .subscribe(data => {
          this.commited = true;
          if (this.finish) {
            this.LMSFinish('',callback);
          }
          return true;
        }, error => {
          this.errorNumber = 203;
          return false;
        });
    }
  }

  public LMSFinish(field, callback?: any): boolean {
    var ret = false;
    if (this.apiInitialized) {
      if (!this.commited) {
        this.finish = true;
        this.LMSCommit('',callback);
      } else {
        this.matriculationService.updateMatriculation(
          this.matriculation.userId,
          this.matriculation.trainingId,
          this.matriculation.matriculationId, this.matriculation)
          .subscribe(data => {
            this.apiInitialized = false;
            if (callback) {
              callback();
            }
          }, error => {
            ret = false;
            this.errorNumber = 301;
          });
      }
    } else {
      ret = false;
      this.errorNumber = 301;
    }
    return ret;
  }

  public LMSGetValue(field: string): string {

    var ret = '';
    if (this.apiInitialized) {
      ret = this.getValue(field);
    } else {
      this.errorNumber = 301;
    }
    return ret;
  }

  public LMSSetValue(field: string, data: string) {

    var ret = false;
    if (this.apiInitialized) {
      ret = this.setValue(field, data);
    } else {
      this.errorNumber = 301;
    }
    return ret;
  }


  public LMSGetLastError(): number {
    return this.errorNumber;
  }

  public LMSGetErrorString(errorCode: number): string {
    if (!errorCode) {

      errorCode = this.errorNumber;
    }
    var message = this.getErrorStrings(errorCode.toString());
    return message;
  }

  public LMSGetDiagnostic(errorCode: number): string {

    if (!errorCode) {
      errorCode = this.errorNumber;
    }
    var Message = this.getErrorDiagnostics(errorCode.toString());
    return this.errorNumber + ': ' + Message;
  }

  private getErrorDiagnostics(element: string) {
    var errorDiagnostics = new Array();
    errorDiagnostics["0"] = "O último comando executado pela API foi completado com sucesso.";
    errorDiagnostics["101"] = "Ocorreu um erro inesperado.";
    errorDiagnostics["201"] = "Argumento inválido.";
    errorDiagnostics["202"] = "Elemento não possui filhos.";
    errorDiagnostics["203"] = "Elemento não é um array - não pode ser contado.";
    errorDiagnostics["301"] = "API não inicializada.";
    errorDiagnostics["401"] = "Este elemento ainda não foi implementado";
    errorDiagnostics["402"] = "Tentativa de gravar uma palavra-chave no banco de dados. Foi tentado gravar uma palavra-chave (count, _children, or _version) e isto é proíbido";
    errorDiagnostics["403"] = "Elemento permite somente leitura (Não gravável) e foi impossível executar LMSSetValue() para o elemento em questão";
    errorDiagnostics["404"] = "Elemento permite somente escrita (Sem leitura) e foi impossível executar LMSGetValue() para o elemento em questão";
    errorDiagnostics["405"] = "Tipo de dado inválido está sendo utilizado e o tipo utilizado para gravar não confere com o vocabulário permitido para o elemento sendo gravado";

    return errorDiagnostics[element];
  }


  private getErrorStrings(element) {
    var errorStrings = new Array();
    errorStrings["0"] = "No error";
    errorStrings["101"] = "General exception";
    errorStrings["201"] = "Invalid argument error";
    errorStrings["202"] = "Element cannot have children";
    errorStrings["203"] = "Element not an array - Cannot have count";
    errorStrings["301"] = "Not initialized";
    errorStrings["401"] = "Not implemented error";
    errorStrings["402"] = "Invalid set value, element is a keyword";
    errorStrings["403"] = "Element is read only";
    errorStrings["404"] = "Element is write only";
    errorStrings["405"] = "Incorrect Data Type";
    return errorStrings[element];

  }
  private setValue(param, value) {
    if (this.scormData && this.scormData.parameters) {
      for (var i = 0; i < this.scormData.parameters.length; i++) {
        if (this.scormData.parameters[i].name == this.normalizeDataElement(param)) {
          this.scormData.parameters[i].value = value;
        }
      }
      return true;
    } else {
      return false;
    }
  }
  private getValue(param) {
    if (this.scormData && this.scormData.parameters) {
      for (var i = 0; i < this.scormData.parameters.length; i++) {
        if (this.scormData.parameters[i].name == this.normalizeDataElement(param)) {
          return this.scormData.parameters[i].value;
        }
      }
    } else {
      return '';
    }
  }

  private normalizeDataElement(field) {
    return field.replace(/\d/g, '#').replace(/#+/g, 'n');
  }

}

