import { CustomDateFormatter } from './../../utils/custom-date-formatter.provider';
import { ThemeService } from '@app/core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Credentials } from '@app/core/models/credentials';
import { AuthService } from '@app/core/services/auth.service'
import { CredentialsService } from '@app/core/services/credentials.service';
import { PlatformDetectorService } from '@app/core/services/platform-detector.service';
import { Observable, Subject } from 'rxjs';
import { BaseView } from '@app/views/shared/BaseView';
import { UserContext } from '@app/core/models/user-context';
import { UserContextService } from '@app/core/services/user-context.service';
import { ConferenceInscriptionService } from '@app/views/user/service/conference-inscription.service';

import { Location } from '@angular/common';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { UserPunctuationService } from '@app/views/user/service/user-punctuartion.service';
import { UserPunctuation } from '@app/views/user/model/user/user-punctuation';
import { Inscription } from '@app/views/user/model/conference/inscription';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ]
})
export class NavbarComponent extends BaseView implements OnInit {

  public readonly MODULE_ASSESSMENTS = 1;
  public readonly VIRTUAL_STORE = 9;
  public readonly MODULE_TRAILS = 11;
  public readonly MODULE_TRAININGS = 10;
  public readonly MODULE_CONFERENCES = 8;
  public readonly MODULE_CAREER_PLAN = 13;

  public menu = false;
  public mobileMenu = false;
  public menuMobile = false;
  public responsiveMenu = false;
  public responsiveMenuVertical = false;
  public companyName;
  public showRouter: any;
  public dontShowNav: number;
  public context: UserContext;
  public showProfile = false;
  public showVirtualStoreModule = false;
  public showCarrerPlanModule = false;
  public showAssessmentModule = false;
  public showTrailsModule = false;
  public showTrainingsModule = false;
  public showConferencesModule = false;
  user$: Observable<Credentials>;
  user: Credentials;

  activeDayIsOpen: boolean = true;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
  ];

  refresh: Subject<any> = new Subject();
  events = new Array<CalendarEvent>();
  conferences: Array<Inscription>;
  userPunctuation = new UserPunctuation();

  public windowWidth = window.innerWidth;

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private platformDetectorService: PlatformDetectorService,
    private themeService: ThemeService,
    private userContextService: UserContextService,
    public conferenceInscriptionService: ConferenceInscriptionService,
    private route: Router,
    private userPunctuationService: UserPunctuationService,
    private location: Location
  ) {
    super();
  }

  ngOnInit() {
    if (this.windowWidth < 1024) {
      this.viewMenu();
    }

    this.userContextService.userSource.subscribe(userContext => {
      this.context = userContext;
    });
    this.user$ = this.credentialsService.getUserCredentials();
    this.user$.subscribe(data => {
      this.user = data;

      if (this.user) {

        this.userPunctuationService.getPunctuation(this.user.id).subscribe(punctuation => {
          if (punctuation) {
            this.userPunctuation = punctuation;
          }
        });

        this.showModules(this.user);

        this.conferenceInscriptionService.getConferenceInscriptions(this.user.id, "", this.STATUS_ALL)
          .subscribe(conferences => {
            this.conferences = conferences;
            if (conferences && conferences.length > 0) {
              this.events.push(
                {
                  start: startOfDay(new Date()),
                  title: '',
                  draggable: false,
                  color: colors.yellow
                }
              );

              conferences.forEach(conference => {
                this.events.push(
                  {
                    start: startOfDay(new Date()),
                    title: conference.name,
                    color: colors.yellow,
                    id: conference.conferenceId,
                    meta: conference
                    //actions: this.actions,
                  }
                );
              });
              this.activeDayIsOpen = false;
            }
          });
      }
    });

    this.menuMobile = !this.platformDetectorService.isPlatformBrowser();

    this.router.events.subscribe((data: NavigationEnd) => {
      if (data.url) {
        this.showRouter = data.url;
      }
    });
    this.dontShowNav = this.themeService.dontShowNav;
  }

  showModules(user: Credentials) {

    if (user.modules) {
      user.modules.forEach(module => {
        if (module.moduleId == this.MODULE_ASSESSMENTS) {
          this.showAssessmentModule = true;
        } else if (module.moduleId == this.VIRTUAL_STORE) {
          this.showVirtualStoreModule = true;
        } else if (module.moduleId == this.MODULE_TRAILS) {
          this.showTrailsModule = true;
        } else if (module.moduleId == this.MODULE_TRAININGS) {
          this.showTrainingsModule = true;
        } else if (module.moduleId == this.MODULE_CONFERENCES) {
          this.showConferencesModule = true;
        } else if (module.moduleId == this.MODULE_CAREER_PLAN) {
          this.showCarrerPlanModule = true;
        }
      })
    }
  }

  toggleMenu() {
    this.mobileMenu = !this.mobileMenu;
  }

  closeMobileMenu() {
    this.menuMobile = !this.menuMobile;
  }

  close() {

    if (this.userContextService.getCurrentType() == 1) {
      this.router.navigate(['assessments']);
    } else if (this.userContextService.getCurrentType() == 2) {
      const matriculationId = this.userContextService.getMatriculationId();
      const id = this.userContextService.getId();
      this.router.navigate([`trainings/${id}/type/1/training-home/${matriculationId}/false`]);
    } else if (this.userContextService.getCurrentType() == 3) {
      const matriculationId = this.userContextService.getMatriculationId();
      const id = this.userContextService.getId();
      this.router.navigate([`trails/${id}/type/2/trail-home/${matriculationId}`]);
    } else if (this.userContextService.getCurrentType() == 5) {
      this.router.navigate(['conference']);
    }
    this.userContextService.stopExecution();
  }

  logout() {
    this.credentialsService.logout();
    this.showProfile = false;
    this.toggleMenu();
    this.router.navigate(['/']);
  }

  viewMenu() {
    this.themeService.showMenuOpen();
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    const conferenceId = event.meta.conferenceId;
    const inscriptionId = event.meta.inscriptionId;
    this.redirect(conferenceId, inscriptionId);
  }

  public navigation(url) {
    this.route.navigate([url]);
  }

  public redirect(conferenceId: number, inscriptionId: number) {
    this.route.navigate([`conferences/${conferenceId}/conference-home/${inscriptionId}`]);
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
